import axios from 'axios';
import { cleanEntity } from 'app/shared/util/entity-utils';
import { IApplicantAssessment } from 'app/shared/model/applicant-assessment.model';

const apiUrl = 'api/assessment-emails';

export class AssessmentEmailService {
  constructor() {}

  async getEntity(id) {
    const requestUrl = `${apiUrl}/${id}`;
    return axios
      .get(requestUrl)
      .then(response => {
        return response;
      })
      .then(body => {
        return body.data;
      })
      .catch(error => {
        alert(error);
      });
  }

  async loadByAssessment(assessmentId, page, size, sort) {
    const requestUrl = `${apiUrl}/loadByAssessment?assessmentId=${assessmentId}${sort ? `&page=${page}&size=${size}&sort=${sort}` : ''}`;
    return axios
      .get<IApplicantAssessment>(`${requestUrl}&cacheBuster=${new Date().getTime()}`)
      .then(response => {
        return response;
      })
      .then(body => {
        return body;
      })
      .catch(error => {
        alert(error);
      });
  }

  async saveInvite(entity) {
    return axios
      .post(`${apiUrl}/saveInvite`, cleanEntity(entity))
      .then(response => {
        return response;
      })
      .then(body => {
        return body.data;
      })
      .catch(error => {
        alert(error);
      });
  }

  async removeCandidate(id) {
    return axios
      .delete(`${apiUrl}/removeCandidate/${id}`)
      .then(response => {
        return response;
      })
      .then(body => {
        return body;
      })
      .catch(error => {
        alert(error);
      });
  }
}

export default AssessmentEmailService;
