import { IUniversity } from 'app/shared/model/university.model';

export interface IFaculty {
  id?: number;
  name?: string;
  description?: string;
  university?: IUniversity;
}

export const defaultValue: Readonly<IFaculty> = {};
