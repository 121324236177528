import axios from 'axios';
import { ICrudDeleteAction, ICrudGetAction, ICrudGetAllAction, ICrudPutAction } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util';

import { defaultValue, ICandidateAssessmentExperience } from 'app/shared/model/candidate-assessment-experience.model';

export const ACTION_TYPES = {
  FETCH_CANDIDATEASSESSMENTEXPERIENCE_LIST: 'candidateAssessmentExperience/FETCH_CANDIDATEASSESSMENTEXPERIENCE_LIST',
  FETCH_CANDIDATEASSESSMENTEXPERIENCE: 'candidateAssessmentExperience/FETCH_CANDIDATEASSESSMENTEXPERIENCE',
  CREATE_CANDIDATEASSESSMENTEXPERIENCE: 'candidateAssessmentExperience/CREATE_CANDIDATEASSESSMENTEXPERIENCE',
  UPDATE_CANDIDATEASSESSMENTEXPERIENCE: 'candidateAssessmentExperience/UPDATE_CANDIDATEASSESSMENTEXPERIENCE',
  DELETE_CANDIDATEASSESSMENTEXPERIENCE: 'candidateAssessmentExperience/DELETE_CANDIDATEASSESSMENTEXPERIENCE',
  RESET: 'candidateAssessmentExperience/RESET'
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<ICandidateAssessmentExperience>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false
};

export type CandidateAssessmentExperienceState = Readonly<typeof initialState>;

// Reducer

export default (state: CandidateAssessmentExperienceState = initialState, action): CandidateAssessmentExperienceState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_CANDIDATEASSESSMENTEXPERIENCE_LIST):
    case REQUEST(ACTION_TYPES.FETCH_CANDIDATEASSESSMENTEXPERIENCE):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true
      };
    case REQUEST(ACTION_TYPES.CREATE_CANDIDATEASSESSMENTEXPERIENCE):
    case REQUEST(ACTION_TYPES.UPDATE_CANDIDATEASSESSMENTEXPERIENCE):
    case REQUEST(ACTION_TYPES.DELETE_CANDIDATEASSESSMENTEXPERIENCE):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true
      };
    case FAILURE(ACTION_TYPES.FETCH_CANDIDATEASSESSMENTEXPERIENCE_LIST):
    case FAILURE(ACTION_TYPES.FETCH_CANDIDATEASSESSMENTEXPERIENCE):
    case FAILURE(ACTION_TYPES.CREATE_CANDIDATEASSESSMENTEXPERIENCE):
    case FAILURE(ACTION_TYPES.UPDATE_CANDIDATEASSESSMENTEXPERIENCE):
    case FAILURE(ACTION_TYPES.DELETE_CANDIDATEASSESSMENTEXPERIENCE):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload
      };
    case SUCCESS(ACTION_TYPES.FETCH_CANDIDATEASSESSMENTEXPERIENCE_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10)
      };
    case SUCCESS(ACTION_TYPES.FETCH_CANDIDATEASSESSMENTEXPERIENCE):
      return {
        ...state,
        loading: false,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.CREATE_CANDIDATEASSESSMENTEXPERIENCE):
    case SUCCESS(ACTION_TYPES.UPDATE_CANDIDATEASSESSMENTEXPERIENCE):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.DELETE_CANDIDATEASSESSMENTEXPERIENCE):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {}
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

const apiUrl = 'api/candidate-assessment-experiences';

// Actions

export const getEntities: ICrudGetAllAction<ICandidateAssessmentExperience> = (page, size, sort) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_CANDIDATEASSESSMENTEXPERIENCE_LIST,
    payload: axios.get<ICandidateAssessmentExperience>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`)
  };
};

export const getEntity: ICrudGetAction<ICandidateAssessmentExperience> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_CANDIDATEASSESSMENTEXPERIENCE,
    payload: axios.get<ICandidateAssessmentExperience>(requestUrl)
  };
};

export const createEntity: ICrudPutAction<ICandidateAssessmentExperience> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_CANDIDATEASSESSMENTEXPERIENCE,
    payload: axios.post(apiUrl, cleanEntity(entity))
  });
  dispatch(getEntities());
  return result;
};

export const updateEntity: ICrudPutAction<ICandidateAssessmentExperience> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_CANDIDATEASSESSMENTEXPERIENCE,
    payload: axios.put(apiUrl, cleanEntity(entity))
  });
  dispatch(getEntities());
  return result;
};

export const deleteEntity: ICrudDeleteAction<ICandidateAssessmentExperience> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_CANDIDATEASSESSMENTEXPERIENCE,
    payload: axios.delete(requestUrl)
  });
  dispatch(getEntities());
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET
});
