import React from 'react';
import MenuItem from 'app/shared/layout/menus/menu-item';
import { Translate, translate } from 'react-jhipster';
import { NavDropdown } from './menu-components';

export const EntitiesMenu = props => (
  <NavDropdown icon="th-list" name={translate('global.menu.entities.main')} id="entity-menu">
    <MenuItem icon="asterisk" to="/audit">
      <Translate contentKey="global.menu.entities.audit" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/audit-question">
      <Translate contentKey="global.menu.entities.auditQuestion" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/audit-question-option">
      <Translate contentKey="global.menu.entities.auditQuestionOption" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/audit-tag">
      <Translate contentKey="global.menu.entities.auditTag" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/question-file">
      <Translate contentKey="global.menu.entities.questionFile" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/coupon">
      <Translate contentKey="global.menu.entities.coupon" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/credit">
      <Translate contentKey="global.menu.entities.credit" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/credit-history">
      <Translate contentKey="global.menu.entities.creditHistory" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/payment-request">
      <Translate contentKey="global.menu.entities.paymentRequest" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/my-audit-question">
      <Translate contentKey="global.menu.entities.myAuditQuestion" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/my-audit-question-option">
      <Translate contentKey="global.menu.entities.myAuditQuestionOption" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/assessment">
      <Translate contentKey="global.menu.entities.assessment" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/assessment-audit">
      <Translate contentKey="global.menu.entities.assessmentAudit" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/assessment-email">
      <Translate contentKey="global.menu.entities.assessmentEmail" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/applicant-assessment">
      <Translate contentKey="global.menu.entities.applicantAssessment" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/candidate-assessment-experience">
      <Translate contentKey="global.menu.entities.candidateAssessmentExperience" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/admin-config">
      <Translate contentKey="global.menu.entities.adminConfig" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/education">
      <Translate contentKey="global.menu.entities.education" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/work-experience">
      <Translate contentKey="global.menu.entities.workExperience" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/training">
      <Translate contentKey="global.menu.entities.training" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/training-schedule">
      <Translate contentKey="global.menu.entities.trainingSchedule" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/training-session">
      <Translate contentKey="global.menu.entities.trainingSession" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/training-date">
      <Translate contentKey="global.menu.entities.trainingDate" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/tag">
      <Translate contentKey="global.menu.entities.tag" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/profile-role">
      <Translate contentKey="global.menu.entities.profileRole" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/faculty">
      <Translate contentKey="global.menu.entities.faculty" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/course">
      <Translate contentKey="global.menu.entities.course" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/major">
      <Translate contentKey="global.menu.entities.major" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/university">
      <Translate contentKey="global.menu.entities.university" />
    </MenuItem>
    {/* jhipster-needle-add-entity-to-menu - JHipster will add entities to the menu here */}
  </NavDropdown>
);
