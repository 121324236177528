import './top-bar.scss';
import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronDown} from '@fortawesome/free-solid-svg-icons';
import {IRootState} from 'app/shared/reducers';
import {connect} from 'react-redux';
import {NavDropdown} from '../layout/menus/top-bar-dropdown';
import {DropdownItem, Modal} from 'reactstrap';
import '../../modules/account/password/password'
import {PasswordPage} from "app/modules/account/password/password";
import {getSession} from "app/shared/reducers/authentication";
import {reset, savePassword} from "app/modules/account/password/password.reducer";
import {NavLink as Link} from 'react-router-dom';
import {Row} from 'reactstrap';


export interface ITopBar extends StateProps, DispatchProps {
}

export interface IPasswordModal {
  passwordToggle: boolean;
}

export class TopBar extends React.Component<ITopBar, IPasswordModal> {

  constructor(props) {
    super(props);
    this.state = {
      passwordToggle: false,
    }
  }

  handleClose = () => {
    this.setState({passwordToggle: false})
  }

  handleOpen = () => {
    this.setState({passwordToggle: true})
  }

  render() {
    return (
      <div className="top-bar" style={{minHeight:80}}>
        <Row className="username align-items-center" style={{minHeight:60,maxHeight:60}}>
          {this.props.account.login}
        </Row>

        <NavDropdown icon={faChevronDown} className="chevron-down">
          <DropdownItem onClick={this.handleOpen}>Reset Password</DropdownItem>
          <DropdownItem tag={Link} to="/logout"><FontAwesomeIcon icon="sign-out-alt" />&nbsp;&nbsp;&nbsp;Logout</DropdownItem>
        </NavDropdown>
        <div className="img-circle">
          <FontAwesomeIcon className="profile-image" icon="user" size={'2x'}/>
        </div>
        <Modal isOpen={this.state.passwordToggle} toggle={this.handleClose}>
          <div>
            <PasswordPage account={this.props.account} isAuthenticated={this.props.isAuthenticated}
                          savePassword={this.props.savePassword} getSession={this.props.getSession}
                          reset={this.props.reset}/>
          </div>
        </Modal>
      </div>
    );
  }


}

const mapStateToProps = ({authentication}: IRootState) => ({
  account: authentication.account,
  isAuthenticated: authentication.isAuthenticated,

});

const mapDispatchToProps = {getSession, savePassword, reset};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TopBar);
