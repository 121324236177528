import { IFaculty } from 'app/shared/model/faculty.model';

export interface ICourse {
  id?: number;
  name?: string;
  description?: string;
  faculty?: IFaculty;
}

export const defaultValue: Readonly<ICourse> = {};
