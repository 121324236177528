import { Moment } from 'moment';

export interface ICoupon {
  id?: string;
  expiryDate?: Moment;
  credit?: number;
  usedBy?: string;
  usageLimit?: number;
}

export const defaultValue: Readonly<ICoupon> = {};
