import { IPaginationBaseState } from 'react-jhipster';

export const ITEMS_PER_PAGE = 10;
export interface IPaginationExtState extends IPaginationBaseState {
  itemsPerPage2: number;
  sort2: string;
  order2: string;
  activePage2: number;
}
export declare const getSortState: (location: any, itemsPerPage: any) => IPaginationExtState;
