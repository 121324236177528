// @flow
import * as React from 'react';
import {IAssessment} from 'app/shared/model/assessment.model';
import {
  Card,
  CardActionArea,
  CardContent,
  Typography,
  CardActions,
  Button,
  Tooltip,
  Chip,
  createTheme
} from '@material-ui/core';
import {displayImage, isAssessmentOngoing} from 'app/shared/util/common-utils';
import { useHistory } from 'react-router-dom';
import {Col, Row} from 'reactstrap';
import {IApplicantAssessmentDTO} from 'app/shared/model/applicant-assessment.model';
import {isPassed} from 'app/shared/util/assessment-util';
import ReplayIcon from '@material-ui/icons/Replay';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';

type Props = {
    assessment: IAssessment;
    completedAssessments: IApplicantAssessmentDTO[];
};

export const AssessmentCard = (props: Props) => {
    const history = useHistory();

    const goToAssessment = (assessmentId:string) =>{
        if(isAssessmentOngoing(assessmentId)){
            history.push(`/applicant-assessment/${assessmentId}/exam`);
        }else if(isPassed(props.completedAssessments)){
            history.push({pathname:`/applicant-assessment/applicant-results-by-assessment`,state: {
                    assessment:props.assessment,
                    completedAssessments:props.completedAssessments
            }});
        }else{ // start exam
            history.push(`/applicant-assessment/${assessmentId}/inform`);
        }
    }

    return (
        <Card style={{height: 320}}>
            <CardActionArea>
                  <Row className="justify-content-center">
                      {
                          props.assessment.logo && props.assessment.logo.length > 0?
                              <img src={displayImage(props.assessment.logo)} style={{height:150, padding:20}}/>
                              :
                              <div>
                                  <AssignmentTurnedInIcon style={{opacity:0.5, fontSize:150}}  className={'pt-1'}/>
                              </div>

                      }

                  </Row>
                <CardContent style={{height:120}}>
                    <Tooltip title={props.assessment.title} aria-label="add">
                        <Typography gutterBottom variant="h6" component="h2" className="description-overflow">
                            {props.assessment.title}
                        </Typography>
                    </Tooltip>
                    <Tooltip title={props.assessment.description} aria-label="add">
                        <Typography variant="body2" color="textSecondary" component="p" style={{height:30}} className="description-overflow">
                            {props.assessment.description}
                        </Typography>
                    </Tooltip>
                    <Row>
                            {
                                isPassed(props.completedAssessments) ?
                              <Chip label="Passed" variant="outlined" className="mr-4" style={{color:'#80DC4B',borderColor:'#80DC4B',backgroundColor:'#ffffff'}}/>
                                  :
                                  <Chip variant="outlined" className={'ml-3'} label={'Failed'} style={{color:'#ff0000',borderColor:'#ff0000',backgroundColor:'#ffffff'}}/>
                            }
                            <div style={{display:'flex', justifyContent:'flex-end'}} className="align-items-center ml-2">
                                <ReplayIcon style={{opacity:0.5}} fontSize={'small'} className={'mr-1'}/>
                                <Typography variant="subtitle2" color="textSecondary" component="p">
                                    {props.completedAssessments.length} attempts
                                </Typography>
                            </div>

                    </Row>

                </CardContent>
                <CardActions>
                    <Button className="w-100" size="small" variant="contained" color="primary" onClick={()=>goToAssessment(props.assessment.id)}>
                        {isAssessmentOngoing(props.assessment.id)?'Continue Exam':isPassed(props.completedAssessments)?'View Result':'Test Me Now'}
                    </Button>
                </CardActions>
            </CardActionArea>
        </Card>
    );
};
