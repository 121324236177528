import React from 'react';
import {connect} from 'react-redux';
import {RouteComponentProps} from 'react-router-dom';
import {Button, Card, Col, Row, Table} from 'reactstrap';
import {Translate, TextFormat} from 'react-jhipster';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {IRootState} from 'app/shared/reducers';
import {getEntity, getTrainingEnrollments} from './training.reducer';
import {displayImage} from 'app/shared/util/common-utils';
import {IUserTraining} from 'app/shared/model/user-training.model';
import {APP_DATE_FORMAT} from 'app/config/constants';

export interface IAdminTrainingViewProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

// export interface IIAdminTrainingViewState{
//   userTrainings: IUserTraining[]
// }

export class AdminTrainingView extends React.Component<IAdminTrainingViewProps> {

  constructor(props) {
    super(props);
    // this.state = {
    //   userTrainings: []
    // };

  }

  componentDidMount() {
    this.props.getEntity(this.props.match.params.id);
    this.props.getTrainingEnrollments(parseInt(this.props.match.params.id,10));
  }

  // componentWillReceiveProps(updatedProps) {
  //   if(updatedProps.userTrainings){
  //     this.setState({
  //       userTrainings: updatedProps.userTrainings
  //     })
  //   }
  // }

  goBack = () =>{
      this.props.history.push('/training');
  }

  render() {
    const { trainingEntity, userTrainings } = this.props;

    return (

      <Card className="main-card">

        <Row className="justify-content-center mt-3">
          <Col md="10">
            <Row>
              <h2>
                <b>{trainingEntity.name}</b>
              </h2>
            </Row>
            <Row className="mt-4">
              <Col md="2">
                <div className="jh-entity-details">
                  <div>
                    {trainingEntity.image ? (
                      <div>
                        <span>
                          <img style={{padding: "10px", width: "100px"}} src={displayImage(trainingEntity.image)}/>
                        </span>
                      </div>
                    ) : <img src="content/images/assessment_logo.png"/>}
                  </div>
                </div>
              </Col>
              <Col md="4">
                <div className="jh-entity-details">

                  <div>
                    <b id="percent">Description</b>
                  </div>
                  <div>{trainingEntity.description}</div>

                  <div>
                    <b id="percent">Level</b>
                  </div>
                  <div>{trainingEntity.level}</div>

                  <div>
                    <b id="percent">Tags</b>
                  </div>
                  <div>{trainingEntity.tags}</div>


                </div>
              </Col>
              <Col md="4">
                <div className="jh-entity-details">
                  <div>
                    <b id="categories">Outcome</b>
                  </div>
                  <div>{trainingEntity.outcome}</div>
                </div>
                <div>
                  <b id="allowViewScore">Type</b>
                </div>
                <div>{trainingEntity.type}</div>
              </Col>
              <Col md="2">
                <div className="jh-entity-details">
                  <div>
                    <b id="expiryDate">Price</b>
                  </div>
                  <div>{trainingEntity.price}</div>

                  <div>
                  </div>
                </div>
              </Col>
            </Row>


            {
              userTrainings && userTrainings.length > 0 &&
                <Row className="mt-4">
                  <div className="table-responsive" id="hoverMenu">
                    <Table responsive aria-describedby="assessment-heading">
                      <thead>
                      <tr>
                        <th className="hand">Candidate Name</th>
                        <th className="hand">Enrolled Date</th>
                        <th className="hand">Schedule</th>
                        <th className="hand">Session</th>
                        <th className="hand">Date</th>

                      </tr>
                      </thead>
                      <tbody>
                      {
                        userTrainings.map((userTraining, index) => {
                          return (
                              <tr key={index}>
                                <td>{userTraining.user.fullName}</td>
                                <td><TextFormat type="date" value={userTraining.createdDate} format={APP_DATE_FORMAT}/></td>
                                <td>{userTraining.trainingDate.trainingSession.trainingSchedule.scheduleName}</td>
                                <td>{userTraining.trainingDate.trainingSession.sessionName}</td>
                                <td><TextFormat type="date" value={userTraining.trainingDate.startDateTime} format={APP_DATE_FORMAT}/></td>
                              </tr>
                          );
                        })
                      }

                      </tbody>
                    </Table>

                  </div>
                </Row>
            }

          </Col>
        </Row>
        <Row>
          <Col md="10">
            <Button onClick={() => this.goBack()} color="info">
              <FontAwesomeIcon icon="arrow-left" />{' '}
              <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.back">Back</Translate>
            </span>
            </Button>
          </Col>
        </Row>
      </Card>
    );
  }
}

const mapStateToProps = ({ training }: IRootState) => ({
  trainingEntity: training.entity,
  userTrainings: training.userTrainings
});

const mapDispatchToProps = {
  getEntity,
  getTrainingEnrollments
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminTrainingView);
