import './landing.scss';

import React from 'react';
import {Redirect, RouteComponentProps} from 'react-router-dom';
import {getSortState, IPaginationBaseState} from 'react-jhipster';
import {connect} from 'react-redux';
import {Col, Row} from 'reactstrap';
import ApplicantAssessmentService from 'app/shared/service/applicant-assessment.service';
import {ITEMS_PER_PAGE} from "app/shared/util/pagination.constants";
import {AUTHORITIES} from "app/config/constants";
import {hasAnyAuthority} from "app/shared/auth/private-route";


export interface ILandingProps extends StateProps, RouteComponentProps<{ id: string }> {
}

export interface ILandingState extends IPaginationBaseState {
    models: any;
};


export class Landing extends React.Component<ILandingProps, ILandingState> {
    applicantAssessmentService: ApplicantAssessmentService;

    constructor(props) {
        super(props);
        this.state = {...getSortState(this.props.location, ITEMS_PER_PAGE), models: []};

    }

    componentDidMount() {
    }

    register = () => {
        this.props.history.push({pathname:'/choose-role'});
    }

    login = () =>{
      this.props.history.push('/login');
    }

    render() {
      const { location, isAuthenticated, isHirer, isAdmin } = this.props;
      const { from } = location.state || { from: { pathname: isHirer ? '/assessment' : (isAdmin ? "/audit": "/dashboard"), search: location.search } };
      if (isAuthenticated) {
        return <Redirect to={from} />;
      }else{
        return (
          <Row className="w-100" style={{marginTop:-50}}>
            <Row style={{backgroundColor:'#C1B9EA', minHeight:545}} className="justify-content-center w-100">
              <Col md="1" xs={0}></Col>
              <Col md="6" xs={12} style={{textAlign:'right'}}>
                <div style={{height:'100%', display:'flex', flexDirection:'column',justifyContent:'center'}}>
                  <div>
                                <span className="hero-main-title" style={{overflow:'wrap'}}>
                                One-stop platform to realise your digital tech career dreams.
                                </span>
                  </div>
                  <div style={{marginTop: 10}}>
                                <span className="hero-main-subtitle">
                                HAIPO comes with assessments, courses, coaches and job features tailored for you.
                                </span>
                  </div>
                  <div style={{marginTop: 10, display:'flex',alignItems:'flex-end',flexDirection:'column'}}>
                    <div className="rounded-button" style={{width: 220}}>
                      <a className="d-flex align-items-center nav-link" onClick={this.register}>
                        GET STARTED
                      </a>
                    </div>
                  </div>
                </div>
              </Col>
              <Col md="5" xs={12} className="my-auto">
                <img src="content/images/people-presenting.png" width="400"/>
              </Col>
            </Row>

            <Row id="how-we-enable-you" className="justify-content-center align-items-center w-100 py-10" style={{backgroundColor:'#ffffff', minHeight:200}}>
              <Col md="12">
                <span className="hero-main-title">How we enable you?</span>
              </Col>
            </Row>

            <Row className="w-100 py-5">
              <Col md={6} xs={12}>
                <Row className="w-100 px-5">
                  <span className="hero-main-title">Identify Skillgaps</span>
                </Row>
                <Row className="w-100 px-5">
                  <span className="hero-main-subtitle">Skillgaps analyses help you identify exactly what skills you need to achieve your career dream</span>
                </Row>
              </Col>
              <Col md={6} xs={12} className="my-auto">
                <img src="content/images/woman-holding-phone.png" width="263"/>
              </Col>
            </Row>

            <Row className="w-100 py-5" style={{backgroundColor:'#ffffff'}}>
              <Col md={6} xs={12} className="my-auto">
                <img src="content/images/landing-graph.png" width="110"/>
              </Col>
              <Col md={6} xs={12}>
                <Row className="w-100 px-5">
                  <span className="hero-main-title">Upskill and Reskill</span>
                </Row>
                <Row className="w-100 px-5">
                  <span className="hero-main-subtitle">Get a targeted learning path customized to your needs and easily track your learning progress.</span>
                </Row>
              </Col>

            </Row>


            <Row className="w-100 py-5">
              <Col md={6} xs={12}>
                <Row className="w-100 px-5">
                  <span className="hero-main-title">Get Coached</span>
                </Row>
                <Row className="w-100 px-5">
                  <span className="hero-main-subtitle">Get connected with coaches to provide you with industry insights and guidance along your career journey</span>
                </Row>
              </Col>
              <Col md={6} xs={12} className="my-auto">
                <img src="content/images/people-in-discussion.png" width="263"/>
              </Col>
            </Row>



            <Row className="w-100 py-5" style={{backgroundColor:'#ffffff'}}>
              <Col md={6} xs={12} className="my-auto">
                <img src="content/images/2-people-with-book.png" width="338"/>
              </Col>
              <Col md={6} xs={12}>
                <Row className="w-100 px-5">
                  <span className="hero-main-title">Land a Job</span>
                </Row>
                <Row className="w-100 px-5">
                  <span className="hero-main-subtitle">Job recommendations based on your strengths and career aspirations.</span>
                </Row>
              </Col>
            </Row>

            <Row id="how-we-enable-you" className="justify-content-center align-items-center w-100 py-10" style={{minHeight:200}}>
              <Col md="12">
                <Row className="w-100 px-5 justify-content-center">
                  <span className="hero-main-title">Have Fun and Get Rewarded</span>
                </Row>
                <Row className="w-100 px-5 justify-content-center">
                  <span className="hero-main-subtitle">Gamified experience where you can compete with others, challenge your peers, earn points and exchange them for cash vouchers!</span>
                </Row>
              </Col>
            </Row>

            <Row id="how-we-enable-you" className="justify-content-center align-items-center w-100 py-10" style={{backgroundColor:'#C1B9EA', minHeight:200}}>
              <Col md="12">
                <Row className="w-100 px-5 justify-content-center">
                  <span className="hero-main-title">Start your journey toward your career dream today!</span>
                </Row>
                <Row className="w-100 px-5 justify-content-center">
                  <a className="d-flex align-items-center nav-link" onClick={this.register}>
                    <div className="rounded-button">
                      Get Started
                    </div>
                  </a>
                </Row>
              </Col>
            </Row>

          </Row>

        );
      }

    }


};

const mapStateToProps = storeState => ({
    account: storeState.authentication.account,
    isAuthenticated: storeState.authentication.isAuthenticated,
    isAdmin: storeState.authentication.account?hasAnyAuthority(storeState.authentication.account.authorities, [AUTHORITIES.ADMIN]):false,
    isApplicant: storeState.authentication.account?hasAnyAuthority(storeState.authentication.account.authorities, [AUTHORITIES.APPLICANT]):false,
    isHirer: storeState.authentication.account?hasAnyAuthority(storeState.authentication.account.authorities, [AUTHORITIES.HIRER]):false,
});

type StateProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps)(Landing);
