import './applicant-assessment.css';
import React from 'react';
import {RouteComponentProps} from 'react-router-dom';
import {Col, Row} from 'reactstrap';
import {Translate} from 'react-jhipster';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {IApplicantAssessment} from 'app/shared/model/applicant-assessment.model';
import ApplicantAssessmentService from 'app/shared/service/applicant-assessment.service';
import SyntaxHighlighter from 'react-syntax-highlighter';
import {docco} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import ReportService from 'app/shared/service/report.service';
import {displayHtml, displayImage, unescapeHtml} from "app/shared/util/common-utils";
import {IAudit} from 'app/shared/model/audit.model';
import RadarChart from 'react-svg-radar-chart';
import 'react-svg-radar-chart/build/css/index.css';
import {Button, Card} from '@material-ui/core';
import ReactToPrint, {PrintContextConsumer} from 'react-to-print';


export interface IApplicantAssessmentExamViewState {
  assessmentId: string;
  model: IApplicantAssessment;
  barChartData: any;
  scorePercent: number;
  applicantAnswers: any;
  questions: any;
  audits: IAudit[];
}

export class ApplicantAssessmentExamView extends React.PureComponent<RouteComponentProps<{ id: string, user: string, from: string }>, IApplicantAssessmentExamViewState> {

  applicantAssessmentService: ApplicantAssessmentService;
  reportService: ReportService;

  componentRef;

  constructor(props) {
    super(props);
    this.componentRef = React.createRef();
    this.state = {
      assessmentId: '0',
      model: {},
      barChartData: [],
      scorePercent: 0,
      applicantAnswers: [],
      questions: [] ,
      audits: []
    };
    this.back = this.back.bind(this);
  }

  componentDidMount() {

    this.applicantAssessmentService = new ApplicantAssessmentService();
    this.reportService = new ReportService();

    this.applicantAssessmentService.loadExam(this.props.match.params.id, this.props.match.params.user).then(data =>{
      const model = data.form;
      const barChartData = data.barChartData;
      const scorePercent = Math.round((model.score/model.maxScore) * 100);

      const applicantAnswers = data.applicantAnswers;
      const questions = data.auditQuestions;
      const audits = data.audits;

      if(model.assessment.showAnswers){
        for(let i = 0; i < applicantAnswers.length; i++){
          const applicantAnswer = applicantAnswers[i];
          for(let j = 0; j < questions.length; j++){
            const question = questions[j];
            for(let k = 0; k < question.questionOptions.length; k++){
              const auditQuestionOption = question.questionOptions[k];
              // if(auditQuestionOption.id === applicantAnswer.questionOption.id && applicantAnswer.score !== -1){
              if(auditQuestionOption.id === applicantAnswer.id && applicantAnswer.score !== -1){
                auditQuestionOption.checked = true;
              }
            }
          }
        }
      }

      this.setState({model: model, scorePercent: scorePercent, applicantAnswers: applicantAnswers, questions: questions, audits: audits, barChartData: barChartData});
    });
  }

  handleNext = (event) =>{
    this.props.history.push('/applicant-assessment/' + this.props.match.params.id + '/experience');
  }

  isQuestionCorrect(questionId:string):boolean{
    const questions = this.state.questions.filter(question=>question.id===questionId);
    const question = questions[0];
    const correctAnswers = question.questionOptions.filter(questionOption=>questionOption.score === 1);
    const aCorrectOptionId = correctAnswers[0].id;
    const candidateAnswers = this.state.applicantAnswers.filter(answer=>answer.id===aCorrectOptionId);
    return candidateAnswers && candidateAnswers.length > 0 && candidateAnswers[0].score > 0? true:false; // just pick the first correct answer
  }

  isApplicantAnswerCorrect(questionOptionId:string):boolean{
    const candidateAnswers = this.state.applicantAnswers.filter(answer=>answer.id===questionOptionId);
    return candidateAnswers && candidateAnswers.length > 0? true:false;
  }

  isTemplateAnswerCorrect(questionId:string, questionOptionId: string):boolean{
    const questions = this.state.questions.filter(question=>question.id===questionId);
    if(questions && questions.length > 0){
      const question = questions[0]; // there should only be one
      const options = question.questionOptions.filter(questionOption=>(questionOption.id ===questionOptionId && questionOption.score > 0));
      return options && options.length>0?true:false;
    }else{
      return false;
    }

  }




  setColor = (index) =>{
    if(this.correctAnswer(index)){
      return {border: "2px solid green", padding: "0px 5px 0px 5px", display: "inline-block", marginRight: "5px"};
    }
    return {border: "2px solid red", padding: "0px 5px 0px 5px", display: "inline-block", marginRight: "5px"};
  }

  getBackgroundColor2 = (parentIndex, index) =>{
    if(this.correctAnswer(parentIndex)){
      return { padding: "10px"};
    }else{
      const {questions, model} = this.state;

      const option = questions[parentIndex].questionOptions[index];
      if((option.score === 1 || option.score) && model.assessment.showAnswers){
        return {background: "#dff3e3", padding: "10px"};
      }
      return  { padding: "10px"};
    }
  }
  getBackgroundColor = (parentIndex, index) =>{
    const {questions, model} = this.state;
    const option = questions[parentIndex].questionOptions[index];
    if((option.score === 1 || option.score) && model.assessment.showAnswers){
      return { padding: "5px", border: "2px solid #26FFCB", borderRadius: "5px"};
    }
    return  { padding: "5px"};
  }
  getBackgroundChooseAnswer = (parentIndex, index) =>{
    const {applicantAnswers, model, questions} = this.state;
    const answer = questions[parentIndex].questionOptions[index];
    for(let i = 0; i < applicantAnswers.length; i++){
      const applicantAnswer = applicantAnswers[i];
        if(applicantAnswer.id === answer.id && model.assessment.showAnswers){
          return { padding: "5px", border: "2px solid #3B86FF", borderRadius: "5px"};
        }
    }
    return  { padding: "5px"};
  }

  getCorrectAnswer(index){
    const {questions, applicantAnswers} = this.state;
    let result = true;
    const answers = questions[index].questionOptions;
    const selectedAnswers = [], values = [], questionOptionIds = [];
    for(let i = 0; i < applicantAnswers.length; i++){
      const applicantAnswer = applicantAnswers[i];
      for(let j = 0; j < answers.length; j++){
        const answer = answers[j];
        // if(applicantAnswer.questionOption.id == answer.id){
        if(applicantAnswer.id === answer.id){
          selectedAnswers.push(applicantAnswer);

          questionOptionIds.push(applicantAnswer.id);
          values.push(applicantAnswer.score);
        }
      }

    }
    for(let i = 0; i < answers.length; i++){
      const answer = answers[i];
      let haveAnswer = answer.score ? false: true;
      for(let j = 0; j < selectedAnswers.length; j++){
        const selectedAnswer = selectedAnswers[j];
        // if(selectedAnswer.questionOption.id == answer.id && answer.score != selectedAnswer.score){
        if(selectedAnswer.id === answer.id){

          if(answer.score !== selectedAnswer.score){
            result = false;
          }
          if(answer.score){
            haveAnswer = true;
          }
        }
      }
      if(!haveAnswer){
        result = false;
        break;
      }

    }

    return {correct: result, questionOptionIds: questionOptionIds, values: values};
  }
  correctAnswer(index){
    const {questions, applicantAnswers} = this.state;
    let result = true;
    const answers = questions[index].questionOptions;
    const selectedAnswers = [];
    for(let i = 0; i < applicantAnswers.length; i++){
      const applicantAnswer = applicantAnswers[i];
      for(let j = 0; j < answers.length; j++){
        const answer = answers[j];
        // if(applicantAnswer.questionOption.id == answer.id){
        if(applicantAnswer.id === answer.id){
          selectedAnswers.push(applicantAnswer);
        }
      }

    }
    for(let i = 0; i < answers.length; i++){
      const answer = answers[i];
      let haveAnswer = answer.score ? false: true;
      for(let j = 0; j < selectedAnswers.length; j++){
        const selectedAnswer = selectedAnswers[j];
        // if(selectedAnswer.questionOption.id == answer.id && answer.score != selectedAnswer.score){
        if(selectedAnswer.id === answer.id){

          if(answer.score !== selectedAnswer.score){
            result = false;
          }
          if(answer.score){
            haveAnswer = true;
          }
        }
      }
      if(!haveAnswer){
        result = false;
        break;
      }

    }

    return result;
  }

  getAuditId = (question, audits) =>{
    for(let i = 0; i < audits.length; i++){
      const audit = audits[i];
      if(audit.id === question.auditId){
        return audit.id;
      }
    }
    return null;

  }
  downloadPDF = () =>{
    const {questions, audits, model} = this.state;
    const submitData = [];
    for(let i= 0; i < questions.length; i++){
      const data = {questionId: questions[i].id, questionOptionIds: [], values: [], auditQuestion: {}, correct: false};

      const answer = this.getCorrectAnswer(i);
      data.questionOptionIds = answer.questionOptionIds;
      data.values = answer.values;
      const auditQuestion = questions[i];
      auditQuestion.auditQuestionOptions = auditQuestion.questionOptions;
      auditQuestion.auditId = this.getAuditId(auditQuestion, audits);
      data.auditQuestion = auditQuestion;
      data.correct = answer.correct;
      submitData.push(data);

    }

  }

  back(){
    this.props.history.push('/dashboard');
  }

  renderRowOption = (questionOptions, parentIndex, rowTh, questionId) =>{
    const action = [];
    for(let j = 0; j < questionOptions.length; j++){
      const auditQuestionOption = questionOptions[j];

      const isTemplateAnswer = this.isTemplateAnswerCorrect(questionId,auditQuestionOption.id)?'active':'';
      const isCandidateAnswer = this.isApplicantAnswerCorrect(auditQuestionOption.id)?'active':'';

      action.push(
          <Col md="4" key={Math.random()}>
            <div className={`template-answer ${isTemplateAnswer}`}>
              <div className={`candidate-answer ${isCandidateAnswer}`}>
                <div><b>Option {rowTh * 3 + j + 1}</b></div>
                <div style={this.getBackgroundColor(parentIndex, j)}>
                  <div style={this.getBackgroundChooseAnswer(parentIndex, j)}>
                    {auditQuestionOption.highlight ?
                        <SyntaxHighlighter style={docco} showLineNumbers={true}>
                          {unescapeHtml(auditQuestionOption.title)}
                        </SyntaxHighlighter>
                        :
                        <label style={{
                          wordWrap: "break-word",
                          whiteSpace: "pre-wrap",
                          display: "inline-flex"
                        }}
                               dangerouslySetInnerHTML={displayHtml(auditQuestionOption.title)}></label>}
                  </div>
                </div>

              </div>
            </div>
          </Col>);
    }
    return action;

  }
  renderQuestionOption = (questionOptions, parentIndex, questionId) =>{

    // render 3 options per row
    const length = Math.floor(questionOptions.length/3);
    const groups = [];
    for(let i = 0; i < length; i=+3){
      const group = [];
      group.push(questionOptions[i * 3]);
      group.push(questionOptions[i * 3 + 1]);
      group.push(questionOptions[i * 3 + 2]);
      groups.push(group);
    }
    const group = [];
    for(let i = length * 3; i < questionOptions.length; i++){
      group.push(questionOptions[i]);
    }
    if(group.length !== 0){
      groups.push(group);
    }
    const action = [];
    for(let i = 0; i < groups.length; i++){
      const items = groups[i];
      action.push( <Row className="margin" key={Math.random()}>
        {this.renderRowOption(items, parentIndex, i,questionId)} </Row>);
    }
    return action;
  }
  downloadFile = () =>{
    this.reportService.downloadPDF({applicantLogin: this.props.match.params.user, assessmentId: this.props.match.params.id}).then(data =>{
      const link = document.createElement('a');
      link.href = 'data:application/octet-stream;base64,' + data["data"];
      link.download = data["headers"].filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  }

  showScore = (value) => {
    return Math.round(value*100)/100;
  }
  renderScore = () =>{
    const{model} = this.state;
    return <Col md="1">
            <div>{model.score != null ? this.showScore(model.score*100/model.maxScore) + "%" : ""}</div>
            <span className="assessment-exam-title-small">Score</span>
          </Col>
  }
  renderPrimaryTechnology = () =>{
    const{model, audits} = this.state;
    let core = null;
    for(let i = 0; i < audits.length; i++){
      const audit = audits[i];
      if(audit.core){
        core = audit;
        break;
      }
    }
    if(core != null){
      return <Col md="2">
                <div>{core.name}</div>
                <span className="assessment-exam-title-small">Primary Technology</span>
              </Col>
    }else{
      return "";
    }

  }
  renderSecondaryTechnology = () =>{
    const{model, audits} = this.state;
    const auditNames = [];
    for(let i = 0; i < audits.length; i++){
      const audit = audits[i];
      if(!audit.core){
        auditNames.push(audit.name);
      }
    }
    if(auditNames.length !== 0){
      return <Col md="3">
              <div>{auditNames.join(", ")}</div>
              <span className="assessment-exam-title-small">Secondary Technology</span>
            </Col>
    }else{
      return "";
    }

  }
  renderDownloadButton = () =>{
    const{model} = this.state;
    return <Col md="2">
                <div onClick={() => this.downloadFile()} style={{cursor:'pointer'}}>
                  <span className="d-none d-md-inline" >
                      <Translate contentKey="entity.action.download">Download</Translate>
                    </span>{' '}
                  <FontAwesomeIcon icon="download"/>
                </div>
            </Col>
  }
  render() {
    const { questions, barChartData, model, audits } = this.state;

    return (
      <React.Fragment>


        <Row className="justify-content-start mb-3">
          <Col md="3">
            <Button onClick={this.back}>
              <FontAwesomeIcon icon="arrow-left"/>
              <span className="d-none d-md-inline ml-2">
                <Translate contentKey="entity.action.back"></Translate>
              </span>
            </Button>
          </Col>
        </Row>

        <div ref={this.componentRef} >
          <Row className="w-100 justify-content-center" >
            <Col xs={11} md={10}>
              <Card>
                <Row className="mt-3 pull-left w-100 pl-4">
                  <h2 style={{padding: "10px 0px 10px 0px"}}><b>{model.fullName}</b></h2>
                  <ReactToPrint content={() => this.componentRef.current}>
                    <PrintContextConsumer>
                      {({ handlePrint }) => (
                        <div onClick={handlePrint} style={{marginLeft:30,cursor:'pointer',display: "flex", alignItems: "center", justifyContent: "center"}}>
                              <span className="d-none d-md-inline" style={{color:'#A1A1A1'}}>
                                  <Translate contentKey="entity.action.download">Download</Translate>
                              </span>{' '}
                          <FontAwesomeIcon icon="download" style={{color:'#A1A1A1',marginLeft:6}}/>
                        </div>
                      )}
                    </PrintContextConsumer>
                  </ReactToPrint>
                </Row>
                <Row className="w-100 ml-4">
                  <div>
                    <div>{model.score != null ? this.showScore(model.score*100/model.maxScore) + "%" : ""}</div>
                    <span className="assessment-exam-title-small">Score</span>
                  </div>
                  {audits.filter(audit=>audit.core === true).map( (audit,index)=>(
                    <div  style={{marginLeft:30}} key={index}>
                      <div>{audit.name}</div>
                      <span className="assessment-exam-title-small">Primary Technology</span>
                    </div>
                  ))}

                  {audits.filter(audit=>audit.core !== true).map( (audit,index)=>(
                    <div style={{marginLeft:30}} key={index}>
                      <div>{audit.name}</div>
                      <span className="assessment-exam-title-small">Secondary Technology</span>
                    </div>
                  ))}
                </Row>

                <Row className={'justify-content-center mt-3'}>
                  {barChartData && barChartData.length > 0
                    ? barChartData.map((radarData, parentIndex) => (
                      <Col key={parentIndex} xs={12} md={6}>
                        <div style={{textAlign: 'center'}}>{radarData.title}</div>
                        <RadarChart
                          captions={radarData.captions}
                          data={radarData.data}
                          size={450}
                        />
                      </Col>
                    )) : ""}
                </Row>
              </Card>

              <Row style={{marginTop: 40, marginBottom: 20}} className="pull-left">
                <Col md="12">
                  <Row className="pull-left w-100">
                    <b>
                      <Translate contentKey="evaluateyouApp.applicantAssessment.exam.detailedTitle">
                      </Translate>
                    </b>
                  </Row>

                  <Row className="w-100">
                    <div>
                      <span className="correct-color-box"></span>
                      <span>Correct Answer</span>&emsp;&emsp;&emsp;&emsp;
                      <span className="choose-color-box"></span> <span>Chosen Answer</span>
                    </div>
                  </Row>
                </Col>
              </Row>


              <Row className="justify-content-center w-100" >
                <Col md="12">
                  {questions
                    ? questions.map((auditQuestion, parentIndex) => (
                      <Card key={auditQuestion.id} className="noselect" style={{marginBottom: 80, maxWidth: 1200}}>
                        <div>
                          {this.isQuestionCorrect(auditQuestion.id) ?
                            <div className="audit-question-no" style={{
                              fontWeight: 'bold',
                              color: '#ffffff',
                              backgroundColor: '#3B86FF',
                              padding: 10,
                              borderRadius: 5
                            }}>
                                    <span
                                      style={{fontSize: '18px',}}>Question {parentIndex + 1}</span>&nbsp;&nbsp;&nbsp;
                              <FontAwesomeIcon size="2x" style={{
                                color: '#ffffff',
                                marginBottom: "-3px"
                              }} icon={['far', 'check-circle']} title="Correct"/>
                            </div>
                            :
                            <div className="audit-question-no" style={{
                              fontWeight: 'bold',
                              color: '#ffffff',
                              backgroundColor: '#FF3BB7',
                              padding: 10,
                              borderRadius: 5
                            }}>
                                    <span
                                      style={{fontSize: '18px',}}>Question {parentIndex + 1}</span>&nbsp;&nbsp;&nbsp;
                              <FontAwesomeIcon size="2x" style={{
                                color: '#ffffff',
                                marginBottom: "-3px"
                              }} icon={['far', 'times-circle']} title="Wrong"/>
                            </div>
                          }

                          <div className="audit-question"
                               style={{marginTop: 10, marginBottom: 10}}>
                            <span dangerouslySetInnerHTML={displayHtml(auditQuestion.title)}></span>
                          </div>
                          {auditQuestion.type === 3 ?
                            (<span><Translate
                              contentKey="evaluateyouApp.applicantAssessment.exam.chooseAllThatApply"></Translate></span>) :
                            (<span></span>)
                          }
                          {auditQuestion.code ? (
                            <div style={{maxWidth: 1000}}>
                              <SyntaxHighlighter style={docco} showLineNumbers={true}>
                                {auditQuestion.code}
                              </SyntaxHighlighter>
                            </div>
                          ) : (<span></span>)
                          }
                          {auditQuestion.questionFiles
                            ? auditQuestion.questionFiles.map((file, index) => (
                              <div key={file.id} style={{marginBottom: "5px"}}>
                                <img style={{padding: "10px"}}
                                     src={displayImage(file.data)}/>
                              </div>
                            ))
                            : null}

                          {/* Question Options */}
                          {this.renderQuestionOption(auditQuestion.questionOptions, parentIndex, auditQuestion.id)}


                        </div>
                      </Card>
                    ))
                    : null}
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </React.Fragment>
    );
  }
}

