import React from 'react';
import {Switch} from 'react-router-dom';
import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import ApplicantAssessment from './applicant-assessment';
import ApplicantAssessmentDetail from './applicant-assessment-detail';
import ApplicantAssessmentUpdate from './applicant-assessment-update';
import ApplicantAssessmentDeleteDialog from './applicant-assessment-delete-dialog';
import ApplicantAssessmentInform
    from 'app/entities/applicant-assessment/applicant-assessment-inform';
import ApplicantAssessmentExperience
    from 'app/entities/applicant-assessment/applicant-assessment-experience';
import ApplicantAssessmentExam from "app/entities/applicant-assessment/applicant-assessment-exam";
import {ApplicantAssessmentExamView}
    from "app/entities/applicant-assessment/applicant-assessment-exam-view";
import {
    ApplicantAssessmentExamResult
} from "app/entities/applicant-assessment/applicant-assessment-exam-result";
import ApplicantResultsByAssessment from 'app/entities/applicant-assessment/applicant-results-by-assessment';

const Routes = ({ match }) => (
  <div>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={ApplicantAssessmentUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/applicant-results-by-assessment`} component={ApplicantResultsByAssessment} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={ApplicantAssessmentUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/inform`} component={ApplicantAssessmentInform} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/experience`} component={ApplicantAssessmentExperience} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/exam`} component={ApplicantAssessmentExam} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/exam-result`} component={ApplicantAssessmentExamResult} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/:user/:from/exam-view`} component={ApplicantAssessmentExamView} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={ApplicantAssessmentDetail} />
      <ErrorBoundaryRoute path={match.url} component={ApplicantAssessment} />
    </Switch>
    <ErrorBoundaryRoute path={`${match.url}/:id/delete`} component={ApplicantAssessmentDeleteDialog} />
  </div>
);

export default Routes;
