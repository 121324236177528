import './assessment.scss';
import React from 'react';
import {connect} from 'react-redux';
import {Link, RouteComponentProps} from 'react-router-dom';
import {
    Button,
    Card,
    Col,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    Table,
    TabPane
} from 'reactstrap';
import {AvField, AvForm, AvGroup, AvInput} from 'availity-reactstrap-validation';
import {
    getSortState,
    IPaginationBaseState,
    JhiItemCount,
    JhiPagination,
    TextFormat,
    Translate
} from 'react-jhipster';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {IRootState} from 'app/shared/reducers';
import {getEntities} from './assessment.reducer';
import {getCredit} from 'app/entities/credit/credit.reducer';
import {IAssessment} from 'app/shared/model/assessment.model';
import {APP_DATE_FORMAT} from 'app/config/constants';
import {ITEMS_PER_PAGE} from 'app/shared/util/pagination.constants';
import AssessmentService from 'app/shared/service/assessment.service';
import ApplicantAssessmentService from 'app/shared/service/applicant-assessment.service';
import {IApplicantAssessment} from "app/shared/model/applicant-assessment.model";
import AuditService from "app/shared/service/audit.service";
import {IAudit} from "app/shared/model/audit.model";

export interface IAssessmentProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {
}

export interface IAssessmentState extends IPaginationBaseState {
    assessments: IAssessment[];
    applicantAssessments: IApplicantAssessment[];
    totalApplicantAssessmentItems: number;
    totalAssessmentItems: number;
    view: number;
    isShowSearchForm: boolean;
    isSearching: boolean;
    audits: IAudit[];
    activeTab: string;
    loading: boolean;
    selectedAudits: IAudit[];
    selectedAssessments: any;
    selectedAssessment: object;
    showConfirmDelete: boolean;
    itemsPerPage2: number;
    sort2: string;
    order2: string;
    activePage2: number;
}

export class Assessment extends React.Component<IAssessmentProps, IAssessmentState> {
    assessmentService: AssessmentService;
    applicantAssessmentService: ApplicantAssessmentService;
    options = [{value: 0, label: "By Assessment"}, {value: 1, label: "By Candidate"}];
    searchModel: any;
    auditService: AuditService;


    constructor(props) {
        super(props);
        this.state = {
            activePage2: 0,
            itemsPerPage2: ITEMS_PER_PAGE,
            ...getSortState(this.props.location, ITEMS_PER_PAGE),
            assessments: [],
            applicantAssessments: [],
            totalApplicantAssessmentItems: 0,
            totalAssessmentItems: 0,
            view: 0,
            order2: 'asc',
            sort2: 'a.id',
            order: 'desc',
            sort: 'expiry_date',
            isShowSearchForm: false,
            isSearching: false,
            audits: [],
            activeTab: '1',
            loading: true,
            selectedAudits: [],
            selectedAssessments: [],
            selectedAssessment: {},
            showConfirmDelete: false
        };
        this.applicantAssessmentService = new ApplicantAssessmentService();
        this.assessmentService = new AssessmentService();
        this.auditService = new AuditService();

        this.searchModel = {name: "", email: "", startTime: null, endTime: null};

    }

    componentDidMount() {
        this.props.getCredit();
        this.auditService.getDTOEntitiesByUsed().then(data => {
            this.setState({audits: data});
        });
        this.getEntities(this.state.activeTab, this.state.isSearching);
    }

    sort(prop: string) {
        this.setState(
            {
                order: this.state.order === 'asc' ? 'desc' : 'asc',
                sort: prop
            },
            () => this.sortEntities()
        );
    };

    sortApplicantAssessment(prop: string) {
        this.setState(
            {
                order2: this.state.order2 === 'asc' ? 'desc' : 'asc',
                sort2: prop
            },
            () => this.sortEntities()
        );
    };

    sortEntities() {
        this.getEntities(this.state.activeTab, this.state.isSearching);
    }

    onSelectAuditChange = (event, item) => {

        const {selectedAudits} = this.state;
        const index = selectedAudits.indexOf(item);
        if (index === -1) {
            selectedAudits.push(item);
        } else {
            selectedAudits.splice(index, 1);
        }
        this.setState({selectedAudits: selectedAudits});
        this.sortEntities();

    }
    onSelectAll = (event) => {

        const {selectedAudits, audits} = this.state;
        const selectedAll = selectedAudits.length === audits.length;
        if (event.target.checked && !selectedAll) {
            selectedAudits.length = 0;
            for (let i = 0; i < audits.length; i++) {
                selectedAudits.push(audits[i]);
            }
        } else if (!event.target.checked && selectedAudits.length !== 0) {
            selectedAudits.length = 0;
        }
        this.setState({selectedAudits: selectedAudits});
        this.sortEntities();

    }
    getSelectedAuditFilter = () => {
        const {selectedAudits} = this.state;
        const result = [];
        for (let i = 0; i < selectedAudits.length; i++) {
            result.push(selectedAudits[i].id);
        }
        return result.join(",");
    }
    onSelectAssessmentForDelete = (event, item) => {
        const {selectedAssessments} = this.state;
        const index = selectedAssessments.indexOf(item);
        if (index === -1) {
            selectedAssessments.push(item);
        } else {
            selectedAssessments.splice(index, 1);
        }
        this.setState({selectedAssessments: selectedAssessments, selectedAssessment: {}});
    }
    deleteSelectedAssessments = (event) => {
        const {selectedAssessments, selectedAssessment} = this.state;
        const ids = [];
        if (selectedAssessment["id"]) {
            ids.push(selectedAssessment["id"]);
        } else {
            for (let i = 0; i < selectedAssessments.length; i++) {
                ids.push(selectedAssessments[i].id);
            }
        }

        this.assessmentService.deleteEntities(ids).then(data => {
            this.setState({
                selectedAssessments: [],
                selectedAssessment: {},
                showConfirmDelete: false
            });
            this.sortEntities();
        });
    }
    deleteAssessment = (assessment) => {
        this.setState({selectedAssessment: assessment, showConfirmDelete: true});
    }
    showDeleteDialog = () => {
        this.setState({showConfirmDelete: true});
    }

    handlePagination = activePage => this.setState({activePage}, () => this.sortEntities());
    handlePagination2 = activePage2 => this.setState({activePage2}, () => this.sortEntities());

    getEntities = (view, isSearching) => {
        const {
            activePage,
            activePage2,
            itemsPerPage,
            itemsPerPage2,
            sort,
            sort2,
            order,
            order2
        } = this.state;
        this.setState({loading: true});
        if (view === '1') {
            this.assessmentService.loadByCreated(activePage - 1, itemsPerPage, `${sort},${order}&createdBy.contains=` + this.props.account.login + `&auditIds=` + this.getSelectedAuditFilter()).then(response => {
                const totalAssessmentItems = parseInt(response["headers"]['x-total-count'], 10);
                const assessments = response["data"];
                this.setState({
                    totalAssessmentItems: totalAssessmentItems,
                    assessments: assessments,
                    activeTab: view,
                    isSearching: isSearching,
                    loading: false
                });
            });
        } else {
            this.applicantAssessmentService.loadByAssessmentCreated(activePage2 - 1, itemsPerPage2, `${sort2},${order2}&createdBy.contains=` + this.props.account.login + `&auditIds=` + this.getSelectedAuditFilter()).then(response => {
                const totalApplicantAssessmentItems = parseInt(response["headers"]['x-total-count'], 10);
                const applicantAssessments = response["data"];
                this.setState({
                    totalApplicantAssessmentItems: totalApplicantAssessmentItems,
                    applicantAssessments: applicantAssessments,
                    activeTab: view,
                    isSearching: isSearching,
                    loading: false
                });
            });
        }
    };

    showScore = (value) => {
        return Math.round(value * 100) / 100;
    }
    toggleTab = (tab) => {
        this.setState({activeTab: tab}, () => this.getEntities(tab, this.state.isSearching));
    }

    doSearch = () => {
        const {activePage2, itemsPerPage2, sort2, order2} = this.state;
        this.applicantAssessmentService.search(activePage2 - 1, itemsPerPage2, `${sort2},${order2}&createdBy.contains=` + this.props.account.login, this.searchModel).then(response => {
            const totalApplicantAssessmentItems = parseInt(response["headers"]['x-total-count'], 10);
            const applicantAssessments = response["data"];
            this.setState({
                totalApplicantAssessmentItems: totalApplicantAssessmentItems,
                applicantAssessments: applicantAssessments,
                view: 1,
                isSearching: true,
                isShowSearchForm: false
            });

        });
    }
    resetSearch = () => {
        this.searchModel = {name: "", email: "", startTime: null, endTime: null};
    }

    changeOption = (value) => {
        value = parseInt(value, 10);
        this.getEntities(value, false);
    }

    setModelValue = (field, event) => {
        const iItem = {...this.searchModel};
        if (field === "allowViewScore") {
            iItem[field] = event.target.checked;
        } else {
            iItem[field] = event.target.value;
        }

        this.searchModel = iItem;
    }

    viewExam = (item) => {
        this.props.history.push(`/applicant-assessment/${item.assessmentId}/${item.createdBy}/l/exam-view`);
    }

    renderCandidates() {
        const {applicantAssessments, totalApplicantAssessmentItems} = this.state;
        return (<div>
            <div className="table-responsive">
                {applicantAssessments && applicantAssessments.length > 0 ? (
                    <Table responsive aria-describedby="assessment-heading" id="hoverMenu">
                        <thead>
                        <tr>
                            <th className="hand"
                                onClick={() => this.sortApplicantAssessment('fullName')}>
                                <Translate
                                    contentKey="evaluateyouApp.applicantAssessment.fullName">Title</Translate>&nbsp;&nbsp;
                                <FontAwesomeIcon icon="sort"/>
                            </th>
                            <th className="hand"
                                onClick={() => this.sortApplicantAssessment('createdBy')}>
                                <Translate
                                    contentKey="evaluateyouApp.applicantAssessment.email">Email</Translate>&nbsp;&nbsp;
                                <FontAwesomeIcon
                                    icon="sort"/>
                            </th>
                            <th className="hand"
                                onClick={() => this.sortApplicantAssessment('createdDate')}>
                                <Translate
                                    contentKey="evaluateyouApp.applicantAssessment.date">Date</Translate>&nbsp;&nbsp;
                                <FontAwesomeIcon icon="sort"/>
                            </th>
                            <th className="hand"
                                onClick={() => this.sortApplicantAssessment('a.title')}>
                                <Translate
                                    contentKey="evaluateyouApp.applicantAssessment.assessmentName">Assessment
                                    Name</Translate>&nbsp;&nbsp;<FontAwesomeIcon icon="sort"/>
                            </th>
                            <th className="hand"
                                onClick={() => this.sortApplicantAssessment('score/aa.maxScore')}>
                                <Translate
                                    contentKey="evaluateyouApp.applicantAssessment.score">Score</Translate>&nbsp;&nbsp;
                                <FontAwesomeIcon icon="sort"/>
                            </th>
                            <th>
                                Actions
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {applicantAssessments && applicantAssessments.map((item, i) => (
                            <tr key={`entity-${i}`}>
                                <td style={{width: 240}}>{item.fullName}</td>
                                <td style={{width: 150}}>{item.createdBy}</td>
                                <td style={{width: 200}}>
                                    <TextFormat type="date" value={item.createdDate}
                                                format={APP_DATE_FORMAT}/>
                                </td>
                                <td>{item.assessmentName}</td>
                                <td style={{width: 80}}>{item.score != null ? this.showScore(item.score * 100 / item.maxScore) : ""}
                                </td>
                                <td style={{width: 100, textAlign: 'left'}}>
                                    <div className="text-left hover-menu">
                                        <div className="btn-group flex-btn-group-container">
                                            {item.score != null ?

                                                <Button color="info" size="sm" tag={Link} to={{
                                                    pathname: `/applicant-assessment/${item.assessmentId}/${item.createdBy}/l/exam-view`,
                                                    state: {from: this.props.location}
                                                }}>

                                                    <FontAwesomeIcon icon="eye"/>{' '}<br/>
                                                    <span className="d-none d-md-inline">
                              <Translate contentKey="entity.action.view">View</Translate>
                            </span>
                                                </Button>
                                                : ""}
                                        </div>
                                    </div>
                                </td>

                            </tr>
                        ))}
                        </tbody>
                    </Table>
                ) : (
                    <div className="alert alert-warning">
                        <Translate contentKey="evaluateyouApp.applicantAssessment.home.notFound">No
                            Assessments found</Translate>
                    </div>
                )}
            </div>
            <div
                className={applicantAssessments && applicantAssessments.length > 0 ? '' : 'd-none'}>
                <Row className="justify-content-center">
                    <JhiItemCount page={this.state.activePage2}
                                  total={this.state.totalApplicantAssessmentItems}
                                  itemsPerPage={this.state.itemsPerPage2}
                                  i18nEnabled/>
                </Row>
                <Row className="justify-content-center">
                    <JhiPagination
                        activePage={this.state.activePage2}
                        onSelect={this.handlePagination2}
                        maxButtons={5}
                        itemsPerPage={this.state.itemsPerPage2}
                        totalItems={totalApplicantAssessmentItems}
                    />
                </Row>
            </div>
        </div>);

    }

    renderAssessments() {
        const {assessments, totalAssessmentItems, selectedAssessments} = this.state;
        const {match} = this.props;
        return (<div>
            <div className="table-responsive">
                {assessments && assessments.length > 0 ? (
                    <Table responsive aria-describedby="assessment-heading" id="hoverMenu">
                        <thead>
                        <tr>
                            <th className="hand" onClick={() => this.sort('title')}>
                                <Translate
                                    contentKey="evaluateyouApp.assessment.title">Title</Translate>&nbsp;&nbsp;
                                <FontAwesomeIcon icon="sort"/>
                            </th>
                            <th className="hand" onClick={() => this.sort('expiry_date')}>
                                <Translate contentKey="evaluateyouApp.assessment.expiryDate">Expiry
                                    Date</Translate>&nbsp;&nbsp;<FontAwesomeIcon icon="sort"/>
                            </th>
                            <th className="hand">
                                <Translate
                                    contentKey="evaluateyouApp.assessment.totalApplicants"></Translate>
                            </th>
                            <th className="hand">
                                <Translate
                                    contentKey="evaluateyouApp.assessment.averageScore"></Translate>
                            </th>
                            <th>
                                Actions
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {assessments && assessments.map((assessment, i) => (
                            <tr key={`entity-${i}`}>
                                <td> {assessment.title}
                                </td>
                                <td>
                                    <TextFormat type="date" value={assessment.expiryDate}
                                                format={APP_DATE_FORMAT}/>
                                </td>
                                <td>{assessment.totalApplicants}</td>
                                <td>
                                    {assessment.totalMaxScore !== 0 ? this.showScore(assessment.totalScore * 100 / assessment.totalMaxScore) : "0"}
                                </td>
                                <td style={{width: 200, textAlign: 'left'}}>
                                    <div className="text-left hover-menu">
                                        <div className="btn-group flex-btn-group-container">
                                            <Button tag={Link}
                                                    to={`${match.url}/${assessment.id}/l`}
                                                    size="xs">
                                                <FontAwesomeIcon size='1x' icon="eye"/>{' '}<br/>
                                                <span className="d-none d-md-inline">
                            <Translate contentKey="entity.action.view">View</Translate>
                          </span>
                                            </Button>
                                            {assessment.totalApplicants === 0 ?
                                                <Button
                                                    onClick={(event) => this.deleteAssessment(assessment)}
                                                    size="xs">
                                                    <FontAwesomeIcon size='1x'
                                                                     icon="trash"/>{' '}<br/>
                                                    <span className="d-none d-md-inline">
                            <Translate contentKey="entity.action.delete">Delete</Translate>
                          </span>
                                                </Button>
                                                : ""}
                                        </div>
                                    </div>
                                </td>

                            </tr>
                        ))}
                        </tbody>
                    </Table>
                ) : (
                    <div className="alert alert-warning">
                        <Translate contentKey="evaluateyouApp.assessment.home.notFound">No
                            Assessments found</Translate>
                    </div>
                )}
            </div>
            <div className={assessments && assessments.length > 0 ? '' : 'd-none'}>
                <Row className="justify-content-center">
                    <JhiItemCount page={this.state.activePage} total={totalAssessmentItems}
                                  itemsPerPage={this.state.itemsPerPage}
                                  i18nEnabled/>
                </Row>
                <Row className="justify-content-center">
                    <JhiPagination
                        activePage={this.state.activePage}
                        onSelect={this.handlePagination}
                        maxButtons={5}
                        itemsPerPage={this.state.itemsPerPage}
                        totalItems={totalAssessmentItems}
                    />
                </Row>
            </div>
            {selectedAssessments.length !== 0 ?
                <Button color="primary" size="sm" onClick={() => this.showDeleteDialog()}>
                    <FontAwesomeIcon icon="trash"/>{' '}
                    <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.deleteSelected">Delete Selected</Translate>
          </span>
                </Button> : null}

        </div>);
    }

    renderSearchForm() {
        const {isShowSearchForm} = this.state;

        let action = (
            <div className="marginBottom marginTop">
                <Row className="justify-content-center1">
                    <Col md="6">
                        <AvForm model={{}}>

                            <AvGroup>
                                <Label id="emailLabel" for="fullName">
                                    <Translate
                                        contentKey="evaluateyouApp.applicantAssessment.fullName">Email</Translate>
                                </Label>
                                <AvField
                                    id="name"
                                    type="text"
                                    name="fullName"
                                    onChange={(event) => this.setModelValue("name", event)}
                                />
                            </AvGroup>
                            <AvGroup>
                                <Label id="emailLabel" for="createdBy">
                                    <Translate
                                        contentKey="evaluateyouApp.applicantAssessment.email">Email</Translate>
                                </Label>
                                <AvField
                                    id="createdBy"
                                    type="text"
                                    name="email"
                                    onChange={(event) => this.setModelValue("email", event)}
                                />
                            </AvGroup>
                            <Row>
                                <Col md="6">
                                    <AvGroup>
                                        <Label id="expiryDateLabel" for="startTime">
                                            <Translate
                                                contentKey="evaluateyouApp.applicantAssessment.startTime">Start
                                                Time</Translate>
                                        </Label>
                                        <AvInput
                                            id="startTime"
                                            type="date"
                                            className="form-control"
                                            name="startTime"
                                            placeholder={'DD/MM/YYYY'}
                                            onChange={(event) => this.setModelValue("startTime", event)}
                                        />
                                    </AvGroup>
                                </Col>
                                <Col md="6">
                                    <AvGroup>
                                        <Label id="expiryDateLabel" for="endTime">
                                            <Translate
                                                contentKey="evaluateyouApp.applicantAssessment.endTime">End
                                                Time</Translate>
                                        </Label>
                                        <AvInput
                                            id="endTime"
                                            type="date"
                                            className="form-control"
                                            name="endTime"
                                            placeholder={'DD/MM/YYYY'}
                                            onChange={(event) => this.setModelValue("endTime", event)}
                                        />
                                    </AvGroup>
                                </Col>
                            </Row>
                            <Button color="primary" id="save-entity" type="button"
                                    onClick={() => this.doSearch()}>
                                &nbsp;
                                <Translate contentKey="entity.action.ok"></Translate>
                            </Button>
                            &nbsp;
                            <Button id="save-entity" type="button"
                                    onClick={() => this.showSearchForm(false)}>
                                &nbsp;
                                <Translate contentKey="entity.action.cancel"></Translate>
                            </Button>
                        </AvForm>
                    </Col>
                </Row>
            </div>
        );

        if (!isShowSearchForm) {
            action = (<div></div>);
        }

        return action;
    }

    showSearchForm = (value) => {
        this.resetSearch();
        this.setState({isShowSearchForm: value});
    }

    hideDialog = () => {
        this.setState({showConfirmDelete: false});
    }

    confirmDelete = () => {
        this.deleteSelectedAssessments(null);
    }

    renderDeleteForm() {
        const {showConfirmDelete} = this.state;

        return (
            <Modal isOpen={showConfirmDelete} toggle={() => this.hideDialog()} backdrop={true}>

                <ModalHeader toggle={() => this.hideDialog()}>
                    <Translate contentKey="entity.delete.title">Confirm delete operation</Translate>
                </ModalHeader>

                <ModalBody id="evaluateyouApp.assessment.delete.question">
                    <Translate contentKey="evaluateyouApp.assessment.delete.selected">
                        Are you sure you want to delete this Assessment?
                    </Translate>
                </ModalBody>

                <ModalFooter>

                    <Button className="btn-secondary" onClick={() => this.hideDialog()}>
                        <FontAwesomeIcon icon="ban"/>
                        &nbsp;
                        <Translate contentKey="entity.action.cancel">Cancel</Translate>
                    </Button>

                    <Button className="btn-primary" id="jhi-confirm-delete-assessment"
                            color="danger" onClick={() => this.confirmDelete()}>
                        <FontAwesomeIcon icon="trash"/>
                        &nbsp;
                        <Translate contentKey="entity.action.delete">Delete</Translate>
                    </Button>


                </ModalFooter>

            </Modal>
        );
    }

    render() {
        const {match} = this.props;
        const {activeTab, audits, selectedAudits, loading} = this.state;
        return (
            <div>
                {this.renderDeleteForm()}
                <Row>
                    <Col md="12">
                        {this.renderSearchForm()}
                    </Col>
                </Row>

                <Row>
                    <Col md="9">
                        <h4 className="pull-left ml-4" id="title-style">
                            Assessments
                        </h4>


                        <Card className="main-card">
                            <Row>
                                <Col md="12">
                                    <div>
                                        <h2 className="pull-right">
                                            <Link to={`${match.url}/choose-assessment`}
                                                  className="btn btn-primary float-right jh-create-entity"
                                                  id="jh-create-entity">
                                                <FontAwesomeIcon icon="plus"/>
                                                &nbsp;
                                                <Translate
                                                    contentKey="evaluateyouApp.assessment.home.createLabel">Create
                                                    a new Assessment</Translate>
                                            </Link>
                                        </h2>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="12">
                                    <Nav>
                                        <NavItem>
                                            <NavLink
                                                className={activeTab === '1' ? 'tab-active' : ''}
                                                onClick={() => {
                                                    this.toggleTab('1');
                                                }}>
                                                Assessments
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                className={activeTab === '2' ? 'tab-active' : ''}
                                                onClick={() => {
                                                    this.toggleTab('2');
                                                }}>Candidates
                                            </NavLink>
                                        </NavItem>

                                    </Nav>
                                    <TabContent activeTab={activeTab}>
                                        <TabPane tabId="1">
                                            <Row>
                                                <Col sm="12">
                                                    {loading ? "" : this.renderAssessments()}
                                                </Col>
                                            </Row>
                                        </TabPane>
                                        <TabPane tabId="2">
                                            <Row>
                                                <Col sm="12">
                                                    {loading ? "" : this.renderCandidates()}
                                                </Col>
                                            </Row>
                                        </TabPane>
                                    </TabContent>
                                </Col>
                            </Row>
                        </Card>


                    </Col>
                    <Col md="3" className="side-bar mt-5">
                        <b className="pull-left ml-4">
                            <span>Filter By Category</span>
                        </b>

                        <Card className="main-card">
                            <div className="pretty p-default p-curve p-thick">
                                <input type="checkbox"
                                       name="primaryAudits`${otherEntity.id}`"
                                       onChange={(event) => this.onSelectAll(event)}/>
                                <div className="state p-primary-o filter-label">
                                    <label>Select All</label>
                                </div>
                            </div>
                            {audits ? audits.map(otherEntity => (
                                    <div key={otherEntity.id}>
                                        <div className="pretty p-default p-curve p-thick">
                                            <input type="checkbox"
                                                   checked={selectedAudits.includes(otherEntity) ? true : false}
                                                   name="primaryAudits`${otherEntity.id}`"
                                                   onChange={(event) => this.onSelectAuditChange(event, otherEntity)}/>
                                            <div className="state p-primary-o filter-label">
                                                <label>{otherEntity.name}</label>
                                            </div>
                                        </div>
                                    </div>
                                ))
                                : null}
                        </Card>


                    </Col>
                </Row>
            </div>
        );
    }

}

const mapStateToProps = ({authentication}: IRootState) => ({
    account: authentication.account
});

const mapDispatchToProps = {
    getEntities,
    getCredit
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Assessment);
