import axios from 'axios';
import { ICrudDeleteAction, ICrudGetAction, ICrudGetAllAction, ICrudPutAction } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util';

import { defaultValue, IQuestionFile } from 'app/shared/model/question-file.model';

export const ACTION_TYPES = {
  FETCH_QUESTIONFILE_LIST: 'questionFile/FETCH_QUESTIONFILE_LIST',
  FETCH_QUESTIONFILE: 'questionFile/FETCH_QUESTIONFILE',
  CREATE_QUESTIONFILE: 'questionFile/CREATE_QUESTIONFILE',
  UPDATE_QUESTIONFILE: 'questionFile/UPDATE_QUESTIONFILE',
  DELETE_QUESTIONFILE: 'questionFile/DELETE_QUESTIONFILE',
  SET_BLOB: 'questionFile/SET_BLOB',
  RESET: 'questionFile/RESET'
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IQuestionFile>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false
};

export type QuestionFileState = Readonly<typeof initialState>;

// Reducer

export default (state: QuestionFileState = initialState, action): QuestionFileState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_QUESTIONFILE_LIST):
    case REQUEST(ACTION_TYPES.FETCH_QUESTIONFILE):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true
      };
    case REQUEST(ACTION_TYPES.CREATE_QUESTIONFILE):
    case REQUEST(ACTION_TYPES.UPDATE_QUESTIONFILE):
    case REQUEST(ACTION_TYPES.DELETE_QUESTIONFILE):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true
      };
    case FAILURE(ACTION_TYPES.FETCH_QUESTIONFILE_LIST):
    case FAILURE(ACTION_TYPES.FETCH_QUESTIONFILE):
    case FAILURE(ACTION_TYPES.CREATE_QUESTIONFILE):
    case FAILURE(ACTION_TYPES.UPDATE_QUESTIONFILE):
    case FAILURE(ACTION_TYPES.DELETE_QUESTIONFILE):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload
      };
    case SUCCESS(ACTION_TYPES.FETCH_QUESTIONFILE_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10)
      };
    case SUCCESS(ACTION_TYPES.FETCH_QUESTIONFILE):
      return {
        ...state,
        loading: false,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.CREATE_QUESTIONFILE):
    case SUCCESS(ACTION_TYPES.UPDATE_QUESTIONFILE):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.DELETE_QUESTIONFILE):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {}
      };
    case ACTION_TYPES.SET_BLOB: {
      const { name, data, contentType } = action.payload;
      return {
        ...state,
        entity: {
          ...state.entity,
          [name]: data,
          [name + 'ContentType']: contentType
        }
      };
    }
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

const apiUrl = 'api/question-files';

// Actions

export const getEntities: ICrudGetAllAction<IQuestionFile> = (page, size, sort) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_QUESTIONFILE_LIST,
    payload: axios.get<IQuestionFile>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`)
  };
};

export const getEntity: ICrudGetAction<IQuestionFile> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_QUESTIONFILE,
    payload: axios.get<IQuestionFile>(requestUrl)
  };
};

export const createEntity: ICrudPutAction<IQuestionFile> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_QUESTIONFILE,
    payload: axios.post(apiUrl, cleanEntity(entity))
  });
  dispatch(getEntities());
  return result;
};

export const updateEntity: ICrudPutAction<IQuestionFile> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_QUESTIONFILE,
    payload: axios.put(apiUrl, cleanEntity(entity))
  });
  dispatch(getEntities());
  return result;
};

export const deleteEntity: ICrudDeleteAction<IQuestionFile> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_QUESTIONFILE,
    payload: axios.delete(requestUrl)
  });
  dispatch(getEntities());
  return result;
};

export const setBlob = (name, data, contentType?) => ({
  type: ACTION_TYPES.SET_BLOB,
  payload: {
    name,
    data,
    contentType
  }
});

export const reset = () => ({
  type: ACTION_TYPES.RESET
});
