import axios from 'axios';
import { cleanEntity } from 'app/shared/util/entity-utils';
import { IApplicantAssessment } from 'app/shared/model/applicant-assessment.model';

const apiUrl = 'api/applicant-assessments';

export class ApplicantAssessmentService {
  constructor() {}

  async getApplicantAssessmentEmailDTOs() {
    const requestUrl = `${apiUrl}/applied`;
    return axios
      .get(requestUrl)
      .then(response => {
        return response;
      })
      .then(body => {
        return body.data;
      })
      .catch(error => {
        alert(error);
      });
  }
  async generateExam(assessmentId) {
    const requestUrl = `${apiUrl}/generateExam?assessmentId=${assessmentId}`;
    return axios
      .get(requestUrl)
      .then(response => {
        return response;
      })
      .then(body => {
        return body.data;
      })
      .catch(error => {
        alert(error);
      });
  }

  async saveExam(entity) {
    const requestUrl = `${apiUrl}/saveExam`;

    return axios
      .post(requestUrl, entity)
      .then(response => {
        return response;
      })
      .then(body => {
        return body.data;
      })
      .catch(error => {
        alert(error);
      });
  }

  async loadExam(assessmentId, applicantLogin) {
    const requestUrl = `${apiUrl}/loadExam?assessmentId=${assessmentId}&applicantLogin=${applicantLogin}`;
    return axios
      .get(requestUrl)
      .then(response => {
        return response;
      })
      .then(body => {
        return body.data;
      })
      .catch(error => {
        alert(error);
      });
  }

  async createEntity(entity) {
    return axios
      .post(apiUrl, cleanEntity(entity))
      .then(response => {
        return response;
      })
      .then(body => {
        return body.data;
      })
      .catch(error => {
        alert(error);
      });
  }
  async saveCandidateAssessmentExperience(entity) {
    const requestUrl = `api/candidate-assessment-experience/save`;

    return axios
      .post(requestUrl, cleanEntity(entity))
      .then(response => {
        return response;
      })
      .then(body => {
        return body.data;
      })
      .catch(error => {
        alert(error);
      });
  }

  async loadByAssessmentCreated(page, size, sort) {
    const requestUrl = `${apiUrl}/byAssessmentCreated${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
    return axios
      .get<IApplicantAssessment>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`)
      .then(response => {
        return response;
      })
      .then(body => {
        return body;
      })
      .catch(error => {
        alert(error);
      });
  }

  async search(page, size, sort, entity) {
    const requestUrl = `${apiUrl}/search${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}&cacheBuster=${new Date().getTime()}`;

    return axios
      .post(requestUrl, cleanEntity(entity))
      .then(response => {
        return response;
      })
      .then(body => {
        return body;
      })
      .catch(error => {
        alert(error);
      });
  }
}

export default ApplicantAssessmentService;
