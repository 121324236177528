import axios from 'axios';
import { ICrudDeleteAction, ICrudGetAction, ICrudGetAllAction, ICrudPutAction, IPayload } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util';

import { defaultValue, ITraining } from 'app/shared/model/training.model';
import { IUserEnrollment } from 'app/entities/training/enroll-training';
import { IUserTraining } from 'app/shared/model/user-training.model';

export const ACTION_TYPES = {
  FETCH_TRAINING_LIST: 'training/FETCH_TRAINING_LIST',
  FETCH_SEARCH_TRAINING_LIST: 'training/FETCH_SEARCH_TRAINING_LIST',
  FETCH_TRAINING: 'training/FETCH_TRAINING',
  CREATE_TRAINING: 'training/CREATE_TRAINING',
  UPDATE_TRAINING: 'training/UPDATE_TRAINING',
  ENROLL_TRAINING: 'training/ENROLL_TRAINING',
  DELETE_TRAINING: 'training/DELETE_TRAINING',
  GET_MY_TRAINING: 'training/GET_MY_TRAINING',
  GET_ADMIN_TRAININGS: 'training/GET_ADMIN_TRAININGS',
  GET_TRAINING_ENROLLMENTS: 'training/GET_TRAINING_ENROLLMENTS',
  SET_BLOB: 'training/SET_BLOB',
  RESET: 'training/RESET',
  GET_ALL_USERS_TRAININGS: 'training/GET_ALL_USERS_TRAININGS',
  GET_RECOMMENDED_TRAININGS: 'training/GET_RECOMMENDED_TRAININGS'
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<ITraining>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
  userTrainings: [] as ReadonlyArray<IUserTraining>,
  recommendedTrainings: [] as ReadonlyArray<ITraining>
};

export type TrainingState = Readonly<typeof initialState>;

type ICrudSearchGetAllAction<T> = (
  searchKey: string,
  page?: number,
  size?: number,
  sort?: string
) => IPayload<T> | ((dispatch: any) => IPayload<T>);

// Reducer

export default (state: TrainingState = initialState, action): TrainingState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.GET_MY_TRAINING):
    case REQUEST(ACTION_TYPES.GET_TRAINING_ENROLLMENTS):
    case REQUEST(ACTION_TYPES.FETCH_TRAINING_LIST):
    case REQUEST(ACTION_TYPES.GET_ADMIN_TRAININGS):
    case REQUEST(ACTION_TYPES.GET_RECOMMENDED_TRAININGS):
    case REQUEST(ACTION_TYPES.FETCH_TRAINING):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true
      };
    case REQUEST(ACTION_TYPES.FETCH_SEARCH_TRAINING_LIST):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true
      };
    case REQUEST(ACTION_TYPES.CREATE_TRAINING):
    case REQUEST(ACTION_TYPES.ENROLL_TRAINING):
    case REQUEST(ACTION_TYPES.GET_ALL_USERS_TRAININGS):
    case REQUEST(ACTION_TYPES.UPDATE_TRAINING):
    case REQUEST(ACTION_TYPES.DELETE_TRAINING):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true
      };
    case FAILURE(ACTION_TYPES.FETCH_TRAINING_LIST):
    case FAILURE(ACTION_TYPES.FETCH_TRAINING):
    case FAILURE(ACTION_TYPES.GET_MY_TRAINING):
    case FAILURE(ACTION_TYPES.FETCH_SEARCH_TRAINING_LIST):
    case FAILURE(ACTION_TYPES.CREATE_TRAINING):
    case FAILURE(ACTION_TYPES.ENROLL_TRAINING):
    case FAILURE(ACTION_TYPES.GET_ALL_USERS_TRAININGS):
    case FAILURE(ACTION_TYPES.GET_TRAINING_ENROLLMENTS):
    case FAILURE(ACTION_TYPES.UPDATE_TRAINING):
    case FAILURE(ACTION_TYPES.GET_ADMIN_TRAININGS):
    case FAILURE(ACTION_TYPES.GET_RECOMMENDED_TRAININGS):
    case FAILURE(ACTION_TYPES.DELETE_TRAINING):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload
      };
    case SUCCESS(ACTION_TYPES.FETCH_TRAINING_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10)
      };
    case SUCCESS(ACTION_TYPES.GET_RECOMMENDED_TRAININGS):
      return {
        ...state,
        loading: false,
        recommendedTrainings: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.FETCH_TRAINING):
      return {
        ...state,
        loading: false,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.GET_MY_TRAINING):
      return {
        ...state,
        loading: false,
        userTrainings: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.GET_ADMIN_TRAININGS):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10)
      };
    case SUCCESS(ACTION_TYPES.GET_TRAINING_ENROLLMENTS):
      return {
        ...state,
        loading: false,
        userTrainings: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.FETCH_SEARCH_TRAINING_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.CREATE_TRAINING):
    case SUCCESS(ACTION_TYPES.ENROLL_TRAINING):
    case SUCCESS(ACTION_TYPES.GET_ALL_USERS_TRAININGS):
      return {
        ...state,
        loading: false,
        userTrainings: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.UPDATE_TRAINING):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.DELETE_TRAINING):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {}
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

const apiUrl = 'api/trainings';

// Actions

export const getEntities: ICrudGetAllAction<ITraining> = (page, size, sort) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_TRAINING_LIST,
    payload: axios.get<ITraining>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`)
  };
};

export const getRecommendedTrainings: ICrudGetAllAction<ITraining> = () => {
  return {
    type: ACTION_TYPES.GET_RECOMMENDED_TRAININGS,
    payload: axios.get<ITraining>(`${apiUrl}/get-recommended`)
  };
};

export const getEntity: ICrudGetAction<ITraining> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_TRAINING,
    payload: axios.get<ITraining>(requestUrl)
  };
};

export const searchEntities: ICrudSearchGetAllAction<ITraining> = (searchKey: string) => {
  const requestUrl = `${apiUrl}/search`;
  return {
    type: ACTION_TYPES.FETCH_SEARCH_TRAINING_LIST,
    payload: axios.get<ITraining>(`${requestUrl}/${searchKey}`)
  };
};

export const getMyTrainings = () => {
  const requestUrl = `${apiUrl}/current-user-training-enrollments`;
  return {
    type: ACTION_TYPES.GET_MY_TRAINING,
    payload: axios.get<ITraining>(`${requestUrl}`)
  };
};

export const getAdminTrainings: ICrudGetAllAction<IUserTraining> = (page, size, sort) => {
  const requestUrl = `${apiUrl}/admin-trainings${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.GET_ADMIN_TRAININGS,
    payload: axios.get<ITraining>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`)
  };
};

export const getThisUserTrainings = () => {
  const requestUrl = `${apiUrl}/this-users-trainings`;
  return {
    type: ACTION_TYPES.GET_ALL_USERS_TRAININGS,
    payload: axios.get<IUserTraining>(`${requestUrl}`)
  };
};

export const getTrainingEnrollments = (id: number) => {
  const requestUrl = `${apiUrl}/get-training-enrollments`;
  return {
    type: ACTION_TYPES.GET_TRAINING_ENROLLMENTS,
    payload: axios.get<IUserTraining>(`${requestUrl}/${id}`)
  };
};

export const enrollTraining = (enrollment: IUserEnrollment) => async dispatch => {
  return await dispatch({
    type: ACTION_TYPES.ENROLL_TRAINING,
    payload: axios.post(`${apiUrl}/enroll-training`, enrollment)
  });
};

export const createEntity: ICrudPutAction<ITraining> = entity => async dispatch => {
  entity.id = null;
  if (entity.trainingSchedules) {
    entity.trainingSchedules.forEach(ts => {
      ts.id = null;
      ts.sessions.forEach(s => {
        s.id = null;

        s.dates = s.dates.map(date => {
          // remove circular reference to training session here
          return {
            id: date.id,
            startDateTime: date.startDateTime,
            endDateTime: date.endDateTime,
            duration: date.duration
          };
        });
      });
    });
  }
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_TRAINING,
    payload: axios.post(apiUrl, cleanEntity(entity))
  });
  return result;
};

export const updateEntity: ICrudPutAction<ITraining> = entity => async dispatch => {
  if (entity.trainingSchedules) {
    entity.trainingSchedules.forEach(ts => {
      ts.sessions.forEach(s => {
        s.dates = s.dates.map(date => {
          // remove circular reference to training session here
          return {
            id: date.id,
            startDateTime: date.startDateTime,
            endDateTime: date.endDateTime,
            duration: date.duration
          };
        });
      });
    });
  }

  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_TRAINING,
    payload: axios.put(apiUrl, cleanEntity(entity))
  });
  return result;
};

export const deleteEntity: ICrudDeleteAction<ITraining> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_TRAINING,
    payload: axios.delete(requestUrl)
  });
  dispatch(getAdminTrainings());
  return result;
};

export const setBlob = (name, data, contentType?) => ({
  type: ACTION_TYPES.SET_BLOB,
  payload: {
    name,
    data,
    contentType
  }
});

export const reset = () => ({
  type: ACTION_TYPES.RESET
});
