import '../assessment/assessment.scss';
import React from 'react';
import {connect} from 'react-redux';
import {RouteComponentProps} from 'react-router-dom';
import {Card, Col, Row} from 'reactstrap';
import "@pathofdev/react-tag-input/build/index.css";
import {IRootState} from 'app/shared/reducers';
import {getCredit} from 'app/entities/credit/credit.reducer';
import {ProgressBar, ProgressBarOption} from 'app/entities/assessment/steps/progress-bar';
import {getUsers} from "app/modules/administration/user-management/user-management.reducer";
import {ITraining} from "app/shared/model/training.model";
import {TrainingDetails} from "app/entities/training/steps/training-details";
import {TrainingConfirmation} from "app/entities/training/steps/training-confirmation";
import {
  createEntity,
  getEntity, getTrainingEnrollments,
  reset,
  setBlob,
  updateEntity
} from 'app/entities/training/training.reducer';
import {TrainingSchedule} from "app/entities/training/steps/training-schedule";
import {TrainingSession} from "app/entities/training/steps/training-session";
import {TrainingDate} from "app/entities/training/steps/training-date";
import {ITrainingDate} from "app/shared/model/training-date.model";
import {ITrainingSession} from 'app/shared/model/training-session.model';
import {getEntities as getTags} from 'app/entities/tag/tag.reducer';


export interface ITrainingStepperProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}


export interface ITrainingStepperChildProps {
  model: ITraining,
  prevStep: Function,
  nextStep: Function,
  populateModel: Function
}

export interface ITrainingStepperState {
  completedSteps: number[];
  model: ITraining;
  currentStepOption: ProgressBarOption;
  isNew: boolean;
}

export class TrainingStepper extends React.Component<ITrainingStepperProps, ITrainingStepperState> {

  steps:ProgressBarOption[];

  constructor(props) {
    super(props);

    this.steps = [
      {
        step: 1,
        stepTitle: 'General',
        renderStep: ()=>this.renderTrainingDetails()
      },
      {
        step: 2,
        stepTitle: 'Schedules',
        renderStep: ()=>this.renderTrainingSchedule()
      },
      {
        step: 3,
        stepTitle: 'Sessions',
        renderStep: ()=>this.renderTrainingSession()
      },
      {
        step: 4,
        stepTitle: 'Dates',
        renderStep: ()=>this.renderTrainingDate()
      },
      {
        step: 5,
        stepTitle: 'Confirm',
        renderStep: ()=>this.renderConfirmation()
      }
    ];

    console.log('in constructor');
    this.state = {
      model: {
        id: null,
        name: '',
        description: '',
        price:0,
        type:'',
        level:'',
        tags:'',
        outcome:'',
        trainingSchedules: [],
      },
      completedSteps:[],
      currentStepOption: this.steps[0], // the first one

      isNew: !this.props.match.params || !this.props.match.params.id
    };

  }

  componentWillReceiveProps(updatedProps) {
    if(updatedProps.trainingCreated && updatedProps.trainingEntity && updatedProps.trainingEntity.id){
      this.props.history.push('/training');
    }

    if(updatedProps.trainingEntity && updatedProps.trainingEntity.id){
      console.log('about to update state');
      console.log(updatedProps.trainingEntity);
      this.setState({model: {...this.state.model, ...updatedProps.trainingEntity}},()=>{
        console.log(`updating model with data`);
        console.log(this.state.model);
      });
    }


  }

  componentDidMount() {

    this.props.getTags(0,99999,'asc');

    if (this.state.isNew) {
      this.props.reset();
    } else {
      this.props.getEntity(this.props.match.params.id);
    }

    this.props.getUsers();
  }



  clearBlob = name => () => {
    this.props.setBlob(name, undefined, undefined);
    const model = this.state.model;
    model.image = null;
    this.setState({model:model});
  };

  getNextStep():ProgressBarOption{
    const nextStep = this.state.currentStepOption.step + 1;
    return this.steps[nextStep -1]; // index are zero based
  }

  getPreviousStep():ProgressBarOption{
    const previous = this.state.currentStepOption.step - 1;
    return this.steps[previous -1]; // index are zero based
  }

  showPrevStep = () =>{
    console.log('showing previous step');
    console.log(this.state.model);
    this.setState({currentStepOption: this.getPreviousStep()});
  }

  showNextStep = () =>{
    this.stepComplete(this.state.currentStepOption.step);
    this.setState({currentStepOption: this.getNextStep()});
  }

  saveEntity = () => {

    const model = this.state.model;
    const entity = {
      ...model
    }
    console.log(entity);

    if(entity.id){
      console.log('Updating existing training');
      this.props.updateEntity(entity);

    }else{
      console.log('Creating new training');
      this.props.createEntity(entity);
    }

  };


  handleClose = () => {
    this.props.history.push('/training');
  }

  stepComplete(step: number):void {
    const completedSteps = this.state.completedSteps;
    completedSteps.push(step);
    this.setState({completedSteps: completedSteps});
  }

  isStepCompleted(step:number):boolean{
    return this.state.completedSteps.includes(step);
  }

  addTrainingDates(trainingDates: ITrainingDate[]) {

    console.log(trainingDates);
    // attach training to sessions
    const trainingSchedules = this.state.model.trainingSchedules;

    trainingSchedules.forEach((schedule) => {
      schedule.sessions.forEach(session => {
          // get all training dates for this session
          session.dates = trainingDates.filter(date => date.trainingSession.id === session.id)

          });
      })

    this.setState({model: {...this.state.model, trainingSchedules: trainingSchedules}}, () => {
      console.log('state updated');
      console.log(this.state);
    });
  }

  renderTrainingDetails(){
    return (<TrainingDetails
      model={this.state.model}
      existingTags={this.props.tags}
      nextStep={() => this.showNextStep()}
      populateModel={(data)=>this.populateModel(data)}
      prevStep={null}/>);
  }

  renderTrainingSchedule() {
    return (<TrainingSchedule
      model={this.state.model}
      prevStep={() => this.showPrevStep()}
      nextStep={() => this.showNextStep()} populateModel={(data)=>this.populateModel(data)}/>);
  }

  populateModel(data) {
    this.setState({model:{...this.state.model, ...data}}, () => {
      console.log(this.state.model);
    });
  }


  renderTrainingSession(){
    return (<TrainingSession
      model = {this.state.model}
      populateModel={(sessions: ITrainingSession[]) => {
          this.addSessions(sessions);
        }
      }
      prevStep={() => this.showPrevStep()}
      nextStep={() => this.showNextStep()}/>);
  }

  private addSessions(sessions: ITrainingSession[]) {
    // attach sessions to schedules
    const schedules = this.state.model.trainingSchedules;

    // reset all sessions to prevent dups
    schedules.forEach(s=>{
      s.sessions = []; // reset sessions since we are always going to only add new ones
    });

    sessions.forEach(session => {
      const schedule = schedules.find(s => s.id === session.trainingSchedule.id);
      schedule.sessions.push(session);
    });
    this.setState({model: {...this.state.model, trainingSchedules: schedules}}, () => {
      console.log('state updated');
      console.log(this.state);
    });
  }

  renderTrainingDate(){
    return (<TrainingDate
      model = {this.state.model}
      prevStep={() => this.showPrevStep()}
      nextStep={() => this.showNextStep()}
      populateModel={
        (trainingDates: ITrainingDate[]) => {
          this.addTrainingDates(trainingDates);
        }
      }/>);
  }

  renderConfirmation(){
    return (<TrainingConfirmation model={this.state.model}
                                  previousStep={()=>this.showPrevStep()}
                                  submit={()=>this.saveEntity()}/>);
  }

  renderCurrentStep(){
    return this.state.currentStepOption.renderStep();
  }

  render() {
    const { loading } = this.props;

    return (
      <Card className="main-card" style={{height:'100%',backgroundColor:'#fbfbfb'}}>
        <Row>
          <ProgressBar currentStep={this.state.currentStepOption} isStepCompleted={(step)=>this.isStepCompleted(step)} stepOptions={this.steps}/>
        </Row>

        <Row className="justify-content-center">
          <Col md="10">
            {loading ? (
              <p>Loading...</p>
            ) : this.renderCurrentStep()}
          </Col>
        </Row>
      </Card>
    );
  }


  private populateTags(tags) {
    console.log(tags);
    // array to comma separated string
    const tagsString = tags.map(tag => tag.text).join(',');
    this.setState({model:{...this.state.model,tags:tagsString}},()=>console.log(this.state.model));
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  trainingEntity: storeState.training.entity,
  account: storeState.authentication.account,
  tags: storeState.tag.entities,
  trainingCreated: storeState.training.updateSuccess,
  loading: storeState.training.loading,
});

const mapDispatchToProps = {
  getEntity,
  updateEntity,
  createEntity,
  reset,
  getCredit,
  getUsers,
  setBlob,
  getTags,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TrainingStepper);
