// @flow
import * as React from 'react';
import { Card, Typography, Button } from '@material-ui/core';
import {IAssessment} from 'app/shared/model/assessment.model';
import {Col, Row} from 'reactstrap';
import {
    AssessmentCard
} from 'app/modules/progressive-candidate-dashboard/components/assessment-card';
import {IProfileRole} from 'app/shared/model/profile-role.model';
import { useEffect } from 'react';
import {IApplicantAssessmentDTO} from 'app/shared/model/applicant-assessment.model';
import { useHistory } from 'react-router-dom';

type Props = {
    assessments: IAssessment[],
    targetRole: IProfileRole
    completedAssessments: IApplicantAssessmentDTO[]

};
export const SkillsetCard = (props: Props) => {

    const history = useHistory();

    useEffect(() => {
        console.log(props.targetRole);
        console.log(props.assessments);
    },[]);

    return (
        <div>
            {
                props.targetRole ?
                      <div>
                        <Row>
                          <Typography variant="h5" className="px-3 py-3">Skillset Requirements as a {props.targetRole.name}</Typography>
                        </Row>
                        <Row>
                            {
                                props.assessments && props.assessments.length> 0 && props.assessments.map((assessment) => {
                                    return <Col key={assessment.id} className="my-1" xs={3}><AssessmentCard assessment={assessment} completedAssessments={props.completedAssessments.filter(a=>a.assessmentId===assessment.id)}/></Col>
                                })
                            }
                        </Row>
                      </div>
                    :
                      <div>
                          <Row>
                              <Typography variant="body1" className="px-3 py-3">Update your profile with your <b>Dream Job</b> so that we can recommend assessments for you.</Typography>
                              <Button onClick={()=>history.push('/account/profile')}>Update my profile</Button>
                          </Row>
                      </div>

            }



        </div>
    );
};
