import './audit-question-update.scss';
import React from 'react';
import {connect} from 'react-redux';
import {RouteComponentProps} from 'react-router-dom';
import {Button, Col, Label, Row} from 'reactstrap';
import {AvFeedback, AvField, AvForm, AvGroup, AvInput} from 'availity-reactstrap-validation';
import {byteSize, openFile, Translate, translate} from 'react-jhipster';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBars} from '@fortawesome/free-solid-svg-icons';
import {IRootState} from 'app/shared/reducers';
import "@pathofdev/react-tag-input/build/index.css";
import {WithContext as ReactTags} from 'react-tag-input';
import SyntaxHighlighter from 'react-syntax-highlighter';
import {docco} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import {
    SortableContainer,
    SortableContainerProps,
    SortableElement,
    SortableElementProps,
    SortableHandle
} from 'react-sortable-hoc';
import arrayMove from 'array-move';
import {getEntities as getAudits} from 'app/entities/audit/audit.reducer';
import {createEntity, getEntity, reset, updateEntity} from './audit-question.reducer';
import {defaultValue, IAuditQuestion} from 'app/shared/model/audit-question.model';
import {
    ANSWER_TYPES,
    MARK_CONSTANTS,
    MULTI_CHOICE_TYPE,
    QUESTION_TYPES,
    SCORE_YES
} from 'app/shared/util/global.constants';
import {IQuestionFile} from 'app/shared/model/question-file.model';
import {
    defaultValue as questionOptionDefault,
    IAuditQuestionOption
} from 'app/shared/model/audit-question-option.model';
import {displayHtml, displayImage, toBase64} from 'app/shared/util/common-utils';
import AuditQuestionService from 'app/shared/service/audit-question.service';
import AuditService from 'app/shared/service/audit.service';


const KeyCodes = {
  comma: 188,
  enter: 13,
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];
const DragHandle = SortableHandle(() => <FontAwesomeIcon icon={faBars} color="#A8A8A8"/>);

export interface IQuestionItemProps extends SortableElementProps {
  auditQuestionOption: IAuditQuestionOption;
  model: IAuditQuestion;
  onEditOption: Function;
  onRemoveOption: Function;
  renderEditOption: Function;
  isEdit: boolean;
}

export interface IQuestionProps extends SortableContainerProps {
  auditQuestionOptions: IAuditQuestionOption[];
  editItem: IAuditQuestionOption;
  model: IAuditQuestion;
  onEditOption: Function;
  onRemoveOption: Function;
  renderEditOption: Function;
}

export interface IFileProps extends SortableElementProps {
  questionFile: IQuestionFile;
  onRemoveOption: Function;
}

export interface IFilesProps extends SortableContainerProps {
  questionFiles: IQuestionFile[];
  onRemoveOption: Function;
}

const SortableItem = SortableElement((props: IQuestionItemProps) => {
    const {auditQuestionOption, model, onEditOption, onRemoveOption, isEdit, renderEditOption} = props;

    let action;
    if (isEdit) {
      action = renderEditOption(auditQuestionOption);
    } else {
      action = (<li style={{marginTop: "15px", marginBottom: "15px"}}>
        <Row>
          <Col>
            {parseInt(model.type + "", 10) === MULTI_CHOICE_TYPE ?
              (<div>
                <div className="pretty p-icon p-smooth">
                  <input type="checkbox"/>
                  <div className="state p-primary">
                    <i className="icon mdi mdi-check"></i>
                    {!auditQuestionOption.highlight ?
                      <label style={{
                        wordWrap: "break-word",
                        whiteSpace: "pre-wrap",
                        display: "inline-flex",
                        paddingLeft: "20px"
                      }} dangerouslySetInnerHTML={displayHtml(auditQuestionOption.title)}></label>
                      : <span></span>}
                    <label>&nbsp;</label>
                  </div>
                </div>
                {auditQuestionOption.highlight ? (
                  <SyntaxHighlighter style={docco} showLineNumbers={true}>
                    {auditQuestionOption.title}
                  </SyntaxHighlighter>
                ) : (<span></span>)
                }
                {parseInt(auditQuestionOption.score + "", 10) === SCORE_YES ?
                  <FontAwesomeIcon icon="check" style={{color: "green"}}/> : ""}
              </div>) : (<div>
                <div className="pretty p-icon p-round p-smooth">
                  <input type="radio"/>
                  <div className="state p-primary">
                    <i className="icon mdi mdi-check"></i>
                    {!auditQuestionOption.highlight ?
                      <label style={{
                        wordWrap: "break-word",
                        whiteSpace: "pre-wrap",
                        display: "inline-flex",
                        paddingLeft: "20px"
                      }} dangerouslySetInnerHTML={displayHtml(auditQuestionOption.title)}></label>
                      : <span></span>}
                    <label>&nbsp;</label>
                  </div>
                </div>
                {auditQuestionOption.highlight ? (
                  <SyntaxHighlighter style={docco} showLineNumbers={true}>
                    {auditQuestionOption.title}
                  </SyntaxHighlighter>
                ) : (<span></span>)
                }
                {parseInt(auditQuestionOption.score + "", 10) === SCORE_YES ?
                  <FontAwesomeIcon icon="check" style={{color: "green"}}/> : ""}
              </div>)
            }
          </Col>
        </Row>
        <Row style={{marginTop: "-25px"}}>
          <Col>
            <div className="float-right">
              <Button onClick={() => onEditOption(auditQuestionOption)} color="info">
                <FontAwesomeIcon icon="pencil-alt"/>
                &nbsp;
                <span className="d-none d-md-inline">
                      <Translate contentKey="entity.action.edit"></Translate>
                    </span>
              </Button>
              &nbsp;
              <Button color="danger" onClick={() => onRemoveOption(auditQuestionOption)}>
                <FontAwesomeIcon icon="trash"/>
                &nbsp;
                <Translate contentKey="entity.action.delete">Delete</Translate>
              </Button>
              &nbsp;&nbsp;
              <DragHandle/>
            </div>
          </Col>
        </Row>
      </li>);
    }
    return action;
  }
);

const SortableList = SortableContainer((props: IQuestionProps) => {
  const {auditQuestionOptions, model, onEditOption, onRemoveOption, renderEditOption, editItem} = props;
  return (
    <ul>
      {auditQuestionOptions.map((item, index) => (
        <SortableItem key={`item-${item.title}`} index={index} auditQuestionOption={item} model={model}
                      onEditOption={onEditOption}
                      onRemoveOption={onRemoveOption} renderEditOption={renderEditOption} isEdit={editItem === item}/>
      ))}
    </ul>
  );
});

const SortableImageItem = SortableElement((props: IFileProps) => {
    const {questionFile, onRemoveOption} = props;

    return (<li style={{marginTop: "15px", marginBottom: "15px"}}>
      <Row>
        <Col>
          <div style={{marginBottom: "5px"}}>
            <img style={{padding: "10px"}} src={displayImage(questionFile.data)}/>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="float-right">
            <Button color="danger" onClick={() => onRemoveOption(questionFile)}>
              <FontAwesomeIcon icon="trash"/>
              &nbsp;
              <Translate contentKey="entity.action.delete">Delete</Translate>
            </Button>
          </div>
        </Col>
      </Row>
    </li>);
  }
);

const ImageSortableList = SortableContainer((props: IFilesProps) => {
  const {onRemoveOption, questionFiles} = props;
  return (
    <ul>
      {questionFiles.map((item, index) => (
        <SortableImageItem key={`item-${item.data}`} index={index} questionFile={item}
                           onRemoveOption={onRemoveOption}/>
      ))}
    </ul>
  );
});

export interface IAuditQuestionUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string, auditId: string, rnd: string }> {
}

export interface IAuditQuestionUpdateState {
  isNew: boolean;
  auditId: string;
  code: string;
  model: IAuditQuestion;
  tags: any;
  auditTags: any;
  questionFiles: IQuestionFile[];
  auditQuestionOption: IAuditQuestionOption;
  editAuditQuestionOption: IAuditQuestionOption;
  auditQuestionOptions: IAuditQuestionOption[];
}

export class AuditQuestionUpdate extends React.Component<IAuditQuestionUpdateProps, IAuditQuestionUpdateState> {
  auditQuestionService: AuditQuestionService;
  auditService: AuditService;
  tagSuggestions: any;

  constructor(props) {
    super(props);
    this.state = {
      auditId: '0',
      isNew: !this.props.match.params || !this.props.match.params.id,
      code: "",
      model: {...defaultValue, audit: {id: this.props.match.params.auditId}},
      tags: [],
      auditTags: [],
      questionFiles: [],
      auditQuestionOption: questionOptionDefault,
      editAuditQuestionOption: null,
      auditQuestionOptions: [],
    };
    this.tagSuggestions = [];

    this.auditQuestionService = new AuditQuestionService();
    this.auditService = new AuditService();
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextProps.updateSuccess !== this.props.updateSuccess && nextProps.updateSuccess) {
      this.handleClose();
    }
  }

  componentDidMount() {
    if (this.props.match.params.id) {
      this.auditQuestionService.getEntity(this.props.match.params.id).then(response => {
        console.log(response);
        response.minutes = response.time.split(":")[0];
        response.seconds = response.time.split(":")[1];
        const questionOptions = response.auditQuestionOptions;
        for (let i = 0; i < questionOptions.length; i++) {
          questionOptions[i].isHighlight = questionOptions[i].isHighlight === null ? false : questionOptions[i].isHighlight;
        }
        const currentTags = [];
        if (response.tags) {
          const tags = response.tags.split(",");
          for (let i = 0; i < tags.length; i++) {
            currentTags.push({id: tags[i], text: tags[i]});
          }
        }
        this.setState({
          model: response, tags: currentTags, questionFiles: response.questionFiles ? response.questionFiles : {},
          auditQuestionOptions: questionOptions, auditQuestionOption: {title: "", score: -1, highlight: false},
        });

        this.auditService.getAuditTags(response.auditId).then(data => {
          console.log(data);
          for (let i = 0; i < data.length; i++) {
            data[i].text = data[i].value;
          }
          this.setState({auditTags: data});
        });
      });
    } else {
      this.auditService.getAuditTags(this.props.match.params.auditId).then(data => {
        console.log(data);
        for (let i = 0; i < data.length; i++) {
          data[i].text = data[i].value;
        }
        this.setState({auditTags: data, auditQuestionOption: {title: "", score: -1, highlight: false},});
      });
    }


  }

  validForm() {
    const {auditQuestionOptions, model} = this.state;
    let result = true;
    if (auditQuestionOptions.length === 0 || !model.title || !(model.minutes || model.seconds)) {
      result = false;
    }
    return result;
  }

  saveQuestion = () => {
    if (!this.validForm()) {
      return;
    }
    const {questionFiles, auditQuestionOptions, tags} = this.state;
    const obj = this.state.model;
    obj.time = obj.minutes + ":" + (parseInt(obj.seconds + "", 10) < 9 ? "0" + obj.seconds : obj.seconds);

    for (let i = 0; i < questionFiles.length; i++) {
      questionFiles[i].order = i;
    }
    obj["questionFiles"] = questionFiles;

    for (let i = 0; i < auditQuestionOptions.length; i++) {
      auditQuestionOptions[i].order = i;
    }
    obj["questionOptions"] = auditQuestionOptions;
    console.log(obj);
    const standardTags = [];
    if (tags.length !== 0) {
      for (let i = 0; i < tags.length; i++) {
        standardTags.push(tags[i].text);
      }
    }
    obj.tags = standardTags.join(",");

    if (!this.props.match.params.id) {
      this.auditQuestionService.createEntity(obj).then(response => {
        console.log(response);
        if (response.id) {
          this.handleClose();
        }
      })
    } else {
      obj.audit = {id: obj.auditId};
      this.auditQuestionService.updateEntity(obj).then(response => {
        console.log(response);
        if (response.id) {
          this.handleClose();
        }
      })
    }
  };

  handleClose = () => {
    this.props.history.goBack();
  };

  goBack = () => {
    this.handleClose();
  }

  changeTags = chips => {

    this.setState({tags: chips});
  }

  validTagInput = (value) => {
    const {tags} = this.state;
    const indexOf = tags.indexOf(value);
    if (indexOf === -1) {
      return true;
    }
    return false;
  }

  handleDelete(i) {
    console.log(i);
    const tags = this.state.tags.slice(0);
    tags.splice(i, 1);
    this.setState({tags})
  }

  handleAddition(tag) {
    console.log(tag);
    const {tags} = this.state;
    let existed = false;
    for (let i = 0; i < tags.length; i++) {
      if (tags[i].text === tag.text) {
        existed = true;
        break;
      }
    }
    console.log(tag);
    console.log(existed);

    if (!existed) {
      const newTags = [].concat(tags, tag);
      this.setState({tags: newTags});
      return true;
    } else {
      return false;
    }
  }

  onBlobChange = (event, isAnImage, name) => {
    const questionFiles = [];
    if (event && event.target.files && event.target.files[0]) {
      const length = event.target.files.length;
      const files = event.target.files;
      for (let i = 0; i < length; i++) {
        const file = files[i];

        toBase64(file, base64Data => {
          const questionFile = {name: file.name, order: i, dataContentType: file.type, data: base64Data};
          questionFiles.push(questionFile);

          if (i === length - 1) {
            this.setState({questionFiles: questionFiles});
          }
        });
      }

    }

  };

  clearBlob = name => () => {
    const {questionFiles} = this.state;
    questionFiles.length = 0;
  }

  setModelValue = (field, event) => {
    const {auditQuestionOption} = this.state;
    if (field === "highlight") {
      console.log(event.target.checked);
      auditQuestionOption[field] = event.target.checked;
    } else {
      auditQuestionOption[field] = event.target.value;
    }

    this.setState({auditQuestionOption: auditQuestionOption});
  }

  setAuditQuestionValue = (field, event) => {
    const {model} = this.state;
    if (field === "highlight") {
      model[field] = event.target.checked;
    } else {
      model[field] = event.target.value;
    }
    console.log(model);
    this.setState({model: model});
  }

  addQuestionOption = () => {
    const {auditQuestionOptions} = this.state;
    const {auditQuestionOption} = this.state;
    if (!auditQuestionOption.title || auditQuestionOption.score === -1) {
      return;
    }
    const newObj = Object.assign({}, auditQuestionOption);
    auditQuestionOptions.push(newObj);

    this.setState({
      auditQuestionOptions: auditQuestionOptions,
      auditQuestionOption: {title: "", score: -1, highlight: false},
      editAuditQuestionOption: null
    });
  }

  saveEditQuestionOption = () => {
    const {auditQuestionOptions} = this.state;
    const {auditQuestionOption, editAuditQuestionOption} = this.state;
    if (!auditQuestionOption.title || auditQuestionOption.score === -1) {
      return;
    }
    const indexOf = auditQuestionOptions.indexOf(editAuditQuestionOption);
    const newObj = Object.assign({}, auditQuestionOption);
    if (indexOf >= 0) {
      auditQuestionOptions.splice(indexOf, 1);
      auditQuestionOptions.splice(indexOf, 0, newObj);
    }
    this.setState({
      auditQuestionOptions: auditQuestionOptions,
      auditQuestionOption: {title: "", score: -1, highlight: false},
      editAuditQuestionOption: null
    });
  }

  editQuestionOption = (item) => {
    this.setState({auditQuestionOption: Object.assign({}, item), editAuditQuestionOption: item});
  }

  cancelEditQuestionOption = () => {
    this.setState({editAuditQuestionOption: null, auditQuestionOption: {title: "", score: -1, highlight: false}});
  }

  removeQuestionOption = (item) => {
    const {auditQuestionOptions} = this.state;
    const indexOf = auditQuestionOptions.indexOf(item);
    if (indexOf >= 0) {
      auditQuestionOptions.splice(indexOf, 1);
      this.setState({auditQuestionOptions: auditQuestionOptions});
    }
  }

  removeFile = (item) => {
    const {questionFiles} = this.state;
    const indexOf = questionFiles.indexOf(item);
    if (indexOf >= 0) {
      questionFiles.splice(indexOf, 1);
      this.setState({questionFiles: questionFiles});
    }
  }

  renderQuestionOption = () => {
    const {auditQuestionOptions, model, editAuditQuestionOption} = this.state;

    return auditQuestionOptions.length !== 0 ? auditQuestionOptions.map((auditQuestionOption, index) => (
        <div key={index}>
          {auditQuestionOptions.indexOf(editAuditQuestionOption) !== index ?
            (<div style={{marginTop: "15px", marginBottom: "15px"}}>
              <Row>
                <Col>
                  {parseInt(model.type + "", 10) === MULTI_CHOICE_TYPE ?
                    (<div>
                      <div className="pretty p-icon p-smooth">
                        <input type="checkbox"/>
                        <div className="state p-primary">
                          <i className="icon mdi mdi-check"></i>
                          {!auditQuestionOption.highlight ?
                            <label style={{
                              wordWrap: "break-word",
                              whiteSpace: "pre-wrap",
                              display: "inline-flex",
                              paddingLeft: "20px"
                            }} dangerouslySetInnerHTML={displayHtml(auditQuestionOption.title)}></label>
                            : <span></span>}
                          <label>&nbsp;</label>
                        </div>
                      </div>
                      {auditQuestionOption.highlight ? (
                        <SyntaxHighlighter style={docco} showLineNumbers={true}>
                          {auditQuestionOption.title}
                        </SyntaxHighlighter>
                      ) : (<span></span>)
                      }
                      {parseInt(auditQuestionOption.score + "", 10) === SCORE_YES ?
                        <FontAwesomeIcon icon="check" style={{color: "green"}}/> : ""}
                    </div>) : (<div>
                      <div className="pretty p-icon p-round p-smooth">
                        <input type="radio"/>
                        <div className="state p-primary">
                          <i className="icon mdi mdi-check"></i>
                          {!auditQuestionOption.highlight ?
                            <label style={{
                              wordWrap: "break-word",
                              whiteSpace: "pre-wrap",
                              display: "inline-flex",
                              paddingLeft: "20px"
                            }} dangerouslySetInnerHTML={displayHtml(auditQuestionOption.title)}></label>
                            : <span></span>}
                          <label>&nbsp;</label>
                        </div>
                      </div>
                      {auditQuestionOption.highlight ? (
                        <SyntaxHighlighter style={docco} showLineNumbers={true}>
                          {auditQuestionOption.title}
                        </SyntaxHighlighter>
                      ) : (<span></span>)
                      }
                      {parseInt(auditQuestionOption.score + "", 10) === SCORE_YES ?
                        <FontAwesomeIcon icon="check" style={{color: "green"}}/> : ""}
                    </div>)
                  }
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="float-right">
                    <Button onClick={() => this.editQuestionOption(auditQuestionOption)} color="info">
                      <FontAwesomeIcon icon="pencil-alt"/>
                      &nbsp;
                      <span className="d-none d-md-inline">
                      <Translate contentKey="entity.action.edit"></Translate>
                    </span>
                    </Button>
                    &nbsp;
                    <Button color="danger" onClick={() => this.removeQuestionOption(auditQuestionOption)}>
                      <FontAwesomeIcon icon="trash"/>
                      &nbsp;
                      <Translate contentKey="entity.action.delete">Delete</Translate>
                    </Button>
                  </div>
                </Col>
              </Row>
            </div>) :
            this.renderEditQuestionOption(editAuditQuestionOption)
          }
        </div>
      ))
      : null;
  }

  onSortEnd = ({oldIndex, newIndex}) => {
    this.setState(({auditQuestionOptions}) => ({
      auditQuestionOptions: arrayMove(auditQuestionOptions, oldIndex, newIndex),
    }));
  }

  onSortImageEnd = ({oldIndex, newIndex}) => {
    this.setState(({questionFiles}) => ({
      questionFiles: arrayMove(questionFiles, oldIndex, newIndex),
    }));
  }

  renderQuestionOption2 = () => {
    const {auditQuestionOptions, model, editAuditQuestionOption} = this.state;

    return <SortableList auditQuestionOptions={auditQuestionOptions} model={model} onSortEnd={this.onSortEnd}
                         onEditOption={this.editQuestionOption} onRemoveOption={this.removeQuestionOption}
                         renderEditOption={this.renderEditQuestionOption}
                         editItem={editAuditQuestionOption} useDragHandle={true}/>;
  }

  renderEditQuestionOption = (editItem) => {
    const auditQuestionOption = Object.assign({}, editItem);
    return (<div>
      <AvForm model={auditQuestionOption}>
        <Row>
          <Col md="6">
            <AvGroup>
              <Label id="titleLabel" for="auditQuestion-title">
                <Translate contentKey="evaluateyouApp.auditQuestion.title">Title</Translate>
              </Label>
              <AvField
                id="auditQuestion-title"
                type="textarea"
                name="title" value={auditQuestionOption.title}

                onChange={(event) => this.setModelValue("title", event)}
              />
            </AvGroup>
          </Col>
          <Col>
            <AvGroup>
              <Label for="audit-question-answer">
                <Translate contentKey="evaluateyouApp.auditQuestion.answer"></Translate>
              </Label>
              <AvInput
                id="audit-question-answer"
                type="select"
                className="form-control"
                name="score"
                value={auditQuestionOption.score}

                onChange={(event) => this.setModelValue("score", event)}
              >
                {ANSWER_TYPES.map(item => (
                  <option value={item.value} key={item.value}>
                    {item.label}
                  </option>
                ))}
              </AvInput>
              <AvFeedback>
                <Translate contentKey="entity.validation.required">This field is required.</Translate>
              </AvFeedback>
            </AvGroup>
          </Col>
          <Col>
            <div style={{marginTop: "35px"}}>
              <div className="pretty p-icon p-smooth">
                <input type="checkbox" defaultChecked={auditQuestionOption.highlight}
                       onChange={(event) => this.setModelValue("highlight", event)}/>
                <div className="state p-primary">
                  <i className="icon mdi mdi-check"></i>
                  <label>
                    <Translate contentKey="evaluateyouApp.auditQuestion.syntaxHighlight"></Translate>
                  </label>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        {auditQuestionOption.highlight ?
          <Row>
            <Col>
              {auditQuestionOption.title ? (
                <SyntaxHighlighter showLineNumbers={true}>
                  {auditQuestionOption.title}
                </SyntaxHighlighter>) : ""}
            </Col>
          </Row> : ""}
        <Row>
          <Col>
            <div className="marginBottom">
              <Button onClick={() => this.saveEditQuestionOption()} color="info">
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.save"></Translate>
                </span>
              </Button>&nbsp;
              <Button onClick={() => this.cancelEditQuestionOption()}>
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.cancel"></Translate>
                </span>
              </Button>
            </div>
          </Col>
        </Row>

      </AvForm>
    </div>);
  }

  renderAddQuestionOption() {
    const {loading, updating} = this.props;
    const {isNew, auditQuestionOption, editAuditQuestionOption} = this.state;
    return (<div>
      <Row className="justify-content-center">
        <hr/>
      </Row>
      <Row className="justify-content-center">
        <Col>
          <h2>
            <Translate contentKey="evaluateyouApp.auditQuestion.answerChoices"></Translate>
          </h2>
        </Col>
      </Row>

      {this.renderQuestionOption2()}
      {editAuditQuestionOption === null ? (
        <AvForm model={auditQuestionOption}>
          <Row>
            <Col md="6">
              <AvGroup>
                <Label id="titleLabel" for="auditQuestion-title">
                  <Translate contentKey="evaluateyouApp.auditQuestion.title">Title</Translate>
                </Label>
                <AvField
                  id="auditQuestion-title"
                  type="textarea"
                  name="title" value={auditQuestionOption.title}

                  onChange={(event) => this.setModelValue("title", event)}
                />
              </AvGroup>
            </Col>
            <Col>
              <AvGroup>
                <Label for="audit-question-answer">
                  <Translate contentKey="evaluateyouApp.auditQuestion.answer"></Translate>
                </Label>
                <AvInput
                  id="audit-question-answer"
                  type="select"
                  className="form-control"
                  name="score"
                  value={auditQuestionOption.score}

                  onChange={(event) => this.setModelValue("score", event)}
                >
                  {ANSWER_TYPES.map(item => (
                    <option value={item.value} key={item.value}>
                      {item.label}
                    </option>
                  ))}
                </AvInput>
                <AvFeedback>
                  <Translate contentKey="entity.validation.required">This field is required.</Translate>
                </AvFeedback>
              </AvGroup>
            </Col>
            <Col>
              <div style={{marginTop: "35px"}}>
                <div className="pretty p-icon p-smooth">
                  <input type="checkbox" checked={auditQuestionOption.highlight}
                         onChange={(event) => this.setModelValue("highlight", event)}/>
                  <div className="state p-primary">
                    <i className="icon mdi mdi-check"></i>
                    <label>
                      <Translate contentKey="evaluateyouApp.auditQuestion.syntaxHighlight"></Translate>
                    </label>
                  </div>
                </div>
              </div>
            </Col>
            <Col>
              <div style={{marginTop: "35px"}}>
                <Button onClick={() => this.addQuestionOption()} color="info">
                  <FontAwesomeIcon icon="plus"/>&nbsp;
                  <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.add"></Translate>
                </span>
                </Button>
              </div>
            </Col>
          </Row>
          {auditQuestionOption.highlight ?
            <Row>
              <Col>
                {auditQuestionOption.title ? (
                  <SyntaxHighlighter showLineNumbers={true}>
                    {auditQuestionOption.title}
                  </SyntaxHighlighter>) : ""}
              </Col>
            </Row> : ""}

        </AvForm>) : ""}

    </div>);
  }

  displayImage = (questionFile) => {
    const imageUrl = "data:image/;base64," + questionFile.data;
    return imageUrl;
  }

  render() {
    const {loading} = this.props;
    const {isNew, model, tags, questionFiles, auditTags} = this.state;
    return (
      <div>
        <Row className="justify-content-center">
          <Col md="8">
            <h2>
              {isNew ?
                <Translate contentKey="evaluateyouApp.auditQuestion.home.createOrEditLabel">Create or edit a
                  AuditQuestion</Translate> :
                <Translate contentKey="evaluateyouApp.auditQuestion.home.editLabel"></Translate>}
            </h2>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md="8">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <div>
                <AvForm model={model}>
                  <AvGroup>
                    <Label id="titleLabel" for="audit-question-title">
                      <Translate contentKey="evaluateyouApp.auditQuestion.title">Title</Translate>
                    </Label>
                    <AvField
                      id="audit-question-title"
                      type="textarea"
                      name="title" value={model.title ? model.title : ""}
                      validate={{
                        required: {value: true, errorMessage: translate('entity.validation.required')}
                      }}
                      onChange={(event) => this.setAuditQuestionValue("title", event)}
                    />
                  </AvGroup>
                  <Row>
                    <Col>
                      <AvGroup>
                        <Label for="audit-question-type">
                          <Translate contentKey="evaluateyouApp.auditQuestion.questionType">Question Type</Translate>
                        </Label>
                        <AvInput
                          id="audit-question-type"
                          type="select"
                          className="form-control"
                          name="type"
                          value={isNew ? QUESTION_TYPES[0] && QUESTION_TYPES[0].value : model.type}
                          required
                          onChange={(event) => this.setAuditQuestionValue("type", event)}
                        >
                          {QUESTION_TYPES.map(item => (
                            <option value={item.value} key={item.value}>
                              {item.label}
                            </option>
                          ))}
                        </AvInput>
                        <AvFeedback>
                          <Translate contentKey="entity.validation.required">This field is required.</Translate>
                        </AvFeedback>
                      </AvGroup>
                    </Col>
                    <Col>
                      <AvGroup>
                        <Label for="audit-question-score">
                          <Translate contentKey="evaluateyouApp.auditQuestion.difficulty">Difficulty</Translate>
                        </Label>
                        <AvInput
                          id="audit-question-score"
                          type="select"
                          className="form-control"
                          name="score"
                          value={isNew ? MARK_CONSTANTS[0] && MARK_CONSTANTS[0].value : model.score}
                          required
                          onChange={(event) => this.setAuditQuestionValue("score", event)}
                        >
                          {MARK_CONSTANTS.map(item => (
                            <option value={item.value} key={item.value}>
                              {item.label}
                            </option>
                          ))}
                        </AvInput>
                        <AvFeedback>
                          <Translate contentKey="entity.validation.required">This field is required.</Translate>
                        </AvFeedback>
                      </AvGroup>
                    </Col>
                  </Row>
                  <AvGroup>
                    <Label id="codeLabel" for="audit-question-code">
                      <Translate contentKey="evaluateyouApp.auditQuestion.code">Code</Translate>
                    </Label>
                    <AvField
                      id="audit-question-code"
                      type="textarea"
                      name="code"
                      validate={{
                        maxLength: {value: 6000, errorMessage: translate('entity.validation.maxlength', {max: 6000})}
                      }}
                      value={model.code ? model.code : ""}
                      onChange={(event) => this.setAuditQuestionValue("code", event)}
                    />
                    {model.code ? (
                      <SyntaxHighlighter showLineNumbers={true}>
                        {model.code}
                      </SyntaxHighlighter>) : ""}

                  </AvGroup>

                  <AvGroup>
                    <Label id="dataLabel" for="file_data">
                      <Translate contentKey="evaluateyouApp.auditQuestion.images">Images</Translate>
                    </Label>
                    <br/>
                    {questionFiles.length !== 0 ? questionFiles.map(item => {
                        <div>
                          <a onClick={openFile(item.dataContentType, item.data)}>
                            <Translate contentKey="entity.action.open">Open</Translate>
                          </a>
                          <br/>
                          <Row>
                            <Col md="11">
                          <span>
                            {item.dataContentType}, {byteSize(item.data)}
                          </span>
                            </Col>
                            <Col md="1">
                              <Button color="danger" onClick={this.clearBlob('data')}>
                                <FontAwesomeIcon icon="times-circle"/>
                              </Button>
                            </Col>
                          </Row>
                        </div>
                      }
                    ) : null}

                    {questionFiles.length !== 0 ?

                      <ImageSortableList questionFiles={questionFiles} onSortEnd={this.onSortImageEnd}
                                         onRemoveOption={this.removeFile}/>
                      : null}


                    <input id="file_data" type="file" multiple
                           onChange={(event) => this.onBlobChange(event, false, 'data')}/>
                  </AvGroup>

                  <AvGroup>
                    <Label id="commentLabel" for="audit-question-comment">
                      <Translate contentKey="evaluateyouApp.auditQuestion.comment">Comment</Translate>
                    </Label>
                    <AvField
                      id="audit-question-comment"
                      type="textarea"
                      name="comment"
                      value={model.comment ? model.comment : ""}
                      validate={{
                        maxLength: {value: 6000, errorMessage: translate('entity.validation.maxlength', {max: 6000})}
                      }}
                      onChange={(event) => this.setAuditQuestionValue("comment", event)}
                    />
                  </AvGroup>
                  <AvGroup>
                    <Row>
                      <Col md="1">
                        <Label id="timeLabel" for="audit-question-minute">
                          <Translate contentKey="evaluateyouApp.auditQuestion.time">Time</Translate>
                        </Label>
                      </Col>
                      <Col md="2">
                        <AvField
                          id="audit-question-minute"
                          type="number"
                          name="minutes"
                          placeholder="Minutes"
                          validate={{
                            required: {value: true, errorMessage: translate('entity.validation.required')},
                            maxLength: {value: 2, errorMessage: translate('entity.validation.maxlength', {max: 2})}
                          }}
                          min="0" max="59"
                          onChange={(event) => this.setAuditQuestionValue("minutes", event)}
                        />
                      </Col>
                      <Col md="2">
                        <AvField
                          id="audit-question-second"
                          type="number"
                          name="seconds"
                          placeholder="Seconds"
                          validate={{
                            required: {value: true, errorMessage: translate('entity.validation.required')},
                            maxLength: {value: 2, errorMessage: translate('entity.validation.maxlength', {max: 2})}
                          }}
                          min="0" max="59"
                          onChange={(event) => this.setAuditQuestionValue("seconds", event)}
                        />
                      </Col>
                    </Row>
                  </AvGroup>
                  <ReactTags tags={tags}
                             suggestions={auditTags}
                             handleDelete={(i) => this.handleDelete(i)}
                             handleAddition={(value) => this.handleAddition(value)}
                             delimiters={delimiters}/>

                </AvForm>

                {this.renderAddQuestionOption()}

                <Button onClick={() => this.goBack()} color="info">
                  <FontAwesomeIcon icon="arrow-left"/>
                  &nbsp;
                  <span className="d-none d-md-inline">
                    <Translate contentKey="entity.action.back">Back</Translate>
                  </span>
                </Button>
                &nbsp;
                <Button color="primary" onClick={(event) => this.saveQuestion()}>
                  <FontAwesomeIcon icon="save"/>
                  &nbsp;
                  <Translate contentKey="entity.action.save">Save</Translate>
                </Button>
              </div>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  audits: storeState.audit.entities,
  auditQuestionEntity: storeState.auditQuestion.entity,
  loading: storeState.auditQuestion.loading,
  updating: storeState.auditQuestion.updating,
  updateSuccess: storeState.auditQuestion.updateSuccess
});

const mapDispatchToProps = {
  getAudits,
  getEntity,
  updateEntity,
  createEntity,
  reset
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AuditQuestionUpdate);
