import React from 'react';
import {Col, Row} from 'reactstrap';
import {faExclamation} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const WarningBubble = (props: {description:string}) =>{


    return (
        <Row>
            <Col xs={12} style={{backgroundColor:'#F9EEB4', padding:10, borderRadius:10}}>
                <FontAwesomeIcon icon={faExclamation} size={'1x'}/>&nbsp;&nbsp;{props.description}
            </Col>
        </Row>
    );

}
