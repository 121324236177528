import React from 'react';
import '../../assessment/assessment.scss';
import {Col, Row, Table} from 'reactstrap';
import {TextFormat, Translate} from 'react-jhipster';
import "@pathofdev/react-tag-input/build/index.css";

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import TextField from '@material-ui/core/TextField';
import {ITrainingDate} from 'app/shared/model/training-date.model';
import {ITrainingSchedule} from 'app/shared/model/training-schedule.model';
import {Button, FormControl, InputLabel, Tooltip} from '@material-ui/core';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import {DateTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import MomentUtils from '@date-io/moment';
import {isBlank} from "app/shared/util/common-utils";
import {ITrainingStepperChildProps} from 'app/entities/training/training-stepper';
import {APP_DATE_FORMAT} from 'app/config/constants';
import {ITrainingSession} from 'app/shared/model/training-session.model';
import {
    countEnrollmentsInDates,
    hasEnrollments
} from 'app/shared/util/training-utils';
import {WarningBubble} from 'app/shared/components/warning-bubble';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';


interface TrainingDateState {
    errors: string[];
    trainingDateForm: ITrainingDate;
    trainingDates: ITrainingDate[];
    trainingSchedules: ITrainingSchedule[];
    trainingSessions: ITrainingSession[];
    trainingScheduleId: number;
    trainingSessionId: number;
    addMode: boolean;
  error: boolean,
  errorMessage: any,

}

export class TrainingDate extends React.Component<ITrainingStepperChildProps, TrainingDateState> {

    constructor(props) {
        super(props);
        const dates = this.props.model.trainingSchedules.flatMap(ts=>ts.sessions).flatMap(s=>s.dates).filter(d=>d !== undefined);
        console.log(dates);
        console.log(this.props.model.trainingSchedules);
        console.log(this.props.model.trainingSchedules.flatMap(ts=>ts.sessions));

        this.state = {
          error: false,
          errorMessage: {},
            errors: [],
            trainingDateForm:{
              startDateTime: new Date(),
                endDateTime: new Date()
            },
            trainingDates:dates.filter(d=>d !== undefined).length>0?dates:[],
            trainingSchedules: this.props.model.trainingSchedules,
            trainingSessions: this.props.model.trainingSchedules.flatMap(ts=>ts.sessions),
            trainingScheduleId:0,
            trainingSessionId:0,
            addMode: true
        };
    }

    private getSessionName(id: number): string {
        const session = this.props.model.trainingSchedules.flatMap(ts=>ts.sessions).find(s=>s.id === id);
        return session !== undefined ? session.sessionName : '';
    }

    private getScheduleName(sessionId: number): string {
        const session = this.state.trainingSessions.find(s=>s.id === sessionId);
        return session !== undefined ? session.trainingSchedule.scheduleName : '';
    }

    private validate():void {
        console.log(this.state.trainingDates);
        this.props.populateModel(this.state.trainingDates);
        this.props.nextStep();
    }

    private goBack():void {
        this.props.populateModel(this.state.trainingDates);
        this.props.prevStep()
    }

    private addOrUpdateDate():void {

      if(isBlank(this.state.trainingDateForm.startDateTime)){
          console.log('start date is blank');
        this.setState({
          error: true,
          errorMessage: {
            startDateTime: "Please select a start date"
          }
        });
        return;
      }

      if(isBlank(this.state.trainingDateForm.endDateTime)){
          console.log("end date is blank");
        this.setState({
          error: true,
          errorMessage: {
            endDateTime: "Please select an end date"
          }
        });
          return;
      }

      if(isBlank(this.state.trainingDateForm.duration)){
          console.log('duration is blank');
        this.setState({
          error: true,
          errorMessage: {
            duration: "Please enter a duration"
          }
        });
          return;
      }


      // reset error state
      this.setState({error: false, errorMessage: {}});

      if(this.state.addMode){
            this.setState({trainingDates: [...this.state.trainingDates,
                    {...this.state.trainingDateForm,
                        id: (this.state.trainingDates.length + 1),
                        trainingSession: this.state.trainingSessions.find(s=>s.id===this.state.trainingSessionId)
                    }]});
        }else{
            this.state.trainingDates.forEach(ts => {
                if(ts.id === this.state.trainingDateForm.id){
                    ts.duration = this.state.trainingDateForm.duration;
                    ts.startDateTime = this.state.trainingDateForm.startDateTime;
                    ts.endDateTime = this.state.trainingDateForm.endDateTime;
                    ts.trainingSession = this.state.trainingSessions.find(s=>s.id===this.state.trainingSessionId);
                }
            });

            this.setState({trainingDates: [...this.state.trainingDates]});
        }

        // reset form
        this.setState({trainingDateForm: {
                startDateTime: new Date(),
                endDateTime: new Date()},
            addMode:true});
    }

    editTrainingDate(id:number): void {
        const selectedDate = this.state.trainingDates.filter(e=>e.id===id)[0];
        const selectedSession = this.state.trainingSessions.filter(e=>e.id===selectedDate.trainingSession.id)[0];
        const selectedSchedule = this.state.trainingSchedules.filter(e=>e.id===selectedSession.trainingSchedule.id)[0];
        this.setState({trainingDateForm: selectedDate,addMode:false});
        this.setState({trainingScheduleId: selectedSchedule.id});
        this.setState({trainingSessionId: selectedSession.id});

    }

    deleteTrainingDate(idx:number): void {
        this.state.trainingDates.splice(idx, 1);
        this.setState({trainingDates: [...this.state.trainingDates]});
    }




    render() {
        return (
            <div>
                <div className="page-title">
                    <h4>
                        <span><b>Dates</b></span>
                    </h4>
                    <h6>
                        Add dates to your training sessions. You may add multiple dates for each session.
                    </h6>
                </div>

                {
                    hasEnrollments(this.props.model) &&
                  <WarningBubble  description='Users have already been enrolled to this training. Some editable features will be limited'/>
                }

                {this.state.errors ? (<label style={{color: "red"}}>{
                    this.state.errors.map(error => <div key={error}><span>{error}</span><br/>
                    </div>)}</label>) : (<span></span>)}


                {
                    this.state.trainingDates.length > 0 &&
                  <Row>
                    <Col md={12}>
                      <Table responsive aria-describedby="assessment-heading" id="hoverMenu">
                        <thead>
                        <tr>
                          <th>Schedule</th>
                          <th>Session</th>
                          <th>Start Date</th>
                          <th>End Date</th>
                          <th>Duration</th>
                            {
                                hasEnrollments(this.props.model) &&
                              <th>Enrollments</th>
                            }
                          <th style={{width: 100, textAlign: 'left'}}>Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            this.state.trainingDates.map((trainingDate,idx) => (
                                <tr key={trainingDate.id}>
                                    <td>{trainingDate.trainingSession.id?this.getScheduleName(trainingDate.trainingSession.id):''}</td>
                                    <td>{this.getSessionName(trainingDate.trainingSession.id)}</td>
                                    <td><TextFormat type="date" value={trainingDate.startDateTime} format={APP_DATE_FORMAT}/></td>
                                    <td><TextFormat type="date" value={trainingDate.endDateTime} format={APP_DATE_FORMAT}/></td>
                                    <td>{trainingDate.duration}</td>
                                    {
                                        hasEnrollments(this.props.model) &&
                                      <td>{countEnrollmentsInDates(trainingDate,this.props.model.userTrainings)}</td>
                                    }
                                    <td style={{width: 100, textAlign: 'left'}}>
                                        <div className="text-left">
                                            <div className="btn-group flex-btn-group-container">
                                                <Button className="mr-3" onClick={() => this.editTrainingDate(trainingDate.id)}>
                                                    <span className="d-none d-md-inline">Edit</span>
                                                </Button>

                                                {
                                                    countEnrollmentsInDates(trainingDate, this.props.model.userTrainings) === 0 ?
                                                        <Button  onClick={() => this.deleteTrainingDate(idx)}>
                                                            <span className="d-none d-md-inline">Delete</span>
                                                        </Button>
                                                        :
                                                        <Tooltip title="Delete disabled since there are enrollments associated with this date" arrow>
                                                            <div style={{display:'flex'}} className="align-items-center justify-content-center w-100">
                                                                <FontAwesomeIcon icon={faExclamationCircle} size={'1x'}/>
                                                            </div>
                                                        </Tooltip>
                                                }

                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            ))
                        }
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                }

                <Row className="mt-4">
                    <Col md={6}>
                        <Row>
                            <Col md={6}>
                                <FormControl variant="filled" className="w-100">
                                    <InputLabel id="demo-simple-select-filled-label">Schedule</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-filled-label"
                                        id="demo-simple-select-filled"
                                        value={this.state.trainingScheduleId}
                                        onChange={(event)=>this.setState({trainingScheduleId: event.target.value as number})}
                                    >
                                        <MenuItem value={0}>
                                            <em>None</em>
                                        </MenuItem>
                                        {
                                            this.state.trainingSchedules.map(trainingSchedule =>
                                                <MenuItem key={trainingSchedule.id} value={trainingSchedule.id}>{trainingSchedule.scheduleName}</MenuItem>
                                            )
                                        }
                                    </Select>
                                </FormControl>
                            </Col>
                            <Col md={6}>
                                <FormControl variant="filled" className="w-100">
                                    <InputLabel id="demo-simple-select-filled-label">Session</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-filled-label"
                                        id="demo-simple-select-filled"
                                        value={this.state.trainingSessionId}
                                        onChange={(event)=>this.setState({trainingSessionId: event.target.value as number})}
                                    >
                                        <MenuItem value={0}>
                                            <em>None</em>
                                        </MenuItem>
                                        {
                                            this.state.trainingSessions.length > 0 && this.state.trainingSessions.filter(ts=>ts.trainingSchedule.id===this.state.trainingScheduleId)
                                                                                                                     .map(trainingSession =>
                                                <MenuItem key={trainingSession.id} value={trainingSession.id}>{trainingSession.sessionName}</MenuItem>
                                            )
                                        }
                                    </Select>
                                </FormControl>
                            </Col>

                        </Row>
                    </Col>
                    <Col md={6}>
                        <Row>
                            <Col md={4}>
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <DateTimePicker
                                        inputVariant="outlined"
                                        ampm={false}
                                        clearable={true}
                                        label="Start Time"
                                        value={this.state.trainingDateForm.startDateTime?this.state.trainingDateForm.startDateTime:new Date()}
                                        onChange={(m)=>this.setState({trainingDateForm: {...this.state.trainingDateForm, startDateTime: m.toDate()}})}
                                        onError={console.log}
                                        disablePast
                                        format="DD/MM/YYYY HH:mm"
                                        error={this.state.errorMessage.startDateTime}
                                        helperText="Start Date Time"
                                    />
                                </MuiPickersUtilsProvider>

                            </Col>

                            <Col md={4}>
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <DateTimePicker
                                        inputVariant="outlined"
                                        ampm={false}
                                        clearable={true}
                                        label="End Time"
                                        value={this.state.trainingDateForm.endDateTime?this.state.trainingDateForm.endDateTime:new Date()}
                                        onChange={(m)=>this.setState({trainingDateForm: {...this.state.trainingDateForm, endDateTime: m.toDate()}})}
                                        onError={console.log}
                                        disablePast
                                        format="DD/MM/YYYY HH:mm"
                                        error={this.state.errorMessage.endDateTime}
                                        helperText="End Date Time"
                                    />
                                </MuiPickersUtilsProvider>

                            </Col>

                            <Col md={3}>
                                <TextField id="assessment-title"
                                           label="Duration"
                                           variant="filled"
                                           type="number"
                                           fullWidth
                                           name="duration"
                                           value={this.state.trainingDateForm.duration?this.state.trainingDateForm.duration:''}
                                           onChange={(event)=>this.setState({trainingDateForm: {...this.state.trainingDateForm, duration: event.target.value}})}
                                           required
                                           error={this.state.errorMessage.duration}
                                           helperText="Hours"
                                />
                            </Col>
                            <Col md={1} className="justify-content-center my-auto" style={{height:56}}>
                                <Button onClick={() => this.addOrUpdateDate()}
                                        className="btn-primary">
                                    <span className="d-none d-md-inline">
                                    { this.state.addMode?'Add':'Update'}
                                    </span>
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>



                <div className="mt-5 mb-4">
                    <Button onClick={() => this.goBack()} variant="contained" color="primary"
                            className="btn-primary">
                        <FontAwesomeIcon icon="arrow-left"/>
                        &nbsp;
                        <span className="d-none d-md-inline">
                      <Translate contentKey="entity.action.prev">Back</Translate>
                    </span>
                    </Button>
                    <Button onClick={() => this.validate()} variant="contained" color="primary"
                            className="pull-right btn-primary">
                          <span className="d-none d-md-inline">
                            <Translate contentKey="entity.action.next"></Translate>
                          </span>&nbsp;
                        <FontAwesomeIcon icon="arrow-right"/>
                    </Button>
                </div>
            </div>
        );
    }

}
