import { IAssessment } from 'app/shared/model/assessment.model';
import { IAssessmentEmail } from 'app/shared/model/assessment-email.model';
import { IEntity } from 'app/shared/model/entity.model';

export interface IApplicantAssessment extends IEntity {
  id?: string;
  score?: number;
  maxScore?: number;
  examDataContentType?: string;
  examData?: any;
  fullName?: string;
  assessment?: IAssessment;
  assessmentId?: string;
  assessmentName?: string;
  assessmentEmail?: IAssessmentEmail;
  email?: string;
  spentTime?: number;
  tags?: string;
  assessmentPercent?: number;
}

export interface IApplicantAssessmentDTO extends IEntity {
  id?: string;
  assessmentId?: string;
  assessmentName?: string;
  fullName?: string;
  assessmentPercent: number;
  score?: number;
  maxScore?: number;
  spentTime?: number;
  totalTime?: number;
  allowViewScore?: boolean;
  assessmentExpiryDate: Date;
  tags?: string;
}

export const defaultValue: Readonly<IApplicantAssessment> = {};
