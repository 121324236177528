import { IEntity } from 'app/shared/model/entity.model';

export interface ICredit extends IEntity {
  id?: string;
  credit?: number;
  spentCredit?: number;
  pricePerCredit?: number;
}

export const defaultValue: Readonly<ICredit> = {};
