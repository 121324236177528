import React from 'react';
import {connect} from 'react-redux';
import {Link, RouteComponentProps} from 'react-router-dom';
import {
    Button,
    Card,
    Col,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row
} from 'reactstrap';
import {AvField, AvForm, AvGroup} from 'availity-reactstrap-validation';
import {Translate, translate} from 'react-jhipster';
import {IRootState} from 'app/shared/reducers';

import {createEntity, getEntity, getPricePerCredit, reset, updateEntity} from './credit.reducer';
import {CONSTANTS, COUPON_STATUS} from "app/shared/util/global.constants";
import CreditService from 'app/shared/service/credit.service';
import CouponService from 'app/shared/service/coupon.service';

export interface ICreditUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export interface ICreditUpdateState {
  isNew: boolean;
  amountSelect:number;
  amount: number;
  customAmount:number;
  paymentAmount: number;
  isShowCustomForm: boolean;
  isShowCouponForm: boolean;
  couponCode: string;
  isValidCoupon: number;
  useCustom:boolean;
  showSuccess:boolean;
  addedCredit:number;
}

export class CreditUpdate extends React.Component<ICreditUpdateProps, ICreditUpdateState> {
  creditService: CreditService;
  couponService: CouponService;
  constructor(props) {
    super(props);
    this.state = {
      isNew: !this.props.match.params || !this.props.match.params.id,
      amountSelect: 0,
      amount: 0,
      customAmount: 0,
      paymentAmount: 0,
      isShowCustomForm: false,
      isShowCouponForm: false,
      couponCode: "",
      isValidCoupon: -10,
      useCustom: false,
      showSuccess: false,
      addedCredit: 0
    };
    this.creditService = new CreditService();
    this.couponService = new CouponService();
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextProps.updateSuccess !== this.props.updateSuccess && nextProps.updateSuccess) {
      this.handleClose();
    }
  }

  componentDidMount() {
    this.props.getPricePerCredit();
  }

  saveEntity = (event, errors, values) => {
    if (errors.length === 0) {
      const { creditEntity } = this.props;
      const entity = {
        ...creditEntity,
        ...values
      };

      if (this.state.isNew) {
        this.props.createEntity(entity);
      } else {
        this.props.updateEntity(entity);
      }
    }
  };
  addCredit = () =>{
    if(this.state.amount > 0){
      this.creditService.payment(this.state.amount).then( response =>{
        if(response.status === CONSTANTS.SUCCESS){
          window.location = response.redirectUrl;
        }
      });
    }
  }
  submitCoupon = () =>{

    this.couponService.validAndUpdateUsedCoupon(this.state.couponCode).then( response =>{
      this.setState({isValidCoupon: response.code});
      if(this.state.isValidCoupon === COUPON_STATUS.ACCEPTED){
        this.setState({showSuccess:true,addedCredit:response.credit});
      }
    });
  }
  doUpdateCredit = () =>{
    this.setState({isShowCouponForm: false});
  }

  handleClose = () => {
    this.props.history.push('/credit');
  };
  changeAmount = (amount) => {
      const amountSelect = amount;
      let paymentAmount = amount;
      if(amount <= 0){
        amount = 0;
        paymentAmount = 0;
      }else{
        paymentAmount = parseFloat(amount * this.props.pricePerCredit + "").toFixed(2);
      }
      this.setState({amount: amount, amountSelect: amountSelect, paymentAmount: paymentAmount, useCustom: false});

  }
  changeCustomAmount = (amount) => {
    let paymentAmount: any;
    if(amount <= 0){
      amount = 0;
      paymentAmount = 0;
    }else{
      paymentAmount = parseFloat(amount * this.props.pricePerCredit + "").toFixed(2);
    }
    const {useCustom} = this.state;
    if(useCustom){
      this.setState({customAmount: amount, paymentAmount: paymentAmount});
    }else{
      this.setState({customAmount: amount});
    }


  }
  useCustomAmount = (event, amount) => {
    const amountSelect = amount;
    let paymentAmount = amount;
    if(amount <= 0){
      amount = 0;
      paymentAmount = 0;
    }else{
      paymentAmount = parseFloat(amount * this.props.pricePerCredit + "").toFixed(2);
    }
    this.setState({amount: amount, amountSelect: amountSelect, paymentAmount: paymentAmount, useCustom: true});

  }
  changeCode = (value) => {
    this.setState({couponCode: value});

  }
  displayAmount = (amount) => {
    return parseFloat(amount).toFixed(2);
  }

  renderCustomCreditForm(){
    const { isShowCustomForm } = this.state;

    let action = (<div>
      <Row className="justify-content-center">
        <Col>
          <AvGroup>
            <Label id="creditLabel" for="credit-credit">
              <Translate contentKey="evaluateyouApp.credit.amount">Amount</Translate>
            </Label>
            <AvField
              id="credit-credit"
              type="string"
              className="form-control"
              name="amount"
              validate={{
                required: { value: true, errorMessage: translate('entity.validation.required') },
                number: { value: true, errorMessage: translate('entity.validation.number') }
              }}
              onChange={(event) => this.changeAmount(event.target.value)}
            />
        </AvGroup>
        </Col>
      </Row>
    </div>);

    if(!this.state.isShowCustomForm){
      action = (<div></div>);
    }

    return action;
  }

  renderCouponForm(){
    const { isShowCouponForm, isValidCoupon } = this.state;

    let action = (<div>
      <Row style={{marginLeft:20}}>
        <Col>
          {isValidCoupon === COUPON_STATUS.EXPIRED ? (<label style={{color: "red"}}><Translate contentKey="evaluateyouApp.coupon.status.expired">Amount</Translate></label>) : (<span></span>)}
          {isValidCoupon === COUPON_STATUS.INVALID_CODE ? (<label style={{color: "red"}}><Translate contentKey="evaluateyouApp.coupon.status.invalidCode">Amount</Translate></label>) : (<span></span>)}
          {isValidCoupon === COUPON_STATUS.USED ? (<label style={{color: "red"}}><Translate contentKey="evaluateyouApp.coupon.status.used">Amount</Translate></label>) : (<span></span>)}
          {isValidCoupon === COUPON_STATUS.USAGE_LIMITED ? (<label style={{color: "red"}}><Translate contentKey="evaluateyouApp.coupon.status.usageLimited">Amount</Translate></label>) : (<span></span>)}
          <AvGroup>
            <Label id="creditLabel" for="credit-credit">
              <Translate contentKey="evaluateyouApp.credit.useCodeTitle">Amount</Translate>
            </Label>
            <AvField
              style={{width:350}}
              id="credit-credit"
              type="string"
              className="form-control"
              name="couponCode"
              validate={{
                required: { value: true, errorMessage: translate('entity.validation.required')}
              }}
              onChange={(event) => this.changeCode(event.target.value)}
            />
          </AvGroup>
          <Button id="save-entity" type="button" onClick={(event) => this.showCouponForm(false)}>
            &nbsp;
            <Translate contentKey="entity.action.cancel">Cancel</Translate>
          </Button>
          &nbsp;
          <Button color="primary" id="save-entity" type="button" onClick={(event) => this.submitCoupon()}>
            &nbsp;
            <Translate contentKey="entity.action.ok"></Translate>
          </Button>
        </Col>
      </Row>
    </div>);

    if(!isShowCouponForm){
      action = (<div></div>);
    }

    return action;
  }

  showCustomForm = (value) => {
    this.setState({isShowCustomForm: value});
  }

  showCouponForm = value =>{
    this.setState({isShowCouponForm: value});
  }

  hidePrompt(){
    if(this.state.showSuccess){
      this.setState({showSuccess:false});
      this.props.history.push('/credit');
    }
  }



  render() {
    const { creditEntity} = this.props;
    const { isNew, customAmount, paymentAmount, isShowCouponForm, showSuccess, addedCredit } = this.state;

    return (
        <div>



          <Modal isOpen={showSuccess} toggle={() => this.hidePrompt()} backdrop={true}>
            <ModalHeader toggle={() => this.hidePrompt()}>
              <Translate contentKey="evaluateyouApp.coupon.detail.title">Successfully applied Coupon</Translate>
            </ModalHeader>

            <ModalBody id="evaluateyouApp.coupon.success">
              <Translate contentKey="evaluateyouApp.coupon.success" interpolate={{ addedCredits: addedCredit}}>
              </Translate>
            </ModalBody>

            <ModalFooter>
              <Button className="btn-primary" id="jhi-confirm-delete-assessment" color="danger"
                      onClick={() => this.hidePrompt()}>
                <Translate contentKey="entity.action.ok">OK</Translate>
              </Button>
            </ModalFooter>
          </Modal>

          <div className="col-md-8" style={{display:'inline-block'}}>
          <b id="credit-heading" className="pull-left">
            <Translate contentKey="evaluateyouApp.credit.home.createOrEditLabel"></Translate>
          </b>
          <Card className="main-card">

            <Row>
              <Col md="12">
                <AvForm model={isNew ? {} : creditEntity} onSubmit={this.saveEntity}>
                  {isShowCouponForm ? (
                      this.renderCouponForm()
                  ) : (
                      <div style={{marginLeft: 40, marginTop: 20}}>
                        <div>
                          <div><Translate contentKey="evaluateyouApp.credit.messages.notice1" interpolate={{ pricePerCredit: this.props.pricePerCredit }}></Translate></div>
                          <Row  style={{marginLeft: 0, marginTop: 40, textAlign:'center', display:'flex', flexDirection:'row',justifyContent:'left', verticalAlign:'middle'}}>
                            <div className="pretty p-default p-round p-thick fontSize30" style={{width:50}}>
                              <input type="radio" name="color"
                                     onChange={(event) => this.changeAmount(1)}/>
                              <div className="state p-primary-o">
                                <label><b>1</b></label>
                              </div>
                            </div>
                            <div className="pretty p-default p-round p-thick fontSize30" style={{width:50}}>
                              <input type="radio" name="color"
                                     onChange={(event) => this.changeAmount(5)}/>
                              <div className="state p-primary-o">
                                <label><b>5</b></label>
                              </div>
                            </div>
                            <div className="pretty p-default p-round p-thick fontSize30" style={{width:50}}>
                              <input type="radio" name="color"
                                     onChange={(event) => this.changeAmount(10)}/>
                              <div className="state p-primary-o">
                                <label><b>10</b></label>
                              </div>
                            </div>
                            <div className="pretty p-default p-round p-thick fontSize30" style={{width:50}}>
                              <input type="radio" name="color"
                                     onChange={(event) => this.changeAmount(50)}/>
                              <div className="state p-primary-o">
                                <label><b>50</b></label>
                              </div>
                            </div>
                            <div className="pretty p-default p-round p-thick fontSize30"  style={{width:50}}>
                              <input type="radio" name="color"
                                     onChange={(event) => this.useCustomAmount(event, customAmount)}/>
                              <div className="state p-primary-o">
                                <label><b>Custom</b></label>
                              </div>

                            </div>
                            <div style={{width:200,textAlign:'left', marginLeft: 20, marginTop: -5}}>
                              <input type="number" min="1" name="customValue"
                                     onChange={(event) => this.changeCustomAmount(event.target.value)}
                                     style={{width: "100px"}}/>
                            </div>

                          </Row>
                        </div>


                        {this.renderCustomCreditForm()}
                        <div className="money" style={{marginTop:40,textAlign:'left',fontSize:20}}>Amount(USD):&nbsp;&nbsp;&nbsp;&nbsp;${paymentAmount}</div>

                        <div style={{textAlign:'left', marginTop: 40}}>
                          <Button color="secondary" tag={Link} id="cancel-save" to="/credit" replace
                                  type="button">
                            &nbsp;
                            <Translate contentKey="entity.action.back">Back</Translate>
                          </Button>&nbsp;
                          <Button color="primary" id="save-entity" type="button"
                                  onClick={(event) => this.addCredit()}>
                            <Translate
                                contentKey="evaluateyouApp.credit.actions.continueToCheckout"></Translate>
                          </Button>
                        </div>

                        <Row style={{marginLeft: 0,marginTop:10,textAlign:'left'}}>
                          <div style={{width:'100%'}}><a style={{color:'#3b86ff'}}onClick={(event)=>this.showCouponForm(true)}><Translate contentKey="evaluateyouApp.credit.useCode">Use Coupon</Translate></a></div>
                        </Row>
                      </div>
                  )}
                </AvForm>
              </Col>
            </Row>
          </Card>
        </div>

        </div>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  creditEntity: storeState.credit.entity,
  loading: storeState.credit.loading,
  updating: storeState.credit.updating,
  updateSuccess: storeState.credit.updateSuccess,
  pricePerCredit: storeState.credit.pricePerCredit
});

const mapDispatchToProps = {
  getEntity,
  updateEntity,
  getPricePerCredit,
  createEntity,
  reset
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreditUpdate);
