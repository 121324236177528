import React, {useEffect, useState} from 'react';
import {Translate, translate} from 'react-jhipster';
import {connect} from 'react-redux';
import {AvField, AvForm} from 'availity-reactstrap-validation';
import {Button, Col, Row} from 'reactstrap';
import "./password.css"
import {IRootState} from 'app/shared/reducers';
import {getSession} from 'app/shared/reducers/authentication';
import PasswordStrengthBar from 'app/shared/layout/password/password-strength-bar';
import {reset, savePassword} from './password.reducer';

export interface IUserPasswordProps extends StateProps, DispatchProps {}

export const PasswordPage = (props: IUserPasswordProps) => {
  const [password, setPassword] = useState('');

  useEffect(() => {
    props.reset();
    props.getSession();
    return () => props.reset();
  }, []);

  const handleValidSubmit = (event, values) => {
    props.savePassword(values.currentPassword, values.newPassword);
  };

  const updatePassword = event => setPassword(event.target.value);

  return (
    <div className="password-modal">
      <Row className="justify-content-center">
        <Col md="12">
          <div className="password-header">
            <h2 id="password-title" >
              <Translate contentKey="password.title" interpolate={{ username: props.account.login }}>
                Password for {props.account.login}
              </Translate>
            </h2>
          </div>
          <AvForm id="password-form" className="password-form" onValidSubmit={handleValidSubmit}>
            <AvField
              name="currentPassword"
              label={translate('global.form.currentpassword.label')}
              placeholder={translate('global.form.currentpassword.placeholder')}
              type="password"
              validate={{
                required: { value: true, errorMessage: translate('global.messages.validate.newpassword.required') }
              }}
            />
            <AvField
              name="newPassword"
              label={translate('global.form.newpassword.label')}
              placeholder={translate('global.form.newpassword.placeholder')}
              type="password"
              validate={{
                required: { value: true, errorMessage: translate('global.messages.validate.newpassword.required') },
                minLength: { value: 4, errorMessage: translate('global.messages.validate.newpassword.minlength') },
                maxLength: { value: 50, errorMessage: translate('global.messages.validate.newpassword.maxlength') }
              }}
              onChange={updatePassword}
            />
            <PasswordStrengthBar password={password} />
            <AvField
              name="confirmPassword"
              label={translate('global.form.confirmpassword.label')}
              placeholder={translate('global.form.confirmpassword.placeholder')}
              type="password"
              validate={{
                required: {
                  value: true,
                  errorMessage: translate('global.messages.validate.confirmpassword.required')
                },
                minLength: {
                  value: 4,
                  errorMessage: translate('global.messages.validate.confirmpassword.minlength')
                },
                maxLength: {
                  value: 50,
                  errorMessage: translate('global.messages.validate.confirmpassword.maxlength')
                },
                match: {
                  value: 'newPassword',
                  errorMessage: translate('global.messages.error.dontmatch')
                }
              }}
            />
            <Button color="success" type="submit">
              <Translate contentKey="password.form.button">Save</Translate>
            </Button>
          </AvForm>
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = ({ authentication }: IRootState) => ({
  account: authentication.account,
  isAuthenticated: authentication.isAuthenticated
});

const mapDispatchToProps = { getSession, savePassword, reset };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PasswordPage);
