import { IAudit } from 'app/shared/model/audit.model';

export interface IMyAuditQuestion {
  id?: string;
  title?: string;
  code?: string;
  type?: number;
  score?: number;
  order?: number;
  status?: number;
  audit?: IAudit;
}

export const defaultValue: Readonly<IMyAuditQuestion> = {};
