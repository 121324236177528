import '../assessment/assessment.scss';
import React from 'react';
import {connect} from 'react-redux';
import {RouteComponentProps} from 'react-router-dom';
import {
    Button,
    Card,
    Col,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row
} from 'reactstrap';
import {AvForm, AvGroup, AvInput} from 'availity-reactstrap-validation';
import {Translate} from 'react-jhipster';
import "@pathofdev/react-tag-input/build/index.css";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {IRootState} from 'app/shared/reducers';
import {Confirmation} from 'app/entities/assessment/steps/confirmation';
import {ProgressBar, ProgressBarOption} from 'app/entities/assessment/steps/progress-bar';
import {getUsers} from "app/modules/administration/user-management/user-management.reducer";

import {
    createEntity,
    getEntity,
    reset,
    updateEntity
} from 'app/entities/education/education.reducer';
import {IWorkExperience} from "app/shared/model/work-experience.model";
import {IEducation} from "app/shared/model/education.model";
import {IResume} from "app/shared/model/resume.model";
import {ResumeEducation} from "app/entities/work-experience/steps/resume-education";
import {ResumeWorkExperience} from "app/entities/work-experience/steps/resume-work-experience";
import {ResumeConfirmation} from "app/entities/work-experience/steps/resume-confirmation";


export interface IWorkExperienceUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export interface IResumeUpdateState{
  isNew: boolean;
  completedSteps: number[];
  showSuccessForm: boolean;
  currentStepOption: ProgressBarOption;
  model: IResume;
  idsUserResume: any[];

}

export class WorkExperienceUpdate extends React.Component<IWorkExperienceUpdateProps, IResumeUpdateState> {
  model: IResume;
  steps:ProgressBarOption[];

  constructor(props) {
    super(props);


    this.steps = [
      {
        step: 1,
        stepTitle: 'Education',
        renderStep: () => this.renderResumeEducation()
      },
      {
        step: 2,
        stepTitle: 'Work',
        renderStep: () => this.renderResumeWorkExperience()
      },
      {
        step: 3,
        stepTitle: 'Confirmation',
        renderStep: () => this.renderResumeConfirmation()
      }
    ];

    this.state = {
      model: {
        user: this.props.account,
        educations:[],
        workExperiences:[],
      },
      completedSteps:[],
      currentStepOption: this.steps[0], // the first one
      idsUserResume: [],
      showSuccessForm: false,

      isNew: !this.props.match.params || !this.props.match.params.id
    };
  }


  componentWillUpdate(nextProps) {
    if (nextProps.updateSuccess !== this.props.updateSuccess && nextProps.updateSuccess) {
      this.handleClose();
    }
  }

  getNextStep():ProgressBarOption{
    const nextStep = this.state.currentStepOption.step + 1;
    return this.steps[nextStep -1]; // index are zero based
  }

  getPreviousStep():ProgressBarOption{
    const previous = this.state.currentStepOption.step - 1;
    return this.steps[previous -1]; // index are zero based
  }

  showPrevStep = () =>{
    this.setState({currentStepOption: this.getPreviousStep()});
  }

  showNextStep = () =>{
    this.stepComplete(this.state.currentStepOption.step);
    this.setState({currentStepOption: this.getNextStep()});
  }

  saveEntity = () => {

    const model = this.state.model;
    const entity = {
      ...model
    }
    console.log(entity);

   this.props.createEntity(entity);

  };


  handleClose = () => {
    this.props.history.push('/dashboard');
  }



  hideDialog =() => {
    this.setState({showSuccessForm: false});
    this.handleClose();
  }


  stepComplete(step: number):void {
    const completedSteps = this.state.completedSteps;
    completedSteps.push(step);
    this.setState({completedSteps: completedSteps});
  }

  isStepCompleted(step:number):boolean{
    return this.state.completedSteps.includes(step);
  }

  renderSuccess(){
    const {showSuccessForm, } = this.state;
    return (<Modal isOpen={showSuccessForm} toggle={() => this.hideDialog()} backdrop={true}>
      <ModalHeader toggle={() => this.hideDialog()}>
        <Translate contentKey="evaluateyouApp.assessment.messages.assessment.added"></Translate>
      </ModalHeader>
      <ModalBody>
        <Row>
        </Row>
        <Row>
          <Col>
            <FontAwesomeIcon icon="hand-point-right"/>{' '}
            <Translate contentKey="evaluateyouApp.assessment.messages.assessment.info2"></Translate>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={() => this.hideDialog()}> <Translate contentKey="entity.action.ok"></Translate></Button>{' '}
      </ModalFooter>
    </Modal>);
  }

  addEducation(educations:IEducation[]){
    this.setState({model: {...this.state.model, educations: educations}},()=>{
      console.log('state updated');
      console.log(this.state);
    });
  }

  addWorkExperience(workExperience:IWorkExperience[]){
    console.log(workExperience)
    this.setState({model: {...this.state.model, workExperiences: [...workExperience]}},()=>{
      console.log('state updated');
      console.log(this.state);
    });
  }

  renderResumeEducation(){
    return (<ResumeEducation
      model={this.state.model}
      educationCallback={(educations) => {
        this.addEducation(educations);
      }   }
      previousStep={() => this.showPrevStep()}
      nextStep={() => this.showNextStep()}/>);
  }

  renderResumeWorkExperience() {
    return (<ResumeWorkExperience
      model={this.state.model}
      workExperienceCallback={(workExperience) => {
        this.addWorkExperience(workExperience);
      }   }
      previousStep={() => this.showPrevStep()}
      nextStep={() => this.showNextStep()}/>);
  }



  renderResumeConfirmation(){
    return (<ResumeConfirmation model={this.state.model}
                                  previousStep={()=>this.showPrevStep()}
                                  submit={()=>this.saveEntity()}/>);
  }

  renderCurrentStep(){
    return this.state.currentStepOption.renderStep();
  }

  render() {
    const { workExperienceEntity, educationEntity, loading } = this.props;
    const { isNew } = this.state;

    return (
      <Card className="main-card" style={{height:'100%'}}>
        {this.renderSuccess()}

        <Row>
          <ProgressBar currentStep={this.state.currentStepOption} isStepCompleted={(step)=>this.isStepCompleted(step)} stepOptions={this.steps}/>
        </Row>

        <Row className="justify-content-center">
          <Col md="10">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <AvForm model={educationEntity}>
                {!isNew ? (
                  <AvGroup>
                    <Label for="education-id">
                      <Translate contentKey="global.field.id">ID</Translate>
                    </Label>
                    <AvInput id="education-id" type="text" className="form-control" name="id" required readOnly />
                  </AvGroup>
                ) : null}
                {
                  this.renderCurrentStep()
                }
              </AvForm>
            )}
          </Col>
        </Row>
      </Card>
    );
  }



}

const mapStateToProps = (storeState: IRootState) => ({
  educationEntity: storeState.education.entity,
  workExperienceEntity: storeState.workExperience.entity,
  loading: storeState.assessment.loading,
  updating: storeState.assessment.updating,
  updateSuccess: storeState.assessment.updateSuccess,
  account: storeState.authentication.account,
});

const mapDispatchToProps = {
  getEntity,
  updateEntity,
  createEntity,
  reset,
  getUsers,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WorkExperienceUpdate);
