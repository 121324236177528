import React from 'react';
import { Switch } from 'react-router-dom';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import Audit from './audit';
import AuditQuestion from './audit-question';
import AuditQuestionOption from './audit-question-option';
import AuditTag from './audit-tag';
import QuestionFile from './question-file';
import Coupon from './coupon';
import Credit from './credit';
import CreditHistory from './credit-history';
import PaymentRequest from './payment-request';
import MyAuditQuestion from './my-audit-question';
import MyAuditQuestionOption from './my-audit-question-option';
import Assessment from './assessment';
import AssessmentAudit from './assessment-audit';
import AssessmentEmail from './assessment-email';
import ApplicantAssessment from './applicant-assessment';
import CandidateAssessmentExperience from './candidate-assessment-experience';
import AdminConfig from './admin-config';
import Education from './education';
import WorkExperience from './work-experience';
import Training from './training';
import Tag from './tag';
import ProfileRole from './profile-role';
import Faculty from './faculty';
import Course from './course';
import Major from './major';
import University from './university';
/* jhipster-needle-add-route-import - JHipster will add routes here */

const Routes = ({ match }) => (
  <div>
    <Switch>
      {/* prettier-ignore */}
      <ErrorBoundaryRoute path={`${match.url}audit`} component={Audit} />
      <ErrorBoundaryRoute path={`${match.url}audit-question`} component={AuditQuestion} />
      <ErrorBoundaryRoute path={`${match.url}audit-question-option`} component={AuditQuestionOption} />
      <ErrorBoundaryRoute path={`${match.url}audit-tag`} component={AuditTag} />
      <ErrorBoundaryRoute path={`${match.url}question-file`} component={QuestionFile} />
      <ErrorBoundaryRoute path={`${match.url}coupon`} component={Coupon} />
      <ErrorBoundaryRoute path={`${match.url}credit`} component={Credit} />
      <ErrorBoundaryRoute path={`${match.url}credit-history`} component={CreditHistory} />
      <ErrorBoundaryRoute path={`${match.url}payment-request`} component={PaymentRequest} />
      <ErrorBoundaryRoute path={`${match.url}my-audit-question`} component={MyAuditQuestion} />
      <ErrorBoundaryRoute path={`${match.url}my-audit-question-option`} component={MyAuditQuestionOption} />
      <ErrorBoundaryRoute path={`${match.url}assessment`} component={Assessment} />
      <ErrorBoundaryRoute path={`${match.url}assessment-audit`} component={AssessmentAudit} />
      <ErrorBoundaryRoute path={`${match.url}assessment-email`} component={AssessmentEmail} />
      <ErrorBoundaryRoute path={`${match.url}applicant-assessment`} component={ApplicantAssessment} />
      <ErrorBoundaryRoute path={`${match.url}candidate-assessment-experience`} component={CandidateAssessmentExperience} />
      <ErrorBoundaryRoute path={`${match.url}admin-config`} component={AdminConfig} />
      <ErrorBoundaryRoute path={`${match.url}education`} component={Education} />
      <ErrorBoundaryRoute path={`${match.url}work-experience`} component={WorkExperience} />
      <ErrorBoundaryRoute path={`${match.url}training`} component={Training} />
      <ErrorBoundaryRoute path={`${match.url}tag`} component={Tag} />
      <ErrorBoundaryRoute path={`${match.url}profile-role`} component={ProfileRole} />
      <ErrorBoundaryRoute path={`${match.url}faculty`} component={Faculty} />
      <ErrorBoundaryRoute path={`${match.url}course`} component={Course} />
      <ErrorBoundaryRoute path={`${match.url}major`} component={Major} />
      <ErrorBoundaryRoute path={`${match.url}university`} component={University} />
      {/* jhipster-needle-add-route-path - JHipster will add routes here */}
    </Switch>
  </div>
);

export default Routes;
