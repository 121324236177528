import { IEducation } from 'app/shared/model/education.model';
import { IWorkExperience } from 'app/shared/model/work-experience.model';
import { IUserSkill } from 'app/shared/model/user-skill.model';
import { IUserResume } from 'app/shared/model/user-resume.model';
import { IProfileRole } from 'app/shared/model/profile-role.model';
import { IFaculty } from 'app/shared/model/faculty.model';
import { ICourse } from 'app/shared/model/course.model';
import { IMajor } from 'app/shared/model/major.model';
import { IUniversity } from 'app/shared/model/university.model';

export interface IUser {
  id?: any;
  login?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  activated?: boolean;
  langKey?: string;
  authorities?: any[];
  createdBy?: string;
  createdDate?: Date;
  lastModifiedBy?: string;
  lastModifiedDate?: Date;
  password?: string;
  timeZone?: string;
  dateOfBirth?: Date;
  address?: string;
  phoneNumber?: string;
  education?: IEducation[];
  workExperience?: IWorkExperience[];
  imageUrl?: string;

  fullName?: string;
  highestEducation?: string;
  yearsOfExperience?: string;
  currentRole?: IProfileRole;
  targetRole?: IProfileRole;
  interest?: string;
  lookingFor?: string;
  agreeToMarketing?: boolean;

  skills?: IUserSkill[];
  resumes?: IUserResume[];

  university?: IUniversity;

  faculty?: IFaculty;
  course?: ICourse;
  major?: IMajor;
  lookingForInternship?: boolean;
  preferedWorkingState?: string;

}

export const defaultValue: Readonly<IUser> = {
  id: '',
  login: '',
  firstName: '',
  lastName: '',
  email: '',
  activated: false,
  langKey: '',
  authorities: [],
  createdBy: '',
  createdDate: null,
  lastModifiedBy: '',
  lastModifiedDate: null,
  password: '',
  timeZone: '',
  dateOfBirth: null,
  address: '',
  phoneNumber: ''
};
