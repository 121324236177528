import React from 'react';
import {Switch} from 'react-router-dom';
import Loadable from 'react-loadable';

import Login from 'app/modules/login/login';
import Register from 'app/modules/account/register/register';
import Activate from 'app/modules/account/activate/activate';
import PasswordResetInit from 'app/modules/account/password-reset/init/password-reset-init';
import PasswordResetFinish from 'app/modules/account/password-reset/finish/password-reset-finish';
import Logout from 'app/modules/login/logout';
import CandidateDashboard from 'app/modules/candidate-dashboard/candidate-dashboard';
import Entities from 'app/entities';
import PrivateRoute from 'app/shared/auth/private-route';
import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';
import PageNotFound from 'app/shared/error/page-not-found';
import {AUTHORITIES} from 'app/config/constants';
import Assessment from 'app/entities/assessment';
import PaymentRequest from 'app/entities/payment-request';
import Landing from 'app/modules/landing/landing';
import ChooseRole from 'app/modules/landing/choose-role';
import Coupon from 'app/entities/coupon/coupon';
import TopUp from 'app/entities/credit/top-up';
import AllAssessment from 'app/entities/assessment/all-assessment';
import Training from 'app/entities/training/training';
import Education from 'app/entities/education/education';
import WorkExperience from 'app/entities/work-experience/work-experience';
import TrainingList from 'app/modules/candidate-dashboard/training-list';
import WorkExperienceUpdate from 'app/entities/work-experience/work-experience-update';
import AssessmentList from 'app/modules/candidate-dashboard/assessment-list';
import ApplyTrainingDetail from 'app/entities/training/admin-training-view';
import AssessorDashboard from "app/modules/assessor-dashboard/assessor-dashboard";
import TraineesTable from "app/modules/assessor-dashboard/components/trainees-table";
import CandidateTable from "app/modules/assessor-dashboard/components/candidate-table";
import ProgressiveCandidateDashboard from 'app/modules/progressive-candidate-dashboard/progressive-candidate-dashboard';
import { RegisterSuccess } from './modules/account/register/register-success';


const Account = Loadable({
  loader: () => import(/* webpackChunkName: "account" */ 'app/modules/account'),
  loading: () => <div>loading ...</div>
});

const Admin = Loadable({
  loader: () => import(/* webpackChunkName: "administration" */ 'app/modules/administration'),
  loading: () => <div>loading ...</div>
});

const Routes = () => (
  <div className="view-routes mt-5">
    <Switch>
      <ErrorBoundaryRoute path="/login" component={Login} />
      <ErrorBoundaryRoute path="/logout" component={Logout} />
      <ErrorBoundaryRoute path="/account/register" component={Register} />
      <ErrorBoundaryRoute path="/account/register-success" component={RegisterSuccess} />
      <ErrorBoundaryRoute path="/account/activate/:key?" component={Activate} />
      <ErrorBoundaryRoute path="/account/reset/request" component={PasswordResetInit} />
      <ErrorBoundaryRoute path="/account/reset/finish/:key?" component={PasswordResetFinish} />
      <ErrorBoundaryRoute path="/" exact component={Landing} />
      <ErrorBoundaryRoute path="/choose-role" exact component={ChooseRole} />

      <PrivateRoute path="/admin" component={Admin} hasAnyAuthorities={[AUTHORITIES.ADMIN]} />
      <PrivateRoute
        path="/account"
        component={Account}
        hasAnyAuthorities={[AUTHORITIES.ADMIN, AUTHORITIES.USER, AUTHORITIES.HIRER, AUTHORITIES.APPLICANT]}
      />
      <PrivateRoute path="/assessment" exact component={Assessment} hasAnyAuthorities={[AUTHORITIES.HIRER, AUTHORITIES.ADMIN]} />
      <PrivateRoute path="/assessor-dashboard"exact component={AssessorDashboard} hasAnyAuthorities={[AUTHORITIES.HIRER, AUTHORITIES.ADMIN]} />
      <PrivateRoute path="/trainees-table"exact component={TraineesTable} hasAnyAuthorities={[AUTHORITIES.HIRER, AUTHORITIES.ADMIN]} />
      <PrivateRoute path="/candidates-table"exact component={CandidateTable} hasAnyAuthorities={[AUTHORITIES.HIRER, AUTHORITIES.ADMIN]}/>
      <PrivateRoute path="/training" exact component={Training} hasAnyAuthorities={[AUTHORITIES.HIRER, AUTHORITIES.ADMIN]} />
      <PrivateRoute path="/create-training" exact component={Training} hasAnyAuthorities={[AUTHORITIES.HIRER, AUTHORITIES.ADMIN]} />
      <PrivateRoute path="/allAssessment" exact component={AllAssessment} hasAnyAuthorities={[AUTHORITIES.ADMIN]} />
      <PrivateRoute path="/coupon" exact component={Coupon} hasAnyAuthorities={[AUTHORITIES.ADMIN]} />
      <PrivateRoute path="/credit/topUp" exact component={TopUp} hasAnyAuthorities={[AUTHORITIES.ADMIN]} />
      <PrivateRoute path="/payment-request" exact component={PaymentRequest} hasAnyAuthorities={[AUTHORITIES.ADMIN]} />
      <PrivateRoute path="/dashboard" exact component={ProgressiveCandidateDashboard} hasAnyAuthorities={[AUTHORITIES.ADMIN, AUTHORITIES.APPLICANT]} />
      <PrivateRoute path="/old-dashboard" exact component={CandidateDashboard} hasAnyAuthorities={[AUTHORITIES.ADMIN, AUTHORITIES.APPLICANT]} />
      <PrivateRoute path="/education" exact component={Education} hasAnyAuthorities={[AUTHORITIES.ADMIN, AUTHORITIES.APPLICANT]} />
      <PrivateRoute
        path="/work-experience-update"
        exact
        component={WorkExperienceUpdate}
        hasAnyAuthorities={[AUTHORITIES.ADMIN, AUTHORITIES.APPLICANT]}
      />
      <PrivateRoute
        path="/work-experience"
        exact
        component={WorkExperience}
        hasAnyAuthorities={[AUTHORITIES.ADMIN, AUTHORITIES.APPLICANT]}
      />
      <PrivateRoute path="/training-list" exact component={TrainingList} hasAnyAuthorities={[AUTHORITIES.ADMIN, AUTHORITIES.APPLICANT]} />
      <PrivateRoute
        path="/apply-training"
        exact
        component={ApplyTrainingDetail}
        hasAnyAuthorities={[AUTHORITIES.ADMIN, AUTHORITIES.APPLICANT]}
      />
      <PrivateRoute
        path="/assessment-list"
        exact
        component={AssessmentList}
        hasAnyAuthorities={[AUTHORITIES.ADMIN, AUTHORITIES.HIRER, AUTHORITIES.APPLICANT]}
      />
      <PrivateRoute
        path="/"
        component={Entities}
        hasAnyAuthorities={[AUTHORITIES.USER, AUTHORITIES.HIRER, AUTHORITIES.APPLICANT, AUTHORITIES.ADMIN]}
      />

      <ErrorBoundaryRoute component={PageNotFound} />
    </Switch>
  </div>
);

export default Routes;
