import { IAuditQuestion } from 'app/shared/model/audit-question.model';

export interface IAuditQuestionOption {
  id?: string;
  title?: string;
  score?: number;
  order?: number;
  isHighlight?: boolean;
  highlight?: boolean;
  auditQuestion?: IAuditQuestion;
}

export const defaultValue: Readonly<IAuditQuestionOption> = {
  isHighlight: false,
  highlight: false,
  title: '',
  score: -1,
  order: 0
};
