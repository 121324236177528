import React from 'react';
import {connect} from 'react-redux';
import {Link, RouteComponentProps} from 'react-router-dom';
import {
    Button,
    Card,
    Col,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row
} from 'reactstrap';
import {AvFeedback, AvField, AvForm, AvGroup, AvInput} from 'availity-reactstrap-validation';
import {Translate, translate} from 'react-jhipster';
import {IRootState} from 'app/shared/reducers';

import {createEntity, getEntity, getPricePerCredit, reset, updateEntity} from './credit.reducer';
import CreditService from 'app/shared/service/credit.service';
import CouponService from 'app/shared/service/coupon.service';
import UserService from 'app/shared/service/user.service';
import {IUser} from 'app/shared/model/user.model';
import {defaultValue, ITopUp} from 'app/shared/model/top-up.model';

export interface ITopUpProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export interface ITopUpState {
  isNew: boolean;
  amountSelect:number;
  amount: number;
  paymentAmount: number;
  isShowCustomForm: boolean;
  showSuccessForm: boolean;
  users: IUser[];
  model: ITopUp;
}

export class TopUp extends React.Component<ITopUpProps, ITopUpState> {
  creditService: CreditService;
  couponService: CouponService;
  userService: UserService;
  constructor(props) {
    super(props);
    this.state = {
      isNew: !this.props.match.params || !this.props.match.params.id,
      amountSelect: 0,
      amount: 0,
      paymentAmount: 0,
      isShowCustomForm: false,
      showSuccessForm: false,
      users:[],
      model: defaultValue
    };
    this.creditService = new CreditService();
    this.couponService = new CouponService();
    this.userService = new UserService();
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextProps.updateSuccess !== this.props.updateSuccess && nextProps.updateSuccess) {
      this.handleClose();
    }
  }

  componentDidMount() {
    this.props.getPricePerCredit();
    this.userService.getAll().then( response =>{
      this.setState({users: response});
    });
  }

  saveEntity = (event, errors, values) => {
    if (errors.length === 0) {
      const { creditEntity } = this.props;
      const entity = {
        ...creditEntity,
        ...values
      };

      if (this.state.isNew) {
        this.props.createEntity(entity);
      } else {
        this.props.updateEntity(entity);
      }
    }
  };
  addCredit = () =>{
    if(this.state.amount > 0){
      const obj = Object.assign({}, this.state.model);
      obj.amount = this.state.amount;
      this.creditService.topUp(obj).then( response =>{
        console.log(response);
        this.setState({showSuccessForm: true});
      });
    }
  }

  setModelValue = (field, event) => {
    const {model} = this.state;
    model[field] = event.target.value;

    this.setState({model: model});
  }

  handleClose = () => {
    this.props.history.push('/credit/topUp');
  };
  changeAmount = (amount) => {
      const amountSelect = amount;
      let paymentAmount = amount;
      if(amount <= 0){
        amount = 0;
        paymentAmount = 0;
      }else{
        paymentAmount = parseFloat(amount * this.props.pricePerCredit + "").toFixed(2);
      }
      this.setState({amount: amount, amountSelect: amountSelect, paymentAmount: paymentAmount});

  }
  displayAmount = (amount) => {
    return parseFloat(amount).toFixed(2);
  }

  renderCustomCreditForm(){
    const { isShowCustomForm } = this.state;

    let action = (<div>
      <Row className="justify-content-center">
        <Col>
          <AvGroup>
            <Label id="creditLabel" for="credit-credit">
              <Translate contentKey="evaluateyouApp.credit.amount">Amount</Translate>
            </Label>
            <AvField
              id="credit-credit"
              type="number"
              className="form-control"
              name="amount"
              validate={{
                required: { value: true, errorMessage: translate('entity.validation.required') },
                number: { value: true, errorMessage: translate('entity.validation.number') }
              }}
              onChange={(event) => this.changeAmount(event.target.value)}
              min="0"
            />
        </AvGroup>
        </Col>
      </Row>
    </div>);

    if(!this.state.isShowCustomForm){
      action = (<div></div>);
    }

    return action;
  }

  showCustomForm = (value) => {
    this.setState({isShowCustomForm: value});
  }

  hideDialog =() =>{
    this.setState({showSuccessForm: false});
  }
  renderSuccess(){
    const {showSuccessForm, model} = this.state;
    return (<Modal isOpen={showSuccessForm} toggle={() => this.hideDialog()} backdrop={true}>
      <ModalHeader toggle={() => this.hideDialog()}>Message</ModalHeader>
      <ModalBody>
        <Translate contentKey="evaluateyouApp.credit.messages.topUpToUser" interpolate={{user:model.createdBy}}>Use Coupon</Translate>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={() => this.hideDialog()}> <Translate contentKey="entity.action.ok"></Translate></Button>{' '}
      </ModalFooter>
    </Modal>);
  }
  render() {
    const { creditEntity, loading, updating } = this.props;
    const { isNew, amountSelect, isShowCustomForm, users, showSuccessForm } = this.state;

    return (
      <Card className="main-card">
        <Row className="justify-content-center">
          <Col md="8">
            <h2 id="evaluateyouApp.credit.home.createOrEditLabel">
              <Translate contentKey="evaluateyouApp.credit.topUp"></Translate>
            </h2>
          </Col>
        </Row>
        {showSuccessForm ? this.renderSuccess() : ""}
        <Row className="justify-content-center">
          <Col md="8">
            <AvForm model={isNew ? {} : creditEntity} onSubmit={this.saveEntity}>
              <div>
                <Row style={{marginBottom: "20px", marginTop: "20px"}}>
                  <button type="button" className={"btn btn-circle btn-lg " + (amountSelect === 1 ? 'btn-primary': '')} onClick={event => {this.showCustomForm(false); this.changeAmount(1);}}>1</button>&nbsp;&nbsp;
                  <button type="button" className={"btn btn-circle btn-lg " + (amountSelect === 5 ? 'btn-primary': '')} onClick={event => {this.showCustomForm(false);this.changeAmount(5)}}>5</button>&nbsp;&nbsp;
                  <button type="button" className={"btn btn-circle btn-lg " + (amountSelect === 10 ? 'btn-primary': '')} onClick={event => {this.showCustomForm(false);this.changeAmount(10)}}>10</button>&nbsp;&nbsp;
                  <button type="button" className={"btn btn-circle btn-lg " + (amountSelect === 50 ? 'btn-primary': '')} onClick={event => {this.showCustomForm(false);this.changeAmount(50)}}>50</button>&nbsp;&nbsp;
                  <button type="button" className={"btn btn-circle btn-lg " + (isShowCustomForm ? 'btn-primary': '')} onClick={() => {this.showCustomForm(true);this.changeAmount(0)}} style={{width: "100px"}}>Custom</button>&nbsp;&nbsp;
                </Row>
                {this.renderCustomCreditForm()}

                <AvGroup>
                  <Label for="users">
                    <Translate contentKey="evaluateyouApp.credit.user"></Translate>
                  </Label>
                  <AvInput
                    id="users"
                    type="select"
                    className="form-control"
                    name="createdBy" value={users && users.length !== 0 ? users[0].login: ""}
                    required
                    onChange={(event) => this.setModelValue("createdBy", event)}
                  >
                    {users
                      ? users.map(user => (
                        <option value={user.login} key={user.id}>
                          {user.login}
                        </option>
                      ))
                      : null}
                  </AvInput>
                  <AvFeedback>
                    <Translate contentKey="entity.validation.required">This field is required.</Translate>
                  </AvFeedback>
                </AvGroup>

                <AvGroup>
                  <Label id="creditLabel" for="credit-note">
                    <Translate contentKey="evaluateyouApp.credit.note"></Translate>
                  </Label>
                  <AvField
                    id="credit-note"
                    type="textarea"
                    className="form-control"
                    name="note"
                    validate={{
                      required: { value: true, errorMessage: translate('entity.validation.required') }
                    }}
                    onChange={(event) => this.setModelValue("note", event)}
                  />
                </AvGroup>

                <Button color="primary" id="save-entity" type="button" onClick={(event) => this.addCredit()}>
                  &nbsp;
                  <Translate contentKey="evaluateyouApp.credit.actions.topUp"></Translate>
                </Button>
                &nbsp;
                <Button tag={Link} id="cancel-save" to="/credit" replace color="default" type="button">
                  &nbsp;
                  <Translate contentKey="entity.action.cancel">Save</Translate>
                </Button>
              </div>
            </AvForm>
          </Col>
        </Row>
      </Card>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  creditEntity: storeState.credit.entity,
  loading: storeState.credit.loading,
  updating: storeState.credit.updating,
  updateSuccess: storeState.credit.updateSuccess,
  pricePerCredit: storeState.credit.pricePerCredit
});

const mapDispatchToProps = {
  getEntity,
  updateEntity,
  createEntity,
  getPricePerCredit,
  reset
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TopUp);
