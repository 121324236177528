import axios from 'axios';
import { ICrudDeleteAction, ICrudGetAction, ICrudGetAllAction, ICrudPutAction } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util';

import { defaultValue, IApplicantAssessment, IApplicantAssessmentDTO } from 'app/shared/model/applicant-assessment.model';

export const ACTION_TYPES = {
  FETCH_APPLICANTASSESSMENT_LIST: 'applicantAssessment/FETCH_APPLICANTASSESSMENT_LIST',
  FETCH_APPLICANTASSESSMENT: 'applicantAssessment/FETCH_APPLICANTASSESSMENT',
  CREATE_APPLICANTASSESSMENT: 'applicantAssessment/CREATE_APPLICANTASSESSMENT',
  UPDATE_APPLICANTASSESSMENT: 'applicantAssessment/UPDATE_APPLICANTASSESSMENT',
  DELETE_APPLICANTASSESSMENT: 'applicantAssessment/DELETE_APPLICANTASSESSMENT',
  SET_BLOB: 'applicantAssessment/SET_BLOB',
  SET_UNSELECTED_QUESTIONS: 'applicantAssessment/SET_UNSELECTED_QUESTIONS',
  RESET: 'applicantAssessment/RESET',
  FETCH_COMPLETED_ASSESSMENTS: 'applicantAssessment/FETCH_COMPLETED_ASSESSMENTS'
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IApplicantAssessment>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
  unselectedQuestions: [],
  completedAssessments: [] as ReadonlyArray<IApplicantAssessmentDTO>
};

export type ApplicantAssessmentState = Readonly<typeof initialState>;

// Reducer

export default (state: ApplicantAssessmentState = initialState, action): ApplicantAssessmentState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_APPLICANTASSESSMENT_LIST):
    case REQUEST(ACTION_TYPES.FETCH_COMPLETED_ASSESSMENTS):
    case REQUEST(ACTION_TYPES.FETCH_APPLICANTASSESSMENT):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true
      };
    case REQUEST(ACTION_TYPES.CREATE_APPLICANTASSESSMENT):
    case REQUEST(ACTION_TYPES.UPDATE_APPLICANTASSESSMENT):
    case REQUEST(ACTION_TYPES.DELETE_APPLICANTASSESSMENT):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true
      };
    case FAILURE(ACTION_TYPES.FETCH_APPLICANTASSESSMENT_LIST):
    case FAILURE(ACTION_TYPES.FETCH_COMPLETED_ASSESSMENTS):
    case FAILURE(ACTION_TYPES.FETCH_APPLICANTASSESSMENT):
    case FAILURE(ACTION_TYPES.CREATE_APPLICANTASSESSMENT):
    case FAILURE(ACTION_TYPES.UPDATE_APPLICANTASSESSMENT):
    case FAILURE(ACTION_TYPES.DELETE_APPLICANTASSESSMENT):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload
      };
    case SUCCESS(ACTION_TYPES.FETCH_APPLICANTASSESSMENT_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10)
      };
    case SUCCESS(ACTION_TYPES.FETCH_APPLICANTASSESSMENT):
      return {
        ...state,
        loading: false,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.FETCH_COMPLETED_ASSESSMENTS):
      return {
        ...state,
        loading: false,
        completedAssessments: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.CREATE_APPLICANTASSESSMENT):
    case SUCCESS(ACTION_TYPES.UPDATE_APPLICANTASSESSMENT):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.DELETE_APPLICANTASSESSMENT):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {}
      };
    case ACTION_TYPES.SET_BLOB: {
      const { name, data, contentType } = action.payload;
      return {
        ...state,
        entity: {
          ...state.entity,
          [name]: data,
          [name + 'ContentType']: contentType
        }
      };
    }
    case ACTION_TYPES.SET_UNSELECTED_QUESTIONS: {
      const { unselectedQuestions } = action.payload;
      return {
        ...state,
        unselectedQuestions: unselectedQuestions
      };
    }
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

const apiUrl = 'api/applicant-assessments';

// Actions

export const getEntities: ICrudGetAllAction<IApplicantAssessment> = (page, size, sort) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_APPLICANTASSESSMENT_LIST,
    payload: axios.get<IApplicantAssessment>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`)
  };
};

export const getCompletedAssessmentsByCurrentLogin = () => {
  const requestUrl = `${apiUrl}/by-current-login`;
  return {
    type: ACTION_TYPES.FETCH_APPLICANTASSESSMENT_LIST,
    payload: axios.get<IApplicantAssessment>(`${requestUrl}`)
  };
};

export const getEntity: ICrudGetAction<IApplicantAssessment> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_APPLICANTASSESSMENT,
    payload: axios.get<IApplicantAssessment>(requestUrl)
  };
};

export const createEntity: ICrudPutAction<IApplicantAssessment> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_APPLICANTASSESSMENT,
    payload: axios.post(apiUrl, cleanEntity(entity))
  });
  dispatch(getEntities());
  return result;
};

export const updateEntity: ICrudPutAction<IApplicantAssessment> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_APPLICANTASSESSMENT,
    payload: axios.put(apiUrl, cleanEntity(entity))
  });
  dispatch(getEntities());
  return result;
};

export const deleteEntity: ICrudDeleteAction<IApplicantAssessment> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_APPLICANTASSESSMENT,
    payload: axios.delete(requestUrl)
  });
  dispatch(getEntities());
  return result;
};

export const setBlob = (name, data, contentType?) => ({
  type: ACTION_TYPES.SET_BLOB,
  payload: {
    name,
    data,
    contentType
  }
});

export const setUnselectedQuestions = unselectedQuestions => ({
  type: ACTION_TYPES.SET_UNSELECTED_QUESTIONS,
  payload: {
    unselectedQuestions
  }
});

export const reset = () => ({
  type: ACTION_TYPES.RESET
});
