import { ITrainingSession } from 'app/shared/model/training-session.model';

export interface ITrainingDate {
  id?: number;
  startDateTime?: Date;
  endDateTime?: Date;
  duration?: string;

  trainingSession?: ITrainingSession;
}

export const defaultValue: Readonly<ITrainingDate> = {};
