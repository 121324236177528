import { IMyAuditQuestion } from 'app/shared/model/my-audit-question.model';

export interface IMyAuditQuestionOption {
  id?: string;
  title?: string;
  score?: number;
  order?: number;
  myAuditQuestion?: IMyAuditQuestion;
}

export const defaultValue: Readonly<IMyAuditQuestionOption> = {};
