import axios from 'axios';
import { cleanEntity } from 'app/shared/util/entity-utils';
import { IApplicantAssessment } from 'app/shared/model/applicant-assessment.model';

const apiUrl = 'api/assessments';

export class AssessmentService {
  constructor() {}

  async getEntity(id) {
    const requestUrl = `${apiUrl}/${id}`;
    return axios
      .get(requestUrl)
      .then(response => {
        return response;
      })
      .then(body => {
        return body.data;
      })
      .catch(error => {
        alert(error);
      });
  }
  async createEntity(entity) {
    return axios
      .post(apiUrl, cleanEntity(entity))
      .then(response => {
        return response;
      })
      .then(body => {
        return body.data;
      })
      .catch(error => {
        alert(error);
      });
  }
  async sendInviteEmail(entity) {
    return axios
      .post(`${apiUrl}/sendInvite`, cleanEntity(entity))
      .then(response => {
        return response;
      })
      .then(body => {
        return body.data;
      })
      .catch(error => {
        alert(error);
      });
  }
  async loadByCreated(page, size, sort) {
    const requestUrl = `${apiUrl}/dto/byCreator${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
    return axios
      .get<IApplicantAssessment>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`)
      .then(response => {
        return response;
      })
      .then(body => {
        return body;
      })
      .catch(error => {
        alert(error);
      });
  }

  async loadAll(page, size, sort) {
    const requestUrl = `${apiUrl}/dto/loadAll${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
    return axios
      .get<IApplicantAssessment>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`)
      .then(response => {
        return response;
      })
      .then(body => {
        return body;
      })
      .catch(error => {
        alert(error);
      });
  }
  async updateShowAnswers(entity) {
    return axios
      .post(`${apiUrl}/updateShowAnswers`, cleanEntity(entity))
      .then(response => {
        return response;
      })
      .then(body => {
        return body.data;
      })
      .catch(error => {
        alert(error);
      });
  }

  async deleteEntities(ids) {
    return axios
      .delete(`${apiUrl}/delete/${ids}`)
      .then(response => {
        return response;
      })
      .then(body => {
        return body.data;
      })
      .catch(error => {
        alert(error);
      });
  }
}

export default AssessmentService;
