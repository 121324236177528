import axios from 'axios';
import { cleanEntity } from 'app/shared/util/entity-utils';

const apiUrl = 'api/audit-questions';

export class AuditQuestionService {
  constructor() {}

  async getEntitiesByAudit(page, size, sort, auditId) {
    const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}&auditId.contains=${auditId}`;
    return axios
      .get(requestUrl)
      .then(response => {
        return response;
      })
      .then(body => {
        const items = [];
        return body;
      })
      .catch(error => {
        alert(error);
      });
  }

  async getEntity(id) {
    const requestUrl = `${apiUrl}/${id}`;
    return axios
      .get(requestUrl)
      .then(response => {
        return response;
      })
      .then(body => {
        return body.data;
      })
      .catch(error => {
        alert(error);
      });
  }

  createEntity(entity) {
    return axios
      .post(apiUrl, cleanEntity(entity))
      .then(response => {
        return response;
      })
      .then(body => {
        return body.data;
      })
      .catch(error => {
        alert(error);
      });
  }

  updateEntity(entity) {
    return axios
      .put(apiUrl, cleanEntity(entity))
      .then(response => {
        return response;
      })
      .then(body => {
        return body.data;
      })
      .catch(error => {
        alert(error);
      });
  }

  async updateEnabled(entity) {
    return axios
      .post(`${apiUrl}/updateEnabled`, cleanEntity(entity))
      .then(response => {
        return response;
      })
      .then(body => {
        return body.data;
      })
      .catch(error => {
        alert(error);
      });
  }

  async deleteEntity(id) {
    return axios
      .delete(`${apiUrl}/${id}`)
      .then(response => {
        return response;
      })
      .then(body => {
        return body;
      })
      .catch(error => {
        alert(error);
      });
  }
}

export default AuditQuestionService;
