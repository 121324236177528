import './assessor-dashboard.scss';
import 'react-circular-progressbar/dist/styles.css';

import React from 'react';
import {RouteComponentProps} from 'react-router-dom';
import {getSortState, IPaginationBaseState} from 'react-jhipster';
import {connect} from 'react-redux';
import {Col, Row} from 'reactstrap';
import {ITEMS_PER_PAGE} from "app/shared/util/pagination.constants";
import {getThisUserTrainings} from 'app/entities/training/training.reducer';
import {IUserTraining} from 'app/shared/model/user-training.model';
import {getAdminAssessmentEmails} from 'app/entities/assessment-email/assessment-email.reducer';
import {TrainingTraineeInPastWeek} from "app/modules/assessor-dashboard/components/training-trainee-in-past-week";
import {AssessmentCandidateInPastWeek} from "app/modules/assessor-dashboard/components/assessment-candidate-in-past-week";
import {IAssessmentEmail} from "app/shared/model/assessment-email.model";
import moment from 'moment/moment.js';



export interface AssessorDashboardProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {
}

export interface AssessorDashboardState extends IPaginationBaseState {
  assessmentCandidates: IAssessmentEmail[];
  userTrainings: IUserTraining[];
}


export class AssessorDashboard extends React.Component<AssessorDashboardProps, AssessorDashboardState> {
  constructor(props) {
    super(props);

    this.state = {
      ...getSortState(this.props.location, ITEMS_PER_PAGE),
      assessmentCandidates: this.props.assessmentCandidates,
      userTrainings: this.props.userTrainings
    };

  }

  componentDidMount() {
    this.props.getThisUserTrainings();
    this.props.getAdminAssessmentEmails();
  }

  componentWillReceiveProps(updatedProps) {
    console.log('receiving updated props');
    if(updatedProps.userTrainings){
      console.log('user trainings updated');
      console.log(updatedProps.userTrainings);
      this.setState({userTrainings: updatedProps.userTrainings});
    }

    if(updatedProps.assessmentCandidates){
      console.log('assessmentCandidates updated');
      console.log(updatedProps.assessmentCandidates);
      this.setState({assessmentCandidates: updatedProps.assessmentCandidates});
    }
  }

  render() {
    return (
      <div>
        <Row className="mb-5">
          <Col className="align-self-stretch my-0" sm={4}
               style={{textAlign: 'left', alignSelf: 'stretch', display: 'flex'}}>
            <TrainingTraineeInPastWeek userTrainings={this.state.userTrainings}/>
          </Col>

          <Col className="align-self-stretch my-0" sm={4}
               style={{textAlign: 'left', alignSelf: 'stretch', display: 'flex'}}>
            <AssessmentCandidateInPastWeek assessmentCandidates={this.state.assessmentCandidates}/>
          </Col>
        </Row>
      </div>
    );
  }


}

const mapStateToProps = storeState => ({
  isAuthenticated: storeState.authentication.isAuthenticated,
  profileCompletion: storeState.settings.profileCompletion,
  userTrainings: storeState.training.userTrainings,
  assessmentCandidates: storeState.assessmentEmail.entities,
});

const mapDispatchToProps = {getThisUserTrainings,getAdminAssessmentEmails};


type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(AssessorDashboard);
