import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import ProfileRole from './profile-role';
import ProfileRoleDetail from './profile-role-detail';
import ProfileRoleUpdate from './profile-role-update';
import ProfileRoleDeleteDialog from './profile-role-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={ProfileRoleUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={ProfileRoleUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={ProfileRoleDetail} />
      <ErrorBoundaryRoute path={match.url} component={ProfileRole} />
    </Switch>
    <ErrorBoundaryRoute path={`${match.url}/:id/delete`} component={ProfileRoleDeleteDialog} />
  </>
);

export default Routes;
