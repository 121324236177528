import React, {useEffect, useState} from 'react';
import {Card,Button} from '@material-ui/core';
import {Row, Col} from 'reactstrap';
import {IUserTraining} from 'app/shared/model/user-training.model';
import moment from 'moment/moment.js';
import {Link} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Translate} from 'react-jhipster';



export const TrainingTraineeInPastWeek = (props:{userTrainings:IUserTraining[]}) =>{

  const {userTrainings} = props;
  const [recentUserEnroll, setRecentUserEnroll] = useState<number>(0);



  useEffect(()=>{
    if(userTrainings && userTrainings.length > 0){
      const today = new Date();
      const lastWeek = new Date(moment().subtract(7, "days").format("YYYY-MM-DD "));
      const uniqueTrainingsIds = userTrainings.filter(ut => new Date(ut.createdDate) >= lastWeek).map(ut=>ut.user.id).filter((ut, i, a) => a.indexOf(ut) === i);

      let userEnrollCount = 0;
      uniqueTrainingsIds.forEach(t => {
        const trainingDates = userTrainings.filter(ut => ut.user.id === t).filter(ut => new Date(ut.createdDate) >= lastWeek);
        const recentTrainingDates = trainingDates.filter(ut => new Date(ut.createdDate) >= lastWeek && new Date(ut.createdDate) <= today);
        if (recentTrainingDates.length === trainingDates.length) {
          userEnrollCount += 1;
        }
      })
      setRecentUserEnroll(userEnrollCount);
    }

  },[userTrainings])



  return (
    <Card className="w-100">
      <Row className="py-4">

        <Col xs={10}>
          <Row><b>Recent Training Trainees</b></Row>
          <Row className={'displayNumber'}><b>{recentUserEnroll}</b></Row>
          <Row style={{marginTop: 20}}>
            <Button href={("/trainees-table")}>
              View
            </Button>
          </Row>
        </Col>
      </Row>
    </Card>
  );

}
