import axios from 'axios';
import { cleanEntity } from 'app/shared/util/entity-utils';

const apiUrl = 'api/credits';

export class CreditService {
  constructor() {}

  async getEntity(id) {
    const requestUrl = `${apiUrl}/${id}`;
    return axios
      .get(requestUrl)
      .then(response => {
        return response;
      })
      .then(body => {
        return body.data;
      })
      .catch(error => {
        alert(error);
      });
  }
  async createEntity(entity) {
    return axios
      .post(apiUrl, cleanEntity(entity))
      .then(response => {
        return response;
      })
      .then(body => {
        return body.data;
      })
      .catch(error => {
        alert(error);
      });
  }

  async getCredit() {
    const requestUrl = `${apiUrl}/get`;
    return axios
      .get(requestUrl)
      .then(response => {
        return response;
      })
      .then(body => {
        return body.data;
      })
      .catch(error => {
        alert(error);
      });
  }
  async getPayments(page, size, sort) {
    const requestUrl = `${apiUrl}/paymentHistory${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
    return axios
      .get(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`)
      .then(response => {
        return response;
      })
      .then(body => {
        return body;
      })
      .catch(error => {
        alert(error);
      });
  }

  async payment(amount) {
    return axios
      .get(`${apiUrl}/paypal/payment?amount=` + amount)
      .then(response => {
        return response;
      })
      .then(body => {
        return body.data;
      })
      .catch(error => {
        alert(error);
      });
  }

  async completePayment(entity) {
    return axios
      .post(`${apiUrl}/paypal/payment/complete`, cleanEntity(entity))
      .then(response => {
        return response;
      })
      .then(body => {
        return body.data;
      })
      .catch(error => {
        alert(error);
      });
  }

  async topUp(entity) {
    return axios
      .post(`${apiUrl}/topUpByAdmin`, cleanEntity(entity))
      .then(response => {
        return response;
      })
      .then(body => {
        return body.data;
      })
      .catch(error => {
        alert(error);
      });
  }
}

export default CreditService;
