import React from 'react';
import {connect} from 'react-redux';
import {Link, RouteComponentProps} from 'react-router-dom';
import {Button, Row, Table, Col} from 'reactstrap';
import {
    getSortState,
    IPaginationBaseState,
    JhiItemCount,
    JhiPagination,
    Translate
} from 'react-jhipster';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {IRootState} from 'app/shared/reducers';
import {getEntities} from './audit.reducer';
import {IAudit} from 'app/shared/model/audit.model';
import {ITEMS_PER_PAGE} from 'app/shared/util/pagination.constants';
import AuditService from "app/shared/service/audit.service";
import {IAuditQuestion} from "app/shared/model/audit-question.model";
import AuditQuestionService from "app/shared/service/audit-question.service";
import { Card } from '@material-ui/core';

export interface IAuditProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {
}

export interface IAuditState extends IPaginationBaseState {
  models: IAudit[];
  questions: IAuditQuestion[];
  totalItems: number;
  questionNumber: string[];
  totalEnabled: number[];
  totalDisabled: number[];
}

export class Audit extends React.Component<IAuditProps, IAuditState> {
  auditService: AuditService;
  auditQuestionService: AuditQuestionService;

  constructor(props) {
    super(props);
    this.state = {
      ...getSortState(this.props.location, ITEMS_PER_PAGE),
      models: [],
      questions: [],
      totalItems: 0,
      questionNumber: [],
      totalEnabled: [],
      totalDisabled: [],
      sort: 'id'
    };
    this.auditService = new AuditService();
  }

  componentDidMount() {
    console.log('component mounting');
    this.getEntities();
    this.auditQuestionService = new AuditQuestionService();
  }

  componentDidUpdate(nextProps, prevState) {
    if (nextProps.deleteSuccess) {
      this.getEntities();
    }
  }

  sort = prop => () => {
    this.setState(
      {
        order: this.state.order === 'asc' ? 'desc' : 'asc',
        sort: prop
      },
      () => this.sortEntities()
    );
  };

  sortEntities() {
    this.getSortEntities();
    this.props.history.push(`${this.props.location.pathname}?page=${this.state.activePage}&sort=${this.state.sort},${this.state.order}`);
  }

  handlePagination = activePage => this.setState({activePage}, () => this.sortEntities());

  getSortEntities = () => {
    const { activePage, itemsPerPage, sort, order } = this.state;
    this.auditService.getDTOs(activePage - 1, itemsPerPage, `${sort},${order}`).then(response => {
      console.log(response);
      const totalItems = parseInt(response["headers"]['x-total-count'], 10);
      const models = response["data"];
      this.setState({totalItems: totalItems, models: models});
    });
  };

  getEntities = () => {
    const {activePage, itemsPerPage, sort, order} = this.state;
    this.auditService.getDTOs(activePage - 1, itemsPerPage, `${sort},${order}`).then(response => {
      console.log(response);
      const totalItems = parseInt(response["headers"]['x-total-count'], 10);
      const models = response["data"];
      this.setState({totalItems: totalItems, models: models});

      this.state.models.forEach(a => {
        let enabled = 0;
        let disabled = 0;
        this.auditQuestionService.getEntitiesByAudit(activePage - 1, itemsPerPage, `${sort},${order}`, a.id).then(body => {
          this.state.questionNumber.push(a.id);
          this.setState({questions: body['data']});
          this.state.questions.map(c => {
            if (c.enabled === true)
              enabled++;
            else if (c.enabled === false)
              disabled++;
          })
          this.state.totalEnabled.push(enabled);
          this.state.totalDisabled.push(disabled);
        });
      })
    });
  };

  findQuestionNumberForEnabled: any = (num: string) => {
    let value = 0;
    this.state.questionNumber.map((question, i) => {
      if (question === num)
        value = i;
    })
    return value;
  }

  render() {
    const {match} = this.props;
    const {models, totalItems, totalDisabled, totalEnabled,} = this.state;
    return (
      <Row className={'justify-content-center w-100'}>
        <Col xs={12} md={11}>
          <Card className={'py-3 px-3'}>
            <h2 className="pull-left mb-4 mt-2">
              <Translate contentKey="evaluateyouApp.audit.home.title">Audits</Translate>
              <Link to={`${match.url}/new`} className="btn btn-primary float-right jh-create-entity ml-3" id="jh-create-entity">
                <FontAwesomeIcon icon="plus"/>
                &nbsp;
                <Translate contentKey="evaluateyouApp.audit.home.createLabel">Create a new Audit</Translate>
              </Link>
            </h2>
            <div className="table-responsive">
              {models && models.length > 0 ? (
                  <Table responsive aria-describedby="audit-heading">
                    <thead>
                    <tr>
                      <th className="hand" onClick={this.sort('name')}>
                        <Translate contentKey="evaluateyouApp.audit.name">Name</Translate> <FontAwesomeIcon icon="sort"/>
                      </th>
                      <th className="hand" onClick={this.sort('description')}>
                        <Translate contentKey="evaluateyouApp.audit.description">Description</Translate> <FontAwesomeIcon
                          icon="sort"/>
                      </th>
                      <th className="hand" onClick={this.sort('is_core')}>
                        <Translate contentKey="evaluateyouApp.audit.isCore">Is Core</Translate> <FontAwesomeIcon icon="sort"/>
                      </th>
                      <th>
                        <Translate contentKey="evaluateyouApp.audit.totalQuestions">Is Core</Translate>
                      </th>
                      <th className="hand">
                        <Translate contentKey="evaluateyouApp.audit.enabled">Enabled</Translate>
                      </th>
                      <th className="hand">
                        <Translate contentKey="evaluateyouApp.audit.disabled">Disabled</Translate>
                      </th>
                      <th/>
                    </tr>
                    </thead>
                    <tbody>
                    {models.map((audit, i) => (
                        <tr key={`entity-${i}`}>
                          <td>{audit.name}</td>
                          <td>{audit.description}</td>
                          <td>{audit.core ? 'true' : 'false'}</td>
                          <td>{audit.totalQuestions}</td>
                          <td>{totalEnabled[this.findQuestionNumberForEnabled(audit.id)]}</td>
                          <td>{totalDisabled[this.findQuestionNumberForEnabled(audit.id)]}</td>
                          <td className="text-right">
                            <div className="btn-group flex-btn-group-container">
                              <Button tag={Link} to={`${match.url}/${audit.id}`} color="info" size="sm">
                                <FontAwesomeIcon icon="eye"/>{' '}
                                <span className="d-none d-md-inline">
                            <Translate contentKey="entity.action.design">View</Translate>
                          </span>
                              </Button>
                              <Button tag={Link} to={`${match.url}/${audit.id}/delete`} color="danger" size="sm">
                                <FontAwesomeIcon icon="trash"/>{' '}
                                <span className="d-none d-md-inline">
                            <Translate contentKey="entity.action.delete">Delete</Translate>
                          </span>
                              </Button>
                            </div>
                          </td>
                        </tr>
                    ))}
                    </tbody>
                  </Table>
              ) : (
                  <div className="alert alert-warning">
                    <Translate contentKey="evaluateyouApp.audit.home.notFound">No Audits found</Translate>
                  </div>
              )}
            </div>
            <div className={models && models.length > 0 ? '' : 'd-none'}>
              <Row className="justify-content-center">
                <JhiItemCount page={this.state.activePage} total={totalItems} itemsPerPage={this.state.itemsPerPage}
                              i18nEnabled/>
              </Row>
              <Row className="justify-content-center">
                <JhiPagination
                    activePage={this.state.activePage}
                    onSelect={this.handlePagination}
                    maxButtons={5}
                    itemsPerPage={this.state.itemsPerPage}
                    totalItems={totalItems}
                />
              </Row>
            </div>
          </Card>

        </Col>
      </Row>
    );
  }
}

const mapStateToProps = ({audit}: IRootState) => ({
  auditList: audit.entities,
  deleteSuccess: audit.updateSuccess,
  totalItems: audit.totalItems
});

const mapDispatchToProps = {
  getEntities
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Audit);
