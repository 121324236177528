import './footer.scss';

import React from 'react';

const Footer = () => (
  <div className="footer" id="contact">
    <p className="footer-text my-0">gpa@greenpacket.com</p>
    <p className="footer-text my-0">B-23A-3, The Ascent Paradigm No, 1, Jalan SS 7/26a, SS 3, 47301 Petaling Jaya, Selangor, Malaysia</p>
  </div>
);

export default Footer;
