import { IAssessment } from 'app/shared/model/assessment.model';
import { IAudit } from 'app/shared/model/audit.model';

export interface IAssessmentAudit {
  id?: string;
  numOfQuestions?: number;
  assessment?: IAssessment;
  audit?: IAudit;
}

export const defaultValue: Readonly<IAssessmentAudit> = {};
