import { IAssessment } from 'app/shared/model/assessment.model';
import { Auditable } from 'app/shared/model/audit.model';

export interface IAssessmentEmail extends Auditable {
  id?: string;
  email?: string;
  assessment?: IAssessment;
}

export const defaultValue: Readonly<IAssessmentEmail> = {};
